import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import { mainLoaderToggle } from "../modules/Helpers";
import appGoogleTracking from "../modules/GoogleTracking";
import GLOBAL_CONFIG from "../config/Config";

class TermsAndCond extends Component {
  componentDidMount() {
    mainLoaderToggle("hide");
  }
  componentWillUnmount() {
    mainLoaderToggle("show");
  }
  render(){
    const { t } = this.props;
    let stripBanner = null;

    if ((window.URL_PREFIX === "de-de") | (window.URL_PREFIX === "de-at")) {
      stripBanner = (
        <div className="strip__banner">
          <div className="section1">
            <p className="linkText">{parse(t("page.holding.linkText"))}</p>
          </div>
          <div className="section2">
          <p 
            className="buttonText"
            onClick={() => {
                appGoogleTracking.googleTag(
                  'click_action',
                  'subscribe',
                  'newsletter_subscribe_button',
                )
              }}
            >{parse(t("page.holding.buttonText"))}</p>
          </div>
        </div>
      );
    }

    return(
      <>
      <Helmet>
          <title>{t('meta.title')}</title>
          <meta name="description" content={t('meta.description')} />
          <body className={window.URL_PREFIX + " termsAndCond"} />
        </Helmet>
        <div className="termsAndCond__wrapper">
          <div className="main__header">
            <img
              src={t("page.main.image.header-mobile")}
              alt={t("page.main.image.headerAlt")}
              className="header-img-mobile"
            />
            <img
              src={t("page.main.image.header")}
              alt={t("page.main.image.headerAlt")}
              className="header-img"
            />
          </div>
          <div className="termsAndCond__heading">
            <h2 className="text__heading-tac">{t("page.termsAndCond.title")}</h2>
          </div>
          <div className="termsAndCond__content">
            <h2 className="text__content-title">{t("page.termsAndCond.contentTitle")}</h2>
            {parse(t("page.termsAndCond.contentParagraph"))}
            <button 
              className="cta-product mobile"
              onClick={() => {
                appGoogleTracking.googleTag(
                  'navigational_click',
                  'return',
                  'back_button',
                )
              }} 
            >
              <a href={`/${window.URL_PREFIX}/`}>{t("page.termsAndCond.buttonText")}</a>
            </button>
          </div>
          {stripBanner}
        </div>
      </>
    );
  }
}

export default withTranslation()(TermsAndCond);