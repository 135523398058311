import React, { Component } from "react";
import { scrollToElementwithTimeout } from "../../../modules/Helpers";

export class DeAt extends Component {
  componentDidMount() {
    scrollToElementwithTimeout();
  }
  Table() {
    return (
      <>

        <p className="content__text borderline">1x Paket 1
        </p>
        <p className="content__text borderin">
          <b>Samsung TV 75" Neo QLED 8K QN800B</b>	 <br />
          Wert in Euro: € 3.299,00 <br />
        </p>
        <p className="content__text borderin">
          <b>Microsoft, XBox Series X ohne Spiele</b>	 <br />
          Wert in Euro: € 499,00
        </p>
        <p className="content__text borderin">
          <b>Xbox Elite Wireless Controller Series 2, 2 Stück</b>	 <br />
          Wert in Euro: € 319,98
        </p>
        <p className="content__text borderin">
          <b>Sonos ARC Soundbar, schwarz</b>	 <br />
          Wert in Euro: € 999,00
        </p>

        <p className="content__text borderline">1x Paket 2
        </p>
        <p className="content__text borderin">
          <b>iPad Pro, 12,9", space grau, 512GB, Wi-Fi, ohne Gravur, ohne Stift,
            ohne Tastatur, ohne Apple Care+</b>	 <br />
          Wert in Euro: € 1.829,00 <br />
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Ultra, ohne Apple Care+</b>	 <br />
          Wert in Euro: € 999,00
        </p>
        <p className="content__text borderin">
          <b>Canon EOS R7 Systemkamera + RF-S 18-150mm F3.5-6.3 IS STM Objektiv</b>	 <br />
          Wert in Euro: € 1.989,00
        </p>

        <p className="content__text borderline">1x Paket 3
        </p>
        <p className="content__text borderin">
          <b>Sonos, Ultimatives Heimkino Set mit Arc, Sub, 2 Era 300</b>	 <br />
          Wert in Euro: € 2.896,00<br />
        </p>
        <p className="content__text borderin">
          <b>Samsung TV 50" Neo QLED 4K QN90C</b>	 <br />
          Wert in Euro: € 1.899,00
        </p>

        <p className="content__text borderline">2x Paket 4
        </p>
        <p className="content__text borderin">
          <b>Canon EOS R7 Systemkamera + RF-S 18-150mm F3.5-6.3 IS STM Objektiv</b>	 <br />
          Wert in Euro: € 1.989,00<br />
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Ultra, ohne Apple Care+</b>	 <br />
          Wert in Euro: € 999,00
        </p>

        <p className="content__text borderline">2x Paket 5
        </p>
        <p className="content__text borderin">
          <b>iPad Pro, 12,9", space grau, 512GB, Wi-Fi, ohne Gravur, ohne Stift,
            ohne Tastatur, ohne Apple Care+</b>	 <br />
          Wert in Euro: € 1.829,00<br />
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Ultra, ohne Apple Care+</b>	 <br />
          Wert in Euro: € 999,00
        </p>

        <p className="content__text borderline">2x Paket 6
        </p>
        <p className="content__text borderin">
          <b>Sonos ARC Soundbar, schwarz</b>	 <br />
          Wert in Euro: € 999,00
        </p>

        <p className="content__text borderline">1x Paket 7
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Ultra, ohne Apple Care+</b>	 <br />
          Wert in Euro: € 999,00
        </p>

        <p className="content__text borderline">2x Paket 8
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Series 8, Aluminiumgehäuse Mitternacht, 41mm, Sport
            Loop, GPS Konnektivität, ohne Apple Care+</b>	 <br />
          Wert in Euro: € 499,00
        </p>
        <p className="content__text borderin">
          <b>Air Pods Pro (2. Generation)</b>	 <br />
          Wert in Euro: € 299,00
        </p>

        <p className="content__text borderline">3x Paket 9
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Series 8, Aluminiumgehäuse Mitternacht, 41mm, Sport
            Loop, GPS Konnektivität, ohne Apple Care+</b>	 <br />
          Wert in Euro: € 499,00
        </p>

        <p className="content__text borderline">3x Paket 10
        </p>
        <p className="content__text borderin">
          <b>Air Pods Pro (2. Generation)	</b>	 <br />
          Wert in Euro: € 299,00
        </p>
        <p className="content__text borderin">
          <b>Home Pod mini	</b>	 <br />
          Wert in Euro: € 109,00
        </p>
      </>
    )

  }
  render() {
    return (
      <div className="main__content">
        <p className="text__heading">TEILNAHMEBEDINGUNGEN</p>
        <div className="content">
          {/*           <p className="content__sub-title">
          </p> */}
          <p className="content__text">
            <strong>Allgemeiner Gewinn und Veranstalter:</strong>  Die Baker & Baker Germany GmbH ist alleiniger Sponsor der Gewinne im Sinne des § 657 BGB („<strong>Veranstalter</strong>“). D&K Brand Activation (Drewes & Keretic GmbH) fungiert als beauftragter Dienstleister des Veranstalters und ist für die Gesamtleitung der Aktion sowie die Bereitstellung und Verteilung der Preise verantwortlich. Die Consultix GmbH ist eine Agentur, die Teilnehmerdaten verwaltet und speichert. Als technischer Produktionspartner und digitale Kreativ- und Konzeptagentur wurden Proximity (RAPP Worldwide, Inc.) und AUW (artundweise GmbH) engagiert. Keiner dieser Dritten ist durch das Gewinnspiel zur Erbringung eigener Leistungen verpflichtet. Händler und Social-Media-Plattformen sind weder Veranstalter des Gewinnspiels noch handeln sie im Auftrag des Veranstalters.
          </p>
          <p className="content__text">
            <strong>Teilnahmeberechtigung:</strong> Teilnehmen kann jeder ab 18 Jahren mit ständigem Wohnsitz in Deutschland, Österreich, Frankreich, Belgien oder den Niederlanden. Voraussetzung für die Teilnahme ist die ordnungsgemäße Anmeldung. Hierzu muss das Online-Formular auf der Website www.donut-muffin.milka.eu vom Teilnehmer ausgefüllt und abgesendet werden sowie durch Anklicken der dafür vorgesehenen Checkbox diese Teilnahmebedingungen akzeptiert werden. Für die Teilnahme ist kein Kauf erforderlich. Für Teilnehmer mit Wohnsitz in Belgien muss aufgrund behördlicher Anforderungen eine Frage richtig beantwortet werden, bevor sie zur Teilnahme berechtigt sind.
          </p>
          <p className="content__text">
            Von der Teilnahme ausgeschlossen sind Mitarbeiter und Angehörige von Mitarbeitern des Veranstalters sowie Mitarbeiter und Angehörige von Mitarbeitern von D&K Brand Activation, Consultix GmbH, Proximity und AUW. Ausdrücklich ausgeschlossen sind Teilnahmen, die von beauftragten Dienstleistern durchgeführt werden, etwa Gewinnspiel-Services für deren Kunden/Mitglieder. Mehrfachteilnahmen sind nicht zulässig. Der Veranstalter behält sich das Recht vor, Teilnehmer vom Gewinnspiel auszuschließen, die gegen diese Teilnahmebedingungen verstoßen, falsche Angaben machen oder versuchen, den Ablauf des Gewinnspiels in rechtswidriger Weise zu beeinflussen. In diesem Fall besteht für die ausgeschlossenen Teilnehmer kein Anspruch auf Auszahlung des Gewinns oder einer sonstigen Entschädigung.
          </p>
          <p className="content__text">
            <strong>Absage des Gewinnspiels:</strong> Der Veranstalter behält sich das Recht vor, das Gewinnspiel aus wichtigem Grund vorzeitig abzubrechen, wenn aus technischen Gründen (z. B. Manipulation der auf der Website basierenden Gewinnspielseite durch Dritte oder Infektion der Website mit Viren), aus rechtlichen Gründen, oder aus anderen Gründen, die nicht im Einflussbereich des Veranstalters liegen, und wodurch eine ordnungsgemäße Durchführung des Gewinnspiels nicht mehr gewährleistet werden kann. Hierfür kann der Veranstalter nicht haftbar gemacht werden, es sei denn, es liegt Vorsatz oder grobe Fahrlässigkeit des Veranstalters vor. Im Falle einer Absage besteht kein Anspruch der Teilnehmer auf einen Gewinn oder eine sonstige Entschädigung.          </p>
          <p className="content__text">
            <strong>Zeitraum/Frist:</strong> Die Teilnahme ist nur im Zeitraum vom 01.05.2024, 00:00 Uhr bis zum 30.11.2024 um 23:59 Uhr möglich.
          </p>
          <p className="content__text">
            <strong>Preise:</strong> Insgesamt werden 53 Gewinner ausgewählt, 3 Gewinner einer Familienfeier (im Wert von jeweils ca. 5.000 €) und 50 Gewinner eines Familien-Picknickkorbs (im Wert von jeweils maximal 100 €). Den Gewinnern wird automatisch ein Preis zugeteilt. Der Gesamtwert aller Preise wird 20.000 € nicht überschreiten. Siehe Preisliste unten.
          </p>
          <p className="content__text">
            <ul>
              <li>
                <strong>•</strong> 3 x Familienfeier-Pakete (ungefährer Wert je 5.000 €) – beinhalten beispielsweise Catering, Buchung eines Veranstaltungsortes/einer Location bei Bedarf, Ausrüstungsverleih, Dekoration und andere Aktivitäten bis zum Höchstwert. Die endgültige Entscheidung über die Durchführung der Familienfeier liegt im Ermessen von D&K in Absprache mit dem Veranstalter. Wenn die einzelnen Anfragen des Gewinners das Preislimit überschreiten, muss der Gewinner den Überschuss direkt an den/die Lieferanten zahlen. Die Veranstaltung muss bis spätestens 31. Dezember 2025 gebucht und durchgeführt werden. Es ist kein Bargeldäquivalent verfügbar. Wenn die Kosten der Veranstaltung weniger als 5.000 € betragen, stellt D&K Brand Activation nach eigenem Ermessen geeignete Geschenkgutscheine oder Feierartikel bis zum 31. Dezember 2025 zur Verfügung Wert des Defizits.
              </li>
            </ul>
          </p>
          <p className="content__text">
            <ul>
              <li>
                <strong>•</strong> 50 x Familien-Picknickkörbe (Höchstwert jeweils 100 €).
              </li>
            </ul>
          </p>
          <p className="content__text">
            Alle Artikel unterliegen der Verfügbarkeit. Sollten Artikel zu diesem Zeitpunkt nicht verfügbar sein, werden sie durch gleichwertige, mindestens gleichwertige Produkte ersetzt.          </p>
          <p className="content__text">
            Im Zusammenhang mit den Familienfeier-Paketen stehen Vertreter von D&K Brand Activation jedem Gewinner bis zu 20 Stunden (Montag bis Freitag von 10:00 bis 16:00 Uhr) zur Verfügung, um ihn bei der Planung der Veranstaltung, der Auswahl von Standorten und Lieferanten sowie bei der Verhandlung und dem Abschluss von Vereinbarungen mit Lieferanten zu unterstützen (einschließlich der Abwicklung von Zahlungen veranstaltungsbezogener Rechnungen bis zum angegebenen Höchstbetrag).
          </p>
          <p className="content__text">
            <strong>Gewinnverteilung:</strong> Die 50 Picknickkörbe-Gewinne werden innerhalb von 4 Wochen nach der Gewinnauslosung auf einem geeigneten Versandweg versandt.
          </p>
          <p className="content__text">
            <strong>Gewinnerermittlung:</strong> Die Gewinner werden am 2. Dezember 2024 nach dem Zufallsprinzip aus allen gültigen und korrekten Einsendungen ermittelt. Es gibt keinen garantierten Gewinner in jedem Land, in dem der Wettbewerb durchgeführt wird.
          </p>
          <p className="content__text">
            <strong>Gewinnbenachrichtigung, Bestätigungszeitraum, Ablauf des Gewinns:</strong> Die Gewinner werden von D&K Brand Activation an die E-Mail-Adresse benachrichtigt, die sie im Registrierungsformular angegeben haben, und werden anschließend gebeten, eine Telefonnummer und eine Postanschrift zum Zweck der Kommunikation bzw. eine Postanschrift für die Zusendung eines Gewinns anzugeben (falls ein kleinerer Preis gewonnen wurde).
          </p>
          <p className="content__text">
            Der Anspruch auf einen Gewinn kann grundsätzlich nicht auf eine andere Person übertragen werden. Wenn ein Gewinner die angeforderten Daten nicht innerhalb einer Frist von entweder 14 Tagen (für Familien-Picknickkörbe) bzw. 28 Tagen (für die Familienfeier-Pakete) nach Absenden der Gewinnbenachrichtigung bereitstellt oder eine Gewinnausschüttung aufgrund fehlerhafter oder unvollständiger Angaben nicht möglich ist, wird ein anderer Gewinner ermittelt und der Gewinnanspruch des ursprünglich ermittelten Gewinners erlischt insoweit. D&K Brand Activation sendet die Familien-Picknickkörbe an die jeweiligen Gewinner.
          </p>
          <p className="content__text">
            <strong>Haftung:</strong> Die Haftung des Veranstalters und der von ihm beauftragten Personen ist ausgeschlossen. Der Haftungsausschluss gilt nicht bei vorsätzlichem oder grob fahrlässigem Verhalten sowie bei Tod oder Körperverletzung. Bei einfacher Fahrlässigkeit ist der Haftungsumfang auf die wesentlichen Vertragspflichten – nämlich die ordnungsgemäße Ermittlung der Gewinner und die Zustellung der Gewinne – beschränkt. Der Veranstalter übernimmt gegenüber den Gewinnern keine Haftung für den Fall, dass ein Gewinnpaket nicht (ganz oder teilweise) zugestellt wird oder während des Transports beschädigt wird.
          </p>
          <p className="content__text">
            <strong>Sonstiges:</strong> Es gilt ausschließlich das Recht der Bundesrepublik Deutschland. Von dieser Regelung bleibt das Recht des Art. 6 Abs. 2 Rom I-VO unberührt, wonach trotz der Rechtswahl diejenigen zwingenden Vorschriften gelten, die auch ohne die ausdrückliche Rechtswahl anwendbar gewesen wären. Sollte eine dieser Bestimmungen unwirksam sein oder werden, so bleibt die Wirksamkeit der übrigen Teilnahmebedingungen hiervon unberührt.
          </p>
          <p className="content__text">
            <strong>Datenschutz:</strong> Mondelēz International (als Eigentümer der Marke Oreo) ist der Datenverantwortliche für die von den Teilnehmern bereitgestellten personenbezogenen Daten und die Consultix GmbH wird personenbezogene Daten für die Zwecke des Wettbewerbs verarbeiten.
          </p>
          <p className="content__text">
            Die nach der EU-Datenschutz-Grundverordnung („DSGVO“) erforderlichen Pflichtinformationen zur Datenverarbeitung sind hier zusammengefasst (die Datenschutzerklärung von Mondelēz International ist abrufbar über: <a href="https://privacy.mondelezinternational.com/eu/de-AT/privacy-notice/" target="_blank" rel="noreferrer">https://privacy.mondelezinternational.com/eu/de-AT/privacy-notice/</a>
          </p>
          <p className="content__text">
            <strong>Verantwortlicher:</strong> Mondelēz International, Lindbergh-Allee 1, 8152 Glattpark, Schweiz ist als Datenverantwortlicher für den Datenschutz verantwortlich.
          </p>
          <p className="content__text">
            Datenschutzbeauftragter: Den Datenschutzbeauftragten von Mondelēz International erreichen Sie unter: <a href="mailto:MDLZDataProtectionOfficeMEU@mdlz.com" target="_blank" rel="noreferrer">MDLZDataProtectionOfficeMEU@mdlz.com</a>
          </p>
          <p className="content__text">
            Datenverarbeitung: Für die Teilnahme am Gewinnspiel über die Website <a href="https://www.donut-muffin.milka.eu" target="_blank" rel="noreferrer">www.donut-muffin.milka.eu</a>  ist bei der Anmeldung die Angabe folgender Daten erforderlich: Anrede, Vorname, Nachname, E-Mail-Adresse, Anschrift und Geburtsdatum. Die Verarbeitung dieser personenbezogenen Daten erfolgt zur Durchführung des Gewinnspiels, d.h. um die Gewinner zu ermitteln, diese zu benachrichtigen und ihnen den Gewinn zusenden zu können. Für die Gewinnverteilung müssen die Gewinner außerdem folgende Angaben machen: Postanschrift. Wenn Sie gewinnen, werden Sie gesondert per E-Mail dazu aufgefordert. Im Rahmen des E-Mail-Verkehrs werden Protokolldateien über die Eigenschaften der E-Mail und den Zeitpunkt des Eintreffens sowie alle in der E-Mail vom Absender angegebenen Daten gespeichert.
          </p>
          <p className="content__text">
            <strong>Rechtsgrundlage:</strong> Die Verarbeitung personenbezogener Daten im Rahmen der Registrierung und Gewinnausschüttung erfolgt rechtmäßig auf Grundlage der DSGVO. Die Verarbeitung der Daten durch die Consultix GmbH erfolgt zur Vertragserfüllung in Form der Durchführung des Gewinnspiels und der damit verbundenen rechtlichen Verpflichtung, Art. 6 Abs. 1 lit. b DSGVO. b) DSGVO.
          </p>
          <p className="content__text">
            <strong>Weitergabe an Dritte:</strong> Mondelēz International gibt die Daten der Teilnehmer nicht an Dritte im Sinne der DSGVO weiter.
          </p>
          <p className="content__text">
            <strong>Auftragsverarbeiter:</strong> Mondelēz International nutzt ausschließlich vertrauenswürdige Dienstleister, die Ihre personenbezogenen Daten verarbeiten. Sie werden nach den strengen Vorgaben der DSGVO schriftlich beauftragt und verfügen beispielsweise über technische und organisatorische Maßnahmen, mit denen die Dienstleister die ihnen anvertrauten Daten vor Missbrauch schützen. Konkret wurde D&K Brand Activation mit der Abwicklung des Gewinnspiels und Proximity mit der Bereitstellung der Website für das Gewinnspiel beauftragt. Zur Auftragsabwicklung zählen nur solche Leistungen, die im unmittelbaren Zusammenhang mit der Erbringung der Hauptleistung stehen. Hiervon ausgenommen sind Nebendienstleistungen wie Telekommunikationsdienste.
          </p>
          <p className="content__text">
            <strong>Übermittlung in Drittländer:</strong> Alle personenbezogenen Daten werden innerhalb der EU/des EWR gespeichert, auch durch etwaige Auftragsverarbeiter.
          </p>
          <p className="content__text">
            <strong>Dauer der Speicherung:</strong> Mondelēz International und Consultix GmbH löschen:
          </p>
          <p className="content__text">
            <ul>
              <li>
                <strong>•</strong> die personenbezogenen Daten der Gewinner, sobald keine handels- oder steuerrechtlichen Aufbewahrungspflichten mehr entgegenstehen (letztere können bis zu 10 Jahre betragen);
              </li>
            </ul>
          </p>
          <p className="content__text">
            <ul>
              <li>
                <strong>•</strong> die personenbezogenen Daten der anderen Teilnehmer, sobald die Gewinner des Gewinnspiels endgültig feststehen, d. h. es kann aufgrund unvollständiger Angaben oder fehlender Rückmeldung eines zuvor ermittelten Gewinners zu weiteren Auslosungen gekommen sein;
              </li>
            </ul>
          </p>
          <p className="content__text">
            <ul>
              <li>
                <strong>•</strong> Eingegangene E-Mails im Zusammenhang mit dem Wettbewerb: sechs (6) Monate nach endgültiger Ermittlung der Gewinner
              </li>
            </ul>
          </p>
          <p className="content__text">
            Cookies: Siehe <a href="https://milka.at/Static/cookie-richtlinie" target="_blank" rel="noreferrer">https://milka.at/Static/cookie-richtlinie</a>
          </p>
          <p className="content__text">
            Ihre Rechte: Bei Vorliegen der gesetzlichen Voraussetzungen stehen Ihnen folgende Rechte zu, die Sie gegenüber Mondelēz International geltend machen können:
          </p>
          <p className="content__text">
            a. Auskunftsrecht: Sie haben jederzeit das Recht auf Auskunft im Rahmen des Art. gemäß Art. 15 DSGVO von Mondelēz International eine Bestätigung darüber zu verlangen, ob sie personenbezogene Daten über Sie verarbeitet; ist dies der Fall, steht Ihnen auch ein Anspruch nach Art. gemäß Art. 15 DSGVO Auskunft über diese personenbezogenen Daten sowie bestimmte weitere Informationen (z.B. Verarbeitungszwecke, Kategorien personenbezogener Daten, Kategorien von Empfängern, geplante Speicherdauer, Herkunft der Daten, Einsatz einer automatisierten Entscheidungsfindung und ggf (bei Drittlandübermittlungen, die entsprechenden Garantien) und eine Kopie Ihrer Daten zu erhalten.
          </p>
          <p className="content__text">
            b. Recht auf Berichtigung: Gemäß Art. Gemäß Art. 16 DSGVO haben Sie das Recht, von Mondelēz International die Berichtigung der über Sie gespeicherten personenbezogenen Daten zu verlangen, sofern diese unrichtig oder unrichtig sind.
          </p>
          <p className="content__text">
            c. Recht auf Löschung: Ihnen steht unter den Voraussetzungen des Art. gemäß Art. 17 DSGVO von Mondelēz International zu verlangen, dass die Sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden. Das Recht auf Löschung besteht unter anderem nicht, soweit eine Verarbeitung personenbezogener Daten nach Art. 6 Abs. 1 lit. b DSGVO erforderlich ist. Art. 17 Abs. 3 DSGVO.
          </p>
          <p className="content__text">
            d. Recht auf Einschränkung der Verarbeitung: Sie haben unter den Voraussetzungen des Art. gemäß Art. 18 DSGVO zu verlangen, dass Mondelēz International die Verarbeitung Ihrer personenbezogenen Daten einschränkt.
          </p>
          <p className="content__text">
            e. Recht auf Datenübertragbarkeit: Ihnen steht unter den Voraussetzungen des Art. gemäß Art. 20 DSGVO von Mondelēz International die Herausgabe der Sie betreffenden personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu verlangen.
          </p>
          <p className="content__text">
            f. Widerrufsrecht: Sie haben das Recht, Ihre Einwilligung zur Verarbeitung personenbezogener Daten jederzeit mit Wirkung für die Zukunft zu widerrufen.
          </p>
          <p className="content__text">
            g. Widerspruchsrecht: Sie haben das Recht, unter den Voraussetzungen des Art. Gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, insbesondere auf Grundlage einer Interessenabwägung (Art. 6 Abs. 1 lit. f) DSGVO).
          </p>
          <p className="content__text">
            Information über Ihr Widerspruchsrecht gemäß Art. Gemäß Art. 21 DSGVO haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung Ihrer Daten einzulegen, die auf Grundlage von Art. 21 DSGVO erfolgt. 6 Abs. 1 f) DSGVO (Datenverarbeitung auf Grundlage einer Interessenabwägung), sofern hierfür Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben. Wenn Sie Widerspruch einlegen, wird Mondelēz International Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, Mondelēz International kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen. Der Widerspruch kann formfrei erfolgen und sollte gerichtet werden an: <a href="mailto:MDLZDataProtectionOfficeMEU@mdlz.com" target="_blank" rel="noreferrer">MDLZDataProtectionOfficeMEU@mdlz.com</a> wenn möglich.
          </p>
          <p className="content__text">
            Beschwerderecht: Sie haben außerdem das Recht, sich bei Beschwerden zu Datenschutzthemen an eine zuständige Aufsichtsbehörde zu wenden. Die zuständige Aufsichtsbehörde ist daher:
          </p>
          <p className="content__text">
            VERBRAUCHERSERVICE<br />
            MONDELEZ DEUTSCHLAND SERVICES GMBH & CO. KG VERBRAUCHERSERVICE<br />
            POSTFACH 10 78 40, 28078 BREMEN<br />
            HOTLINE DE/AT/CH: 00800 83 00 00 36 KOSTENFREI <br />
            Phone: HOTLINE DE/AT/CH: 00800 83 00 00 36 FREE OF CHARGE  <br />
            Email:   <a href="mailto:verbraucherservice@mdlz.com">VERBRAUCHERSERVICE@MDLZ.COM</a>

          </p>
        </div>
      </div>
    );
  }
}

export default DeAt;
