import React, { Component, Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import i18next, { buildLocalisedContent } from './modules/Localisation'
import Layout from './layout/Layout'
import './styles/_config.scss'
import CampaignServices from './Services/Campaign'
import CampaignStatus from './Models/CampaignStatus'

window.URL_PREFIX = null
window.COUNTRY_CODE = null
window.EndDate = null
window.DayLeft = null
window.FILE_STATUS = false

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      campaign: true,
      campaignStatus: CampaignStatus.live,
      localDev: false,
      localDomain: 'localhost',
      urlPrefix: '',
      countryCode: 'us',
    }

    this.setLanguagePrefix = this.setLanguagePrefix.bind(this)
  }

  componentDidMount() {
    const currentUrl = window.location.href;
    if (/[A-Z]/.test(currentUrl)){
      window.location.href = currentUrl.toLowerCase();
    }
    this.loadCampaign(() => {
      // if (this.state.urlPrefix === 'nl-nl' ||
      //   this.state.urlPrefix === 'nl-be' ||
      //   this.state.urlPrefix === 'fr-be'
      // ) {
      //   const tagManagerArgs = { gtmId: 'GTM-557CTGXQ' };
      //   TagManager.initialize(tagManagerArgs);
      // }
    });
  }

  render() {
    let renderPage = null,
      _baseName =
        this.state.urlPrefix !== 'en-us' ? `/${this.state.urlPrefix}` : ''

    if (this.state.campaign === true) {
      renderPage = (
        <Suspense fallback="loading...">
          <I18nextProvider i18n={i18next}>
            <BrowserRouter>
              <Layout
                campaign={this.state.campaign}
                campaignStatus={this.state.campaignStatus}
                urlPrefix={this.state.urlPrefix}
              />
            </BrowserRouter>
          </I18nextProvider>
        </Suspense>
      )
    }

    return renderPage
  }

  setLanguagePrefix(language, campaign) {
    /**
     * Redirects only home page
     */
    if (campaign === CampaignStatus.redirect) {
      window.location.href = `/${language}`
    }
    if (language !== 'en-us' && window.location.pathname === '/') {
      window.location.href = `/${language}`
    }
  }

  setPromotion = (campaign) => {
    if (campaign.toLowerCase() === 'holding') {
      return CampaignStatus.holding
    } else if (campaign.toLowerCase() === 'main') {
      return CampaignStatus.live
    } else if (campaign.toLowerCase() === 'end') {
      return CampaignStatus.end
    } else if (campaign.toLowerCase() === 'redirect') {
      return CampaignStatus.redirect
    } else {
      return CampaignStatus.noCampaign
    }
  }

  //function to load language status and campaign status
  loadCampaign(callback) {
    CampaignServices.CampaignMilka()
      .then((response) => {
        let { status } = response,
          { success, data, message } = response.data
        if (success) {
          let { status, countryCode, locale } = data,
            _STATUS = this.setPromotion(status)
          if (_STATUS == CampaignStatus.noCampaign) {
            locale = 'en-us'
          }
          this.setState({
            campaign: true,
            campaignStatus: _STATUS,
            urlPrefix: locale,
            countryCode: countryCode,
          })
          window.PROMOTION = _STATUS
          window.URL_PREFIX = locale
          window.COUNTRY_CODE = countryCode
          this.setLanguagePrefix(locale, _STATUS)
          buildLocalisedContent(locale)
          if (typeof callback === 'function') {
            callback();
          }
        }
      })
      .catch()

    //local simulation
    this.localCampaignSimulation()
  }

  //simulation for local environment
  localCampaignSimulation() {
    if (
      this.state.localDev === true &&
      window.location.host.indexOf(this.state.localDomain) > -1
    ) {
      let promotion = CampaignStatus.live,
        language =
          window.location.pathname.split('/')[1].length > 0 &&
            window.location.pathname.split('/')[1].length < 'de-de'.length + 1
            ? window.location.pathname.split('/')[1]
            : 'de-de',
        countryCode = 'us';

      this.setState({
        campaignStatus: promotion,
        urlPrefix: language,
      })

      window.PROMOTION = promotion
      window.URL_PREFIX = language
      window.COUNTRY_CODE = countryCode

      this.setLanguagePrefix(language, promotion)

      //localisation
      buildLocalisedContent(language)
    }
  }
}

export default App
