import React, { useState, useEffect } from 'react';
import appGoogleTracking from '../../modules/GoogleTracking';
import StickyHeader from '../../modules/StickyHeader';
import hamburgerMenu from '../../modules/HamburgerMenu';

/*
    Google tagging plan added on links using : appGoogleTracking.processEventCTA
*/

const Header = (props) => {
    const { t } = props;
    const [isLogoVisible, setIsLogoVisible] = useState(true);
    const [searchTerm, setSearchTerm] = useState(''); // Initialize searchTerm to empty string
    const handleInputChange = (event) => {
        setSearchTerm(event.target.value); // Update searchTerm state with user input
    };


    const domLoad = () => {
        new StickyHeader({
            headerSelector: '.js-header.header-md__container',
            contentSelector: '.js-header-md-content',
            iconSelector: '.js-header-md-hamburger',
            linkSelector: '.header-md__navigation'
        });

        new StickyHeader({
            headerSelector: '.js-header.header__container .header__head',
            linkSelector: '.header__navigation'
        });

        new hamburgerMenu({
            menuSelector: '.js-hamburger',
            contentSelector: '.js-ham-content',
            modifier: 'header__menu--open',
            close: '.js-ham-close'
        });
    };

    const [flag2, setFlag2] = useState({ name: "", url: "" });
    const [flag1, setFlag1] = useState({ name: "", url: "" });

    useEffect(() => {
        window.addEventListener('DOMContentLoaded', domLoad());
        // alert('test')
        if (props.urlPrefix === 'fr-be') {
            setFlag1({ name: 'french-unactive', url: '/fr-be' });
            setFlag2({ name: 'dutsch', url: '/nl-be' });
        }

        else if (props.urlPrefix === 'nl-be') {
            setFlag1({ name: 'dutsch-unactive', url: '/nl-be' });
            setFlag2({ name: 'french', url: '/fr-be' });
        }


    }, []);
    function displayFlag() {
        return (
            <>
                <li className="header-md__icon header-md__flag">
                    <a
                        href={flag2.url}
                        rel="noopener noreferrer"
                        data-event="language_option"
                        data-category="Header"
                        data-action="Select Language"
                        data-label={flag2.name}
                        onClick={appGoogleTracking.processEventCTA}
                    >
                        <img
                            src={'/resources/images/oreo/flag/' + flag2.name + '.png'}
                            alt="Flag"
                        />
                    </a>
                </li>
                <li className="header-md__icon header-md__flag">
                    <a
                        href={flag1.url}
                        rel="noopener noreferrer"
                        data-event="language_option"
                        data-category="Header"
                        data-action="Select Language"
                        data-label={flag1.name}
                        onClick={appGoogleTracking.processEventCTA}
                    >
                        <img
                            src={'/resources/images/oreo/flag/' + flag1.name + '.png'}
                            alt="Flag"
                        />
                    </a>
                </li>
            </>

        );
    }



    return (
        <header>
            {/* /////mobile/////// */}
            <nav className="js-header header__container">
                <div className="header__head">
                    <img className="header__pull js-pull-animate" src="resources/images/icons/Pull_Out_Shape.svg" alt="print" />
                    <div className="js-hamburger header__menu" id="hamburger-m"><span className="menu-line"></span><span className="menu-line"></span> <span className="menu-line"></span><span className="menu-line"></span></div>
                    <div className="header__logo">
                        <a
                            href={t('header.milkaUrl')}
                            target="_blank"
                            rel="noopener noreferrer"
                            data-event="go_to_milka"
                            data-category="Header"
                            data-action="Go to Page"
                            data-label="MILKA_ALLPAGE_NAVBAR"
                            onClick={appGoogleTracking.processEventCTA}
                        >
                            <span className="icon-milka-logo"></span>
                        </a>
                    </div>
                    {props.urlPrefix === 'fr-be' || props.urlPrefix === 'nl-be' ? (
                        <div className="js-header-md-content header-md__content" style={{ marginRight: '-217px', marginTop: '-10px' }}>
                            <ul className="header-md__navigations">
                                {displayFlag()}
                            </ul>
                        </div>
                    ) : (
                        null
                    )}
                    <div className="header__icons">
                        {props.urlPrefix === 'de-de' ?
                            <a
                                href='https://fcmilka.de/account'
                                target="_blank"
                                rel="noopener noreferrer"
                                data-event="header_contact_menu"
                                data-category="Header"
                                data-action="Select Menu"
                                data-label="PROFILE"
                                onClick={appGoogleTracking.processEventCTA}
                            >
                                <span className="icon-profile"></span>
                            </a>
                            : null}
                        {props.urlPrefix === 'fr-fr' || props.urlPrefix === 'fr-be' || props.urlPrefix === 'nl-be' || props.urlPrefix === 'nl-nl' || t('header.news') === "" ? null :

                            <a
                                href={t('header.newsletterUrl')}
                                target="_blank"
                                rel="noopener noreferrer"
                                data-event="header_contact_menu"
                                data-category="Header"
                                data-action="Select Menu"
                                data-label="NEWSLETTER"
                                onClick={appGoogleTracking.processEventCTA}
                            >
                                <span className="newsletter">
                                </span>
                            </a>
                        }
                            {/* <a
                                href={t('header.milkaUrl')}
                                target="_blank"
                                rel="noopener noreferrer"
                                data-event="header_contact_menu"
                                data-category="Header"
                                data-action="Select Menu"
                                data-label="GLOBE"
                                onClick={appGoogleTracking.processEventCTA}
                            >
                                <span className='globe' > </span>
                            </a> */}

                    </div>
                </div>
                <div className="header__content js-ham-content">
                    <div className="header__head">
                        <div className="js-ham-close"><span className="icon-home"></span></div>
                    </div>
                    <div className="header__navigations">
                        <ul>
                            {t('header.product') === "" ? null :
                                <li>
                                    <a
                                        className="header__navigation js-event-cta"
                                        data-event="header_menu"
                                        data-category="Header"
                                        data-action="Select Menu"
                                        data-label={t('header.product')}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={t('header.productUrl')}
                                        onClick={appGoogleTracking.processEventCTA}>{t('header.product')}</a>
                                </li>
                            }
                            {props.urlPrefix === 'fr-fr' || t('header.news') === "" ? null :
                                (<li>
                                    <a
                                        className="header__navigation js-event-cta"
                                        data-event="header_menu"
                                        data-category="Header"
                                        data-action="Select Menu"
                                        data-label={t('header.news')}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={t('header.newsUrl')}
                                        onClick={appGoogleTracking.processEventCTA}>{t('header.news')}</a>
                                </li>)
                            }
                            {t('header.fcMilka') === "" ? null :
                                <li>
                                    <a
                                        className="header__navigation js-event-cta"
                                        data-event="header_menu"
                                        data-category="Header"
                                        data-action="Select Menu"
                                        data-label={t('header.fcMilka')}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={t('header.fcMilkaUrl')}
                                        onClick={appGoogleTracking.processEventCTA}>{t('header.fcMilka')}</a>
                                </li>
                            }
                            {t('header.about') === "" ? null :
                                <li>
                                    <a
                                        className="header__navigation js-event-cta"
                                        data-event="header_menu"
                                        data-category="Header"
                                        data-action="Select Menu"
                                        data-label={t('header.about')}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={t('header.aboutUrl')}
                                        onClick={appGoogleTracking.processEventCTA}>{t('header.about')}</a>
                                </li>
                            }
                        </ul>
                        <div className="header__end"></div>
                    </div>
                </div>
            </nav>

            {/* /////WEB/////// */}
            <nav className="js-header header-md__container">
                <div className={`header-md__logo`} style={{ display: isLogoVisible ? '' : 'none' }}>
                    <a className="nav__item js-event-cta" data-event="go_to_milka" data-category="Header" data-action="Go to Page" data-label="MILKA_ALLPAGE_NAVBAR" onClick={appGoogleTracking.processEventCTA} href={t('header.milkaUrl')} target="_blank" rel="noopener noreferrer"><span className="icon-milka-logo"></span></a>
                </div>
                <div className="js-header-md-hamburger header-md__menu">
                    <div className="header__btn" id="hamburger"><span className="menu-line"></span><span className="menu-line"></span><span className="menu-line"></span><span className="menu-line"></span></div>
                </div>
                <div className="js-header-md-content header-md__content">
                    <ul className="header-md__navigations">
                        {props.urlPrefix === 'fr-be' || props.urlPrefix === 'nl-be' ? (
                            <>
                                {displayFlag()}
                            </>
                        ) : (
                            null
                        )}
                        {t('header.product') === "" ? null :
                            <li>
                                <a
                                    className="header-md__navigation js-event-cta"
                                    data-event="header_menu"
                                    data-category="Header"
                                    data-action="Select Menu"
                                    data-label={t('header.product')}
                                    href={t('header.productUrl')}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={appGoogleTracking.processEventCTA}>{t('header.product')}</a>
                            </li>
                        }
                        {props.urlPrefix === 'fr-fr' || t('header.news') === "" ? null :

                            (<li>
                                <a
                                    className="header-md__navigation js-event-cta"
                                    data-event="header_menu"
                                    data-category="Header"
                                    data-action="Select Menu"
                                    data-label={t('header.news')}
                                    href={t('header.newsUrl')}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={appGoogleTracking.processEventCTA}>{t('header.news')}</a>
                            </li>)
                        }
                        {t('header.fcMilka') === "" ? null :
                            <li>
                                <a
                                    className="header-md__navigation js-event-cta"
                                    data-event="header_menu"
                                    data-category="Header"
                                    data-action="Select Menu"
                                    data-label={t('header.fcMilka')}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={t('header.fcMilkaUrl')}
                                    onClick={appGoogleTracking.processEventCTA}>{t('header.fcMilka')}</a>
                            </li>
                        }
                        {t('header.about') === "" ? null :
                            <li>
                                <a
                                    className="header-md__navigation js-event-cta"
                                    data-event="header_menu"
                                    data-category="Header"
                                    data-action="Select Menu"
                                    data-label={t('header.about')}
                                    href={t('header.aboutUrl')}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={appGoogleTracking.processEventCTA}>{t('header.about')}
                                </a>
                            </li>
                        }
                    </ul>
                    <div class="header-md__search-outter-container">
                        <div class="search-container">
                            <span className="icon-search-mobile text-lg icon-search"></span>
                            <div className="">
                                <input
                                    placeholder={t('header.searchPlaceholder')}
                                    className="searchInput placeholder-opacity-[0.5]"
                                    name="search-box"
                                    value={searchTerm}
                                    onChange={handleInputChange}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            appGoogleTracking.dataLayerPush({
                                                dataEvent: "search",
                                                dataCategory: "Header",
                                                dataAction: "Click on Search",
                                                dataLabel: "ENG_ALLPAGE_NAVBAR",
                                            });
                                            const targetUrl = `${t('header.searchUrl')}=${searchTerm}`;
                                            window.location.href = targetUrl;
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <ul className="header-md__icons">
                        {props.urlPrefix === 'de-de' ?
                            <li className="header-md__icon header-md__contact ">
                                <a
                                    href='https://fcmilka.de/account'
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    data-event="header_contact_menu"
                                    data-category="Header"
                                    data-action="Select Menu"
                                    data-label="ACCOUNT"
                                    onClick={appGoogleTracking.processEventCTA}
                                >
                                    <span className="icon-profile"></span>
                                </a>
                            </li>
                            : null}
                        {props.urlPrefix === 'de-de' || props.urlPrefix === 'de-at' ?
                            <li className="header-md__icon header-md__contact">
                                <a
                                    href={t('header.newsletterUrl')}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    data-event="header_contact_menu"
                                    data-category="Header"
                                    data-action="Select Menu"
                                    data-label="NEWSLETTER"
                                    onClick={appGoogleTracking.processEventCTA}
                                >
                                    <span className="newsletter">
                                    </span>
                                </a>
                            </li>
                            : null}
                        
                        <li className="header-md__icon header-md__contact">
                            <a
                                className="js-event-cta"
                                href={t('header.contactUrl')}
                                target="_blank"
                                rel="noopener noreferrer"
                                data-event="header_contact_menu"
                                data-category="Header"
                                data-action="Select Menu"
                                data-label="CONTACT"
                                onClick={appGoogleTracking.processEventCTA}
                            >
                                <span className="icon-contact"></span>
                            </a>
                        </li>
                    
                        {/* {props.urlPrefix==='de-de' || props.urlPrefix==='de-at' ? null : 
                        <li className="header-md__icon header-md__globe">
                            <a
                                href={t('header.milkaUrl')}
                                target="_blank"
                                rel="noopener noreferrer"
                                data-event="header_contact_menu"
                                data-category="Header"
                                data-action="Select Menu"
                                data-label="GLOBE"
                                onClick={appGoogleTracking.processEventCTA}
                            >
                                <img src='/resources/icons/icon-globe.png' alt='icon-globe' className='icon-globe' />
                            </a>
                        </li>
                        } */}
                    </ul>
                </div>
            </nav>
        </header>
    );
}

export default Header;