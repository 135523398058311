import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import { mainLoaderToggle } from "../../modules/Helpers";
import appGoogleTracking from "../../modules/GoogleTracking";
import Banner from "../../components/Oreo/Banner";
import TitleContent from "../../components/TitleContent/TitleContent";
import ProductSecond from "../../components/ProductSecond/ProductSecond";

class End extends Component {
  componentDidMount() {
    mainLoaderToggle("hide");
  }

  componentWillUnmount() {
    mainLoaderToggle("show");
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <Helmet>
          <body className={window.URL_PREFIX + " End"} />
        </Helmet>
        <Banner
            DesktopImage={t("page.end.oreo.banner.desktop")}
            MobileImage={t("page.end.oreo.banner.mobile")}
            AltText={t("page.end.oreo.banner.alt")}
        />
        <div className="wrapper oreo-holding">



          <div className="end__content">
            <TitleContent
              title={t("page.end.oreo.title")}
              description={t("page.end.oreo.desc")}
            />

          </div>

          <div className="product-wrapper__second">
            <ProductSecond urlPrefix={'/'+window.URL_PREFIX} />
          </div>

        </div>
      </>
    );
  }
}

export default withTranslation()(End);