import React, { Component } from "react";
import Banner from "../../components/Oreo/Banner";
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import { mainLoaderToggle } from "../../modules/Helpers";
import { withRouter } from "react-router-dom";
import DeDe from "./TermsContent/DeDe";
import DeAt from "./TermsContent/DeAt";
import DeCh from "./TermsContent/DeCh";
import EnGb from "./TermsContent/EnGb";
import Nl from "./TermsContent/Nl";
import NlBe from "./TermsContent/NlBe";
import LbLu from "./TermsContent/LbLu";
import Fr from "./TermsContent/Fr";
import FrBe from "./TermsContent/FrBe";
import FrCh from "./TermsContent/FrCh";
import FrLu from "./TermsContent/FrLu";

class Terms extends Component {
    componentDidMount() {
        mainLoaderToggle("hide");
    }
    content(){
        switch (window.URL_PREFIX) {
            case 'de-de':
                return <DeDe/>
            case "de-at":
                return <DeAt/>
            case "de-ch":
                return <DeCh/>
            case "lb-lu":
                return <LbLu/>
            case 'fr-fr':
                return <Fr/>
            case "fr-ch":
                return <FrCh/>
            case "fr-be":
                return <FrBe/>
            case "fr-lu":
                return <FrLu/>
            case "nl-nl": 
                return <Nl/>
            case "nl-be":
                return <NlBe/>
            default:
                return <EnGb/>
              
        }
    }
    render() {
    const { t } = this.props;
        return (
            <div className="main__wrapper oreo-main terms">
                <Banner
                    DesktopImage={t("page.main.banner.desktop")}
                    MobileImage={t("page.main.banner.mobile")}
                    AltText={t("page.main.banner.alt")}
                />
                {this.content()}
                {/* <div className="main__content">
                    <p className="text__heading">{t(`${this.content()}.title`)}</p>
                    <div className="content">
                        <p className="content__sub-title">{t(`${this.content()}.sub_title`)}</p>
                        <p className="content__text">{parse(t(`${this.content()}.paragraph_1`))}</p>
                        <p className="content__text">{parse(t(`${this.content()}.paragraph_2`))}</p>
                        <p className="content__text">{parse(t(`${this.content()}.paragraph_3`))}</p>
                    </div>
                </div> */}
            </div>
        );
    }
}
export default withRouter(withTranslation()(Terms));
