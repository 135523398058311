import React, { Component } from 'react'

export class Banner extends Component {
  render() {
    const { DesktopImage, MobileImage, AltText } = this.props;
    return (
        <div className="banner-container">
        <picture>
          <source srcSet={DesktopImage} media="(min-width: 1024px)" />
          <source srcSet={DesktopImage} media="(min-width: 481px)" />
          <source srcSet={MobileImage} media="(min-width: 200px)" />
          <img
            className="banner__image"
            src={DesktopImage}
            alt={AltText}
          />
        </picture>
      </div>
    )
  }
}

export default Banner