import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import { mainLoaderToggle } from "../modules/Helpers";
import appGoogleTracking from "../modules/GoogleTracking";
import GLOBAL_CONFIG from "../config/Config";
import Banner from "../components/Oreo/Banner";

class CookiePolicy extends Component {
  componentDidMount() {
    mainLoaderToggle("hide");
  }
  componentWillUnmount() {
    mainLoaderToggle("show");
  }
  render() {
    const { t } = this.props;
    let stripBanner = null;

    if ((window.URL_PREFIX === "de-de") | (window.URL_PREFIX === "de-at")) {
      stripBanner = (
        <div className="strip__banner">
          <div className="section1">
            <p className="linkText">{parse(t("page.holding.linkText"))}</p>
          </div>
          <div className="section2">
            <p className="buttonText">{parse(t("page.holding.buttonText"))}</p>
          </div>
        </div>
      );
    }

    return (
      <>
        <Helmet>
          <title>{t('meta.title')}</title>
          <meta name="description" content={t('meta.description')} />
          <body className={window.URL_PREFIX + " cookie-policy"} />
        </Helmet>
        <div className="cookie__wrapper">
          {/* <div className="main__header">
            <img
              src={t("page.main.image.header-mobile")}
              alt={t("page.main.image.headerAlt")}
              className="header-img-mobile"
            />
            <img
              src={t("page.main.image.header")}
              alt={t("page.main.image.headerAlt")}
              className="header-img"
            />
          </div> */}
          <Banner
            DesktopImage={t("page.holding.oreo.banner.desktop")}
            MobileImage={t("page.holding.oreo.banner.mobile")}
            AltText={t("page.holding.oreo.banner.alt")}
          />
          <div className="cookie__content" style={{ marginBottom: "10px" }}>
            {parse(t("page.cookiePolicy"))}
            {/* <center>
              <button className="cta-product mobile">
                <a href={`/${window.URL_PREFIX}/`}>{t("page.termsAndCond.buttonText")}</a>
              </button>
            </center> */}

          </div>
          {/* {stripBanner} */}
        </div>
      </>
    );
  }
}

export default withTranslation()(CookiePolicy);