import React, { useState, useEffect, useRef } from "react";
import LbLu from "./TermsContent/LbLu";
import FrLu from "./TermsContent/FrLu";

const TermsPopUp = (props) => {
  const [modal, setModal] = useState({ ...props.modal });
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [isEnable, setIsEnable] = useState("");

  useEffect(() => {
    setModal(props.modal);
  }, [props.modal]);

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  useEffect(() => {
    
    const div = document.getElementById("scrollableDiv"); 
    const handleScroll = () => {
      // Replace 'scrollableDiv' with your div's id
      if (div) {
        const isAtBottom =
          //div.scrollHeight - div.scrollTop === div.clientHeight;
          div.scrollTop + div.clientHeight >= div.scrollHeight;
        //setIsScrolledToBottom(isAtBottom);
        if(!isScrolledToBottom && isAtBottom) {
            setIsScrolledToBottom(true);
            setIsEnable("enable");
            //document.getElementById("modal-button").classList.add("enable");
        }else{
            setIsScrolledToBottom(false);
            setIsEnable("");
            //document.getElementById("modal-button").classList.remove("enable");
        }
        console.log("scrolled to bottom value",isAtBottom);
      }
    };

    // Attach the scroll event listener
    div.addEventListener("scroll", handleScroll);
    
    return () => {
      // Clean up the event listener
      div.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = () => {
    if (isScrolledToBottom) {
      // Perform your button click action here
      console.log("Button clicked when scrolled to bottom");
      props.toggleModal();
      document.body.classList.remove("active-modal");
    } else {
      console.log("Button click disabled");
    }
  };

  function content() {
    if (props.urlPrefix === "fr-lu") {
      return <FrLu />;
    } else {
      return <LbLu />;
    }
  }

  return (
    <>
      {modal && (
        <div className="modal-terms" style={{ zIndex: 11 }}>
          <div className="popup-overlay"></div>
          <div
            className="modal-wrapper"
            style={{ zIndex: 12 }}
          >
            <div id="scrollableDiv" className="modal-content">{content()}</div>
            <button
                id="modal-button"
              onClick={() => {
                handleClick();
              }}
              class={`oreo-button-blue close-modal ${isEnable}`}
              type="submit"
              data-event="info-click"
              data-category="Form Action"
              data-action="Submit"
              data-label="Type In"
            >
              {props.urlPrefix === "fr-lu" ? "accepter" : "akzeptieren"}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default TermsPopUp;
