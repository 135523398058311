import React, { Component } from 'react'
import { scrollToElementwithTimeout } from '../../../modules/Helpers'

export class NlBe extends Component {
  componentDidMount() {
    scrollToElementwithTimeout();
  }
  Table() {
    return (
      <>

        <p className="content__text borderline">1x Pakket 1
        </p>
        <p className="content__text borderin">
          <b>Ultieme meeslepende set met Arc, Sub, 2 Era 300</b>	 <br />
          Waarde in Euro: € 2.896,00 <br />
        </p>
        <p className="content__text borderin">
          <b>Samsung TV 50" Neo QLED 4K QN90C</b>	 <br />
          Waarde in Euro: € 1.999,00
        </p>

        <p className="content__text borderline">1x Pakket 2
        </p>
        <p className="content__text borderin">
          <b>Samsung TV 75" Neo QLED 8K QN800B</b>	 <br />
          Waarde in Euro: € 3.089,00 <br />
        </p>
        <p className="content__text borderin">
          <b>Microsoft, XBox Series X, geen game</b>	 <br />
          Waarde in Euro: € 499,90
        </p>
        <p className="content__text borderin">
          <b>Xbox Elite draadloze controller Series 2, 2 stukken</b>	 <br />
          Waarde in Euro: € 290,00
        </p>
        <p className="content__text borderin">
          <b>Sonos ARC Soundbar, zwart</b>	 <br />
          Waarde in Euro: € 999,00
        </p>

        <p className="content__text borderline">1x Pakket 3
        </p>
        <p className="content__text borderin">
          <b>iPad Pro 12,9'', Spacegrijs, 512 GB, Wi-Fi,
            geen inscriptie, geen pencil, geen toetsenbord, geen Apple Care+</b>	 <br />
          Waarde in Euro: € 1.849,45<br />
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Ultra, ohne Apple Care+</b>	 <br />
          Waarde in Euro: € 999,00
        </p>
        <p className="content__text borderin">
          <b>Canon EOS R7-systeemcamera en RF-S 18-150mm F3.5-6.3 IS STM-lens</b>	 <br />
          Waarde in Euro: € 1.999,00
        </p>

        <p className="content__text borderline">2x Pakket 4
        </p>
        <p className="content__text borderin">
          <b>Canon EOS R7-systeemcamera en RF-S 18-150mm F3.5-6.3 IS STM-lens</b>	 <br />
          Waarde in Euro: € 1.999,00<br />
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Ultra, geen Apple Care+</b>	 <br />
          Waarde in Euro: € 999,00
        </p>

        <p className="content__text borderline">2x Pakket 5
        </p>
        <p className="content__text borderin">
          <b>iPad Pro 12,9'', Spacegrijs, 512 GB, Wi-Fi,
            geen inscriptie, geen pencil, geen toetsenbord, geen Apple Care+</b>	 <br />
          Waarde in Euro: € 1.849,00<br />
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Ultra, geen Apple Care+</b>	 <br />
          Waarde in Euro: € 999,00
        </p>

        <p className="content__text borderline">1x Pakket 6
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Ultra, geen Apple Care+</b>	 <br />
          Waarde in Euro: € 999,00
        </p>

        <p className="content__text borderline">2x Pakket 7
        </p>
        <p className="content__text borderin">
          <b>Sonos ARC Soundbar, zwart</b>	 <br />
          Waarde in Euro: € 999,00
        </p>

        <p className="content__text borderline">2x Pakket 8
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Series 8, kast aluminium, middernacht, Sportbandje, 41mm, connectiviteit GPS , geen Apple Care+</b>	 <br />
          Waarde in Euro: € 499,00
        </p>
        <p className="content__text borderin">
          <b>Air Pods Pro (2e generatie)</b>	 <br />
          Waarde in Euro: € 299,00
        </p>

        <p className="content__text borderline">3x Pakket 9
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Series 8, kast aluminium, middernacht, Sportbandje, 41mm, connectiviteit GPS , geen Apple Care+</b>	 <br />
          Waarde in Euro: € 499,00
        </p>

        <p className="content__text borderline">3x Pakket 10
        </p>
        <p className="content__text borderin">
          <b>Air Pods Pro (2e generatie)	</b>	 <br />
          Waarde in Euro: € 299,00 
        </p>
        <p className="content__text borderin">
          <b>Home Pod mini	</b>	 <br />
          Waarde in Euro: € 109,00
        </p>
      </>
    )

  }
  render() {
    return (
      <div className="main__content">
        <p className="text__heading">VOORWAARDEN VOOR DEELNAME</p>
        <div className="content">
          <p className="content__text">
            <strong>Algemene prijs en organisator:</strong> Baker & Baker Germany GmbH is de enige sponsor van de prijzen in de zin van § 657 van het Duitse Burgerlijk Wetboek ("organisator"). D&K Brand Activation (Drewes & Keretic GmbH) treedt op als dienstverlener in opdracht van de organisator, verantwoordelijk voor het algemene beheer van de promotie en het leveren en distribueren van de prijzen.  Consultix GmbH is een bureau dat de gegevens van de deelnemers beheert en bewaart.  Proximity (RAPP Worldwide, Inc.) en AUW (artundweise GmbH) zijn ingeschakeld als technisch productiepartner en digitaal creatief- en conceptbureau. Geen van deze derden is verplicht om hun eigen diensten te leveren als gevolg van de wedstrijd. Retailers en sociale mediaplatformen zijn geen organisatoren van de wedstrijd en handelen ook niet namens de organisator.
          </p>
          <p className="content__text">
            <strong>Voorwaarden voor deelname:</strong> Iedereen ouder dan 18 jaar en met vaste woonplaats in Duitsland, Oostenrijk, Frankrijk, België of Nederland kan deelnemen. Voorwaarde voor deelname is dat er een goede aanmelding heeft plaatsgevonden. Daartoe moet het online formulier op de website www.donut-muffin.milka.eu worden ingevuld en verzonden door de deelnemer en moeten deze deelnamevoorwaarden worden geaccepteerd door op het daarvoor bestemde selectievakje te klikken.  Er is geen vereiste om een aankoop te doen om deel te nemen.  Voor deelnemers die in België wonen moet een vraag vanwege wettelijke vereisten correct worden beantwoord voordat ze in aanmerking komen voor deelname
          </p>
          <p className="content__text">
            Uitgesloten van deelname zijn medewerkers en familieleden van medewerkers van de organisator en medewerkers en familieleden van medewerkers van D&K Brand Activation, Consultix GmbH, Proximity en AUW. Inzendingen van opdrachtnemers, zoals prijsvraagdiensten voor hun klanten/leden, worden uitdrukkelijk uitgesloten. Meervoudige deelnames zijn niet toegestaan. De organisator behoudt zich het recht voor deelnemers uit te sluiten van de wedstrijd die deze deelnamevoorwaarden schenden, onjuiste informatie verstrekken of het verloop van de wedstrijd op onrechtmatige wijze proberen te beïnvloeden. In dit geval hebben de uitgesloten deelnemers geen recht op uitbetaling van de prijs of enige andere vergoeding.
          </p>
          <p className="content__text">
            <strong>Annulering van de wedstrijd:</strong> De organisator behoudt zich het recht voor om de wedstrijd om gegronde redenen voortijdig te beëindigen indien om technische redenen (bijvoorbeeld manipulatie van de op de website gebaseerde wedstrijdpagina door derden of infectie van de website met virussen), om juridische redenen of om andere redenen buiten de controle van de organisator, een goede uitvoering van de wedstrijd niet langer kan worden gegarandeerd. De organisator kan hiervoor niet aansprakelijk worden gesteld, behoudens in geval van opzet of grove schuld van de organisator. In geval van annulering hebben de deelnemers geen recht op een prijs of enige andere vergoeding.
          </p>
          <p className="content__text">
            <strong>Periode / deadline:</strong> Deelname is enkel mogelijk in de periode van 12.00 uur op 1 mei 2024 tot 23.59 uur. op 30 november 2024.
          </p>
          <p className="content__text">
            <strong>Prijzen:</strong> Er worden in totaal 53 winnaars geselecteerd, 3 winnaars van een familiefeest (met een waarde van ongeveer € 5.000 per stuk) en 50 winnaars van een familiepicknickmand (ter waarde van maximaal € 100 per stuk).  De winnaars krijgen automatisch een prijs toegewezen.  De totale waarde van alle prijzen zal niet hoger zijn dan € 20.000. Zie prijzenlijst hieronder.
          </p>
          <p className="content__text">
            <ul>
              <li>
                • 3 x Family Celebration Packages (geschatte waarde € 5.000 per stuk) – omvat bijvoorbeeld catering, het boeken van een locatie indien nodig, materiaalhuur, decoratie en andere activiteiten tot de maximale waarde. De uiteindelijke keuze over hoe het familiefeest wordt uitgevoerd, is ter beoordeling van D&K in overleg met de organisator. Als de individuele verzoeken van de winnaar de prijslimiet overschrijden, moet het verschil door de winnaar rechtstreeks aan de leverancier(s) worden betaald. Het evenement moet uiterlijk 31 december 2025 geboekt en vervuld zijn.  Er is geen equivalent in contanten beschikbaar, indien de kosten van het evenement minder dan € 5.000 bedragen, zal D&K Brand Activation naar eigen goeddunken passende cadeaubonnen of feestartikelen ter beschikking stellen tot de waarde van het tekort.
              </li>
            </ul>
          </p>
          <p className="content__text">
            <ul>
              <li>
                • 50 x Family Picknickmanden (maximale waarde €100 per stuk).
              </li>
            </ul>
          </p>
          <p className="content__text">
            Alle artikelen zijn onder voorbehoud van beschikbaarheid. Als artikelen op dat moment niet beschikbaar zijn, worden ze vervangen door vergelijkbare producten van ten minste gelijke waarde.
          </p>
          <p className="content__text">
            In verband met de Family Celebration Packages zullen D&K Brand Activation vertegenwoordigers maximaal 20 uur (maandag tot en met vrijdag van 10:00 tot 16:00 uur) aan elke winnaar ter beschikking stellen om te helpen bij het plannen van het evenement, het selecteren van locaties en leveranciers, het onderhandelen en afronden van afspraken met leveranciers (inclusief het afhandelen van betalingen van evenement gerelateerde facturen tot het vermelde maximum).         </p>
          <p className="content__text">
            <strong>Prijsuitreiking:</strong> De 50 picknickmanden worden binnen 4 weken na de verloting via een geschikte bezorgmethode verzonden.
          </p>
          <p className="content__text">
            <strong>Bepaling van de winnaars:</strong> De winnaars worden op 2 december 2024 willekeurig geselecteerd uit alle geldige en correcte inzendingen.  Er zal geen gegarandeerde winnaar zijn in elk land waar de wedstrijd wordt gehouden.
          </p>
          <p className="content__text">
            <strong>Prijskennisgeving, bevestigingstermijn, vervaldatum van de prijs:</strong> De winnaars worden door D&K Brand Activation op de hoogte gebracht op het e-mailadres dat ze in het registratieformulier hebben ingevoerd en worden vervolgens gevraagd om een telefoonnummer en postadres op te geven voor communicatiedoeleinden of een postadres voor het verzenden van een prijs (als er een kleinere prijs is gewonnen). Een aanspraak op een prijs kan in principe niet worden overgedragen aan een andere persoon. Als een winnaar de gevraagde gegevens niet binnen een termijn van 14 dagen (voor picknickmanden) of 28 dagen ( voor het Family Celebration Packet) na verzending van de prijskennisgeving verstrekt, of als een prijsverdeling niet mogelijk is vanwege onjuiste of onvolledige gegevens, wordt een andere winnaar geselecteerd en vervalt de aanspraak op de prijs van de oorspronkelijk bepaalde winnaar in dit opzicht.  D&K Brand Activation zal de picknickmanden opsturen naar de respectievelijke winnaars.
          </p>
          <p className="content__text">
            <strong>Aansprakelijkheid:</strong> De aansprakelijkheid van de organisator en de door hem ingeschakelde personen is uitgesloten.  De uitsluiting van aansprakelijkheid geldt niet voor opzettelijk of grof nalatig gedrag en dood of lichamelijk letsel.  Voor gewone nalatigheid is de omvang van de aansprakelijkheid beperkt tot de belangrijkste contractuele verplichtingen – namelijk de juiste bepaling van de prijswinnaars en de levering van de prijzen.  De organisator is niet aansprakelijk jegens een winnaar in het geval dat een prijzenbundel niet (geheel of gedeeltelijk) wordt geleverd of tijdens het transport wordt beschadigd.
          </p>
          <p className="content__text">
            <strong>Diversen:</strong> Uitsluitend het recht van de Bondsrepubliek Duitsland is van toepassing. Deze bepaling doet geen afbreuk aan het recht van artikel 6, lid 2, van de Rome I-verordening, volgens hetwelk dwingende bepalingen die zonder de uitdrukkelijke rechtskeuze van toepassing zouden zijn geweest, niettegenstaande de rechtskeuze, van toepassing zijn.  Indien een van deze bepalingen ongeldig is of wordt, blijft de geldigheid van de overige deelnamevoorwaarden onaangetast.
          </p>
          <p className="content__text">
            <strong>Gegevensbescherming:</strong> Mondelēz International (als eigenaar van het merk Milka) is de verwerkingsverantwoordelijke voor de persoonsgegevens die door de deelnemers worden verstrekt en Consultix GmbH zal persoonsgegevens verwerken ten behoeve van de wedstrijd.
          </p>
          <p className="content__text">
            De verplichte informatie over gegevensverwerking die vereist is door de Algemene Verordening Gegevensbescherming ("AVG") van de EU wordt hier samengevat (de privacyverklaring van Mondelēz International is toegankelijk via:<a href="https://privacy.mondelezinternational.com/eu/nl-NL/privacy-notice/" target="_blank" rel="noreferrer">https://privacy.mondelezinternational.com/eu/nl-NL/privacy-notice/</a>
          </p>
          <p className="content__text">
            Verantwoordelijke: Mondelēz International, Lindbergh-Allee 1, 8152 Glattpark, Zwitserland, is als verwerkingsverantwoordelijke verantwoordelijk voor de gegevensbescherming.
          </p>
          <p className="content__text">
            Functionaris voor gegevensbescherming: U kunt de functionaris voor gegevensbescherming van Mondelēz International bereiken op: <a href="mailto:MDLZDataProtectionOfficeMEU@mdlz.com" target="_blank" rel="noreferrer">MDLZDataProtectionOfficeMEU@mdlz.com</a>
          </p>
          <p className="content__text">
            <strong>Gegevensverwerking:</strong> Om deel te nemen aan de wedstrijd via de website <a href="https://www.donut-muffin.milka.eu" target="_blank" rel="noreferrer">www.donut-muffin.milka.eu</a>, moeten bij de registratie de volgende gegevens worden verstrekt: Aanhef, voornaam, achternaam, e-mailadres, adres en geboortedatum. Deze persoonsgegevens worden verwerkt om de wedstrijd uit te voeren, d.w.z. om de winnaars te bepalen, hen op de hoogte te stellen, met hen te kunnen communiceren en (indien van toepassing) hen een prijs te sturen. De winnaars moeten ook de volgende informatie verstrekken voor de verdeling van de prijzen: postadres. Als je wint, wordt je gevraagd om dit apart per e-mail te doen. Als onderdeel van het e-mailverkeer worden logbestanden opgeslagen over de eigenschappen van de e-mail en het tijdstip van aankomst, evenals alle gegevens die door de afzender in de e-mail zijn opgegeven.
          </p>
          <p className="content__text">
            <strong>Rechtsgrondslag:</strong> De verwerking van persoonsgegevens in het kader van de registratie en prijsuitreiking vindt rechtmatig plaats op basis van de AVG. Consultix GmbH verwerkt de gegevens voor de uitvoering van de overeenkomst in het kader van de uitvoering van de prijsvraag en de daarmee samenhangende wettelijke verplichting, art. 6 lid 1 sub b) AVG.
          </p>
          <p className="content__text">
            <strong>Doorgifte aan derden:</strong> Mondelēz International geeft de gegevens van de deelnemers niet door aan derden in de zin van de AVG.
          </p>
          <p className="content__text">
            <strong>Verwerkers:</strong> Mondelēz International maakt alleen gebruik van betrouwbare dienstverleners die uw persoonsgegevens verwerken. Ze worden schriftelijk aangesteld in overeenstemming met de strenge eisen van de AVG en hebben bijvoorbeeld technische en organisatorische maatregelen waarmee de dienstverleners de aan hen toevertrouwde gegevens beschermen tegen misbruik. Concreet heeft D&K Brand Activation de opdracht gekregen om de wedstrijd te beheren en heeft Proximity de opdracht gekregen om de website voor de wedstrijd te verzorgen. De verwerking van bestellingen omvat alleen die diensten die rechtstreeks verband houden met de levering van de hoofddienst. Ondersteunende diensten zoals telecommunicatiediensten vallen hier niet onder.
          </p>
          <p className="content__text">
            <strong>Doorgifte naar derde landen:</strong> Alle persoonsgegevens worden opgeslagen binnen de EU/EER, ook door eventuele verwerkers.
          </p>
          <p className="content__text">
            <strong>Duur van de opslag:</strong> Mondelēz International en Consultix GmbH zullen het volgende verwijderen:
          </p>
          <p className="content__text">
            <ul>
              <li>
                • de persoonsgegevens van de winnaars zodra er geen commerciële of fiscale bewaarplichten meer zijn die het tegendeel beweren;
              </li>
            </ul>
          </p>
          <p className="content__text">
            <ul>
              <li>
                • de persoonsgegevens van de andere deelnemers zodra de winnaars van de wedstrijd definitief zijn bepaald, d.w.z. dat er aanvullende trekkingsprocedures kunnen hebben plaatsgevonden als gevolg van onvolledige informatie of het uitblijven van een reactie van een eerder geïdentificeerde winnaar;
              </li>
            </ul>
          </p>
          <p className="content__text">
            <ul>
              <li>
                • E-mails ontvangen in verband met de wedstrijd: zes (6) maanden nadat de winnaars definitief zijn bepaald.
              </li>
            </ul>
          </p>
          <p className="content__text">
            Cookies: See <a href="https://milka.be/nlbe/Static/cookiebeleid" target="_blank" rel="noreferrer">https://milka.be/nlbe/Static/cookiebeleid</a>
          </p>
          <p className="content__text">
            <strong>Uw rechten:</strong> Als aan de wettelijke vereisten is voldaan, heeft u de volgende rechten, die u kunt doen gelden tegen Mondelēz International:
          </p>
          <p className="content__text">
            a. Recht op informatie: U hebt te allen tijde het recht om in het kader van art. 15 AVG Mondelēz International te verzoeken om bevestiging of zij persoonsgegevens over u verwerkt; als dit het geval is, hebt u op grond van artikel 15 van de AVG ook het recht om informatie over deze persoonsgegevens en bepaalde andere informatie te verkrijgen (bijv. verwerkingsdoeleinden, categorieën van persoonsgegevens, categorieën van ontvangers, geplande opslagperiode, de oorsprong van de gegevens, het gebruik van geautomatiseerde besluitvorming en, in het geval van doorgifte aan derde landen, de passende waarborgen) en om een kopie van uw gegevens te ontvangen.
          </p>
          <p className="content__text">
            b. Recht op rectificatie: Volgens artikel 16 van de AVG hebt u het recht om Mondelēz International te verzoeken de over u opgeslagen persoonsgegevens te corrigeren als deze onjuist of onjuist zijn.
          </p>
          <p className="content__text">
            c. Recht op gegevenswissing: U hebt het recht om onder de voorwaarden van art. 17 AVG te eisen dat Mondelēz International uw persoonsgegevens onmiddellijk verwijdert. Het recht op gegevenswissing bestaat onder andere niet als de verwerking van persoonsgegevens vereist is in overeenstemming met artikel 17, lid 3, van de AVG.
          </p>
          <p className="content__text">
            d. Recht op beperking van de verwerking: U hebt het recht om onder de voorwaarden van art. 18 AVG Mondelēz International te verzoeken de verwerking van uw persoonsgegevens te beperken.
          </p>
          <p className="content__text">
            e. Recht op overdraagbaarheid van gegevens: U hebt het recht om, met inachtneming van de vereisten van art. 20 AVG, Mondelēz International te verzoeken de relevante persoonsgegevens die u hebt verstrekt in een gestructureerd, gangbaar en machinaal leesbaar formaat te overhandigen.
          </p>
          <p className="content__text">
            f. Herroepingsrecht: U hebt te allen tijde het recht om uw toestemming voor de verwerking van persoonsgegevens in te trekken met werking voor de toekomst.
          </p>
          <p className="content__text">
            g. Recht van bezwaar: U hebt het recht om onder de voorwaarden van art. 21 AVG bezwaar te maken tegen de verwerking van uw persoonsgegevens, in het bijzonder op basis van een belangenafweging (art. 6 lid 1 sub f AVG).
          </p>
          <p className="content__text">
            Informatie over uw recht van bezwaar volgens art. 21 AVG U hebt te allen tijde het recht om bezwaar te maken tegen de verwerking van uw gegevens, die is gebaseerd op art. 6 lid 1 f) AVG (gegevensverwerking op basis van een belangenafweging), indien hiervoor redenen zijn die voortvloeien uit uw specifieke situatie. Als u bezwaar maakt, Mondelēz International zal uw persoonsgegevens niet langer verwerken, tenzij zij dwingende gerechtvaardigde gronden voor de verwerking kan aantonen die zwaarder wegen dan uw belangen, rechten en vrijheden, of de verwerking dient voor de instelling, uitoefening of onderbouwing van een rechtsvordering. Het bezwaar kan in elke vorm worden ingediend en moet indien mogelijk aan <a href="mailto:MDLZDataProtectionOfficeMEU@mdlz.com" target="_blank" rel="noreferrer">MDLZDataProtectionOfficeMEU@mdlz.com</a> worden gericht.
          </p>
          <p className="content__text">
            Recht op beroep: U hebt ook het recht om contact op te nemen met een bevoegde toezichthoudende autoriteit in geval van klachten over gegevensbeschermingskwesties. De verantwoordelijke toezichthoudende autoriteit is dus:
          </p>
          <p className="content__text">
            VERBRAUCHERSERVICE
            <br />
            MONDELEZ DEUTSCHLAND SERVICES GMBH & CO. KG VERBRAUCHERSERVICE
            <br />
            POSTFACH 10 78 40, 28078 BREMEN
            <br />
            HOTLINE DE/AT/CH: 00800 83 00 00 36 KOSTENFREI <br />
            Phone: HOTLINE DE/AT/CH: 00800 83 00 00 36 FREE OF CHARGE <br />
            Email:
            <a href="mailto:verbraucherservice@mdlz.com">
              VERBRAUCHERSERVICE@MDLZ.COM
            </a>
          </p>
        </div>
      </div>
    )
  }
}

export default NlBe