import React from 'react';
import FooterLogoV1 from './FooterContent/FooterLogoV1';
import FooterSocialV1 from './FooterContent/FooterSocialV1';
import FooterCopyrightV1 from './FooterContent/FooterCopyrightV1';
import FooterLinksV1 from './FooterContent/FooterLinksV1';
import appGoogleTracking from '../../modules/GoogleTracking';

const footerV1 = (props) => (
    <footer className="footer__container_v1">
        <div className="grid-row footer__head_v1">
            <FooterLogoV1 t={props.t} />
            <FooterSocialV1 t={props.t} urlPrefix={props.urlPrefix} />
            <FooterCopyrightV1 t={props.t} urlPrefix={props.urlPrefix} />
            <FooterLinksV1 CampaignStatus={props.CampaignStatus} t={props.t} urlPrefix={props.urlPrefix} />
            <div className='js-scroll-top' />
            <a className="milka__logo__back"
                href={props.t('header.milkaUrl')}
                target="_blank"
                rel="noopener noreferrer"
                title="Milka"
                data-event="go_to_milka"
                data-category="Footer"
                data-action="Go to Page"
                data-label="MILKA_ALLPAGE_FOOTER"
                onClick={appGoogleTracking.processEventCTA}
            >
                <span className="icon-milka-logo"></span></a>
        </div>
    </footer>
);

export default footerV1;