import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import { mainLoaderToggle } from "../modules/Helpers";
import appGoogleTracking from "../modules/GoogleTracking";
import GLOBAL_CONFIG from "../config/Config";
import Banner from "../components/Oreo/Banner";
class NoParticipation extends Component {
  componentDidMount() {
    mainLoaderToggle("hide");
  }

  componentWillUnmount() {
    mainLoaderToggle("show");
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <Helmet>
          <body className={window.URL_PREFIX + " noParticipation"} />
        </Helmet>
        {/* <Banner
          DesktopImage={t("page.holding.oreo.banner.desktop")}
          MobileImage={t("page.holding.oreo.banner.mobile")}
          AltText={t("page.holding.oreo.banner.alt")}
        /> */}
        <div style={{ backgroundColor: "#7d69ac", height: "90px" }}>
          <p style={{ color: "#7d69ac" }} >_</p>
        </div>
        <div className="noParticipation__wrapper">
          <h2 className="text__heading-np">{parse(t("page.noParticipation.title"))}</h2>
          {/* <p className="text__desc-np">{parse(t("page.noParticipation.desc"))}</p> */}
          {/*           <img className="noParticiption-mobile" src={t("page.noParticipation.image.mobile")}/>
          <img className="noParticiption-desktop" src={t("page.noParticipation.image.desktop")}/> */}
        </div>
      </>
    );
  }
}

export default withTranslation()(NoParticipation);