import React from 'react';
import './popup.scss'
const Popup = ({ errorText, closePopup }) => {
    return (
        <div className='popupContainer'>

            <div className='popupError'>
                <div className='closePopup' onClick={closePopup}>
                    <img src='resources/icons/close.png' alt='Close'></img>
                </div>
                <p>
                    {errorText}
                </p>

            </div>
        </div>
    );
}

export default Popup;