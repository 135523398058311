import { Component } from "react";
import { withTranslation } from "react-i18next";
import appGoogleTracking from "../../modules/GoogleTracking";
import './ProductSecond.scss';

class ProductSecond extends Component {
    render() {
        const { t, CampaignStatus, urlPrefix } = this.props;
        return (
            <>
                <div class="content-product_second">
                    <div class="product_second">
                        <div class="back-top">
                            <p></p>
                        </div>
                        <div class="card blue">
                            <div class="image">
                                <img src={t("page.holding.oreo.product.5")} alt="Product" />
                            </div>
                            <div class="descri">
                                <p>{t("page.holding.oreo.product.5-text")}</p>
                            </div>
                        </div>
                        <div class="card green">
                            <div class="image">
                                <img src={t("page.holding.oreo.product.6")} alt="Product" />
                            </div>
                            <div class="descri">
                                <p>{t("page.holding.oreo.product.6-text")}</p>
                            </div>
                        </div>
                        {/* <div class="back-left">
                            <p></p>
                        </div>
                        <div class="back-right">
                            <p></p>
                        </div> */}
                    </div>
                    {CampaignStatus === 'holding' || urlPrefix === '/fr-fr' || urlPrefix === '/fr-be' || urlPrefix === '/nl-nl' || urlPrefix === '/nl-be' ? null :
                        <div class="btn-content">
                            <div class="btn">
                                <a
                                    href={t("page.thank-you.buttonLink")}
                                    data-event="join_now"
                                    data-category={CampaignStatus === 'end' ? "End Page" : "Thank You Page"}
                                    data-action="Click on Join Now"
                                    data-label={CampaignStatus === 'end' ? "ENG_EP_OTHER" : "ENG_TP_OTHER"}
                                    onClick={(event) => {
                                        appGoogleTracking.processEventCTANavLink(event)
                                        const url = t("page.thank-you.buttonLink");
                                        window.open(url, '_blank');
                                    }}
                                >
                                    <p>{t("page.holding.oreo.button")}</p>
                                </a>
                            </div>
                        </div>
                    }
                </div>
            </>
        );
    }

}

export default withTranslation()(ProductSecond);