import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import { mainLoaderToggle } from "../../modules/Helpers";
import appGoogleTracking from "../../modules/GoogleTracking";
import GLOBAL_CONFIG from "../../config/Config";
import Banner from "../../components/Oreo/Banner";
import TitleContent from "../../components/TitleContent/TitleContent";
import Product from "../../components/Product/Product";
import ProductSecond from "../../components/ProductSecond/ProductSecond";

class Holding extends Component {
  componentDidMount() {
    const { t } = this.props;
    mainLoaderToggle("hide");
  }
  componentWillUnmount() {
    mainLoaderToggle("show");
  }

  render() {
    const { t, CampaignStatus } = this.props;

    return (
      <>
        {/* Metadata */}
        <Helmet>
          <title>{t('meta.title')}</title>
          <meta name="description" content={t('meta.description')} />
          <body className={window.URL_PREFIX + " holding"} />
        </Helmet>
        <Banner
          DesktopImage = {t("page.holding.oreo.banner.desktop")}
          MobileImage = {t("page.holding.oreo.banner.mobile")}
          AltText = {t("page.holding.oreo.banner.alt")}
        />
        

        <div className="wrapper oreo-holding">

        

          <div className="holding__content">
            <TitleContent
              title={t("page.holding.oreo.title")}
              description={t("page.holding.oreo.desc")}
            >

            </TitleContent>
          </div>

          <Product/>
          <div className="product-wrapper__second">
            <ProductSecond CampaignStatus={CampaignStatus} />
          </div>

        </div>
      </>
    );
  }
}

export default withTranslation()(Holding);
