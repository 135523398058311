import React from 'react';
import appGoogleTracking from '../../../modules/GoogleTracking';

const footerlogoV1 = (props) => (
    <div className="footer__logo">
        <p className="footer__logo__text">
            {props.t('footer.text')}
        </p>
        <p className="footer__logo__text2">
            {props.t('footer.para1')}
        <a 
                href={props.t('header.milkaUrl')} 
                target="_blank"
                rel="noopener noreferrer"
                title="Milka"
                data-event="go_to_milka"
                data-category="Footer"
                data-action="Go to Page"
                data-label="MILKA_ALLPAGE_FOOTER"
                onClick={appGoogleTracking.processEventCTA}
          > <span className="icon-milka-logo"></span></a>
            {props.t('footer.para2')}
        </p>
    </div>
);

export default footerlogoV1;