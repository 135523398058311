const _LOCAL_CAPTCHA = {
    // local: "6Lct-o4oAAAAAJTCtZTlhefMH7DRVHBO2SYkUNPF",
    local: "6LdkRKYnAAAAAF6HB1BV-PrOXbHlnc8_aNvVy9aN",

    muffin: {
        key: "6Ld09LMpAAAAAN46VEfcGBOjXx3jm6LSeViIo55N",
        // key: "6LdkRKYnAAAAAF6HB1BV-PrOXbHlnc8_aNvVy9aN",
        lang: "de"
    }
}

export default _LOCAL_CAPTCHA;