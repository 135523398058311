import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import GLOBAL_CONFIG, { GetRoute } from "../config/Config";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Holding from "../page/Oreo/Holding";
import Main from "../page/Oreo/Main";
import ThankYou from "../page/Oreo/ThankYou";
import NoParticipation from "../page/NoParticipation";
import End from "../page/Oreo/End";
import Terms from "../page/Oreo/Terms";
import TermsAndCond from "../page/TermsAndCond";
import PrivacyNotice from "../page/PrivacyNotice";
import CookiePolicy from "../page/CookiePolicy";
import Participation from "../page/Oreo/Participation";

class Layout extends Component {
  constructor() {
    super();
  }
  componentWillMount() {
    this.setState({
      CampaignStatus: this.props.campaignStatus
      // CampaignStatus: "end" // to test local
    })

  }
  render() {
    const { t } = this.props;
    const { CampaignStatus } = this.state;
    console.log("layout campaign status", CampaignStatus);
    //////For testing
    let RouterRender = null,
      urlPrefix = "/" + this.props.urlPrefix;
    let Routes = GetRoute(this.props.urlPrefix);
    if (this.props.campaign === true)
    {
      if (CampaignStatus === "holding")
      {
        RouterRender = (
          <Switch>
            <Route exact path={`${urlPrefix}${GLOBAL_CONFIG.Route.termsAndConditions}`} component={TermsAndCond} />
            <Route exact path={`${urlPrefix}${GLOBAL_CONFIG.Route.privacyNotice}`} component={PrivacyNotice} />
            <Route exact path={`${urlPrefix}${GLOBAL_CONFIG.Route.cookiePolicy}`} component={CookiePolicy} />
            <Route exact path={`${urlPrefix}${GLOBAL_CONFIG.Route.holding}`} render={(props) => <Holding {...props} CampaignStatus={CampaignStatus} />} />
            <Route component={NoParticipation} />
          </Switch>
        );
      } else if (CampaignStatus === "main")
      {
        RouterRender = (
          <Switch>
            <Route
              exact
              path={`${urlPrefix}${GLOBAL_CONFIG.Route.main}`}
              render={(props) => <Participation {...props} urlPrefix={urlPrefix} CampaignStatus={this.state.CampaignStatus} />}
            />
            <Route
              exact
              path={`${urlPrefix}${GLOBAL_CONFIG.Route.participation}`}
              render={(props) => <Participation {...props} urlPrefix={urlPrefix} CampaignStatus={this.state.CampaignStatus} />}
            />
            <Route
              exact
              path={`${urlPrefix}${Routes.thankYou}`}
              render={(props) => <ThankYou {...props} urlPrefix={urlPrefix} CampaignStatus={this.state.CampaignStatus} />}
            />
            {/* <Route exact path={`${urlPrefix}${Routes.thankYou}`} component={ThankYou} /> */}
            <Route exact path={`${urlPrefix}${Routes.termsAndConditions}`} component={Terms} />
            <Route exact path={`${urlPrefix}${Routes.privacyNotice}`} component={PrivacyNotice} />
            <Route exact path={`${urlPrefix}${Routes.cookiePolicy}`} component={CookiePolicy} />
            <Route exact path={`${urlPrefix}/end`} component={End} />
            <Route component={NoParticipation} />
          </Switch>
        );
      } else if (CampaignStatus === "end")
      {
        RouterRender = (
          <Switch>
            <Route path={`${Routes.end}`} component={End} />
            <Route exact path={`${urlPrefix}${Routes.cookiePolicy}`} component={CookiePolicy} />
            <Route exact path={`${urlPrefix}${Routes.privacyNotice}`} component={PrivacyNotice} />
          </Switch>
        );
      } else
      {
        RouterRender = (
          <Switch>
            <Route component={NoParticipation} />
          </Switch>
        )
      }
    }

    return (
      <>
        {
          this.props.urlPrefix === "de-de" ?
            <script>
              {`
            var esomeVariables = {
                page_type: '',
                content_ids: '',
                content_category: '',
                content_subcategory: '',
                product_name: '',
                content_type: '',
                value: '',
                currency: '',
                custom_parameter_one: '',
                custom_parameter_two: '',
                custom_parameter_three: '',
                custom_parameter_four: '',
                custom_parameter_five: '',
                order_id: '',
                page_id: ''
            };
          `}
            </script>
            : null
        }
        {
          this.props.urlPrefix === "de-de" ?
            <script id="esome-container-352" src="https://urldefense.com/v3/__https://container.cdn-eso.me/320/352.js__;!!KwM0_Rgk!aaJXiEk4Z_Qj-19D_YEL39fyOSrhq_HbGkML7hiawNj1bWfyUN4GBcPmy9TW3Q$ " async></script>
            : null
        }
        {
          this.props.urlPrefix === "de-de" ?
            <noscript><iframe src="https://urldefense.com/v3/__https://container.cdn-eso.me/320/352-html.html__;!!KwM0_Rgk!aaJXiEk4Z_Qj-19D_YEL39fyOSrhq_HbGkML7hiawNj1bWfyUN4GBcPPSQ86FQ$ "></iframe></noscript>
            : null
        }
        <Helmet>
          <meta property="og:title" content={t("meta.ogTitle")} />
          <meta property="og:description" content={t("meta.ogDescription")} />
          <meta property="og:url" content={t("meta.ogUrl")} />
          <meta property="og:site_name" content={t("meta.ogSitename")} />
        </Helmet>
        <main className={"body-content static-content " + window.COUNTRY_CODE}>
          {CampaignStatus === CampaignStatus.noCampaign ? null : <Header urlPrefix={this.props.urlPrefix} t={t} />}
          {RouterRender}
          <Footer CampaignStatus={CampaignStatus} urlPrefix={this.props.urlPrefix} t={t} />
        </main>
      </>
    );
  }
}

export default withRouter(withTranslation()(Layout));
