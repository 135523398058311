import React, { Component } from "react";
import { scrollToElementwithTimeout } from "../../../modules/Helpers";

export class FrCh extends Component {
  componentDidMount() {
    scrollToElementwithTimeout();
  }
  Table() {
    return (
      <>

        <p className="content__text borderline">1x Lot 1
        </p>
        <p className="content__text borderin">
          <b>Samsung TV 75" Neo QLED 8K QN800B</b>	 <br />
          Valeur en CHF: CHF 3.527,00 <br />
        </p>
        <p className="content__text borderin">
          <b>Microsoft, XBox Series X sans jeux</b>	 <br />
          Valeur en CHF: CHF 499,00
        </p>
        <p className="content__text borderin">
          <b>Manette sans fil Xbox Elite Series 2, 2 pièces</b>	 <br />
          Valeur en CHF: CHF 295,80
        </p>
        <p className="content__text borderin">
          <b>Sonos ARC Soundbar, noir</b>	 <br />
          Valeur en CHF: CHF 999,00
        </p>

        <p className="content__text borderline">1x Lot 2
        </p>
        <p className="content__text borderin">
          <b>Pack immersion totale 5.1 avec Sonos Arc</b>	 <br />
          Valeur en CHF: CHF 2.896,00 <br />
        </p>
        <p className="content__text borderin">
          <b>Samsung TV 50" Neo QLED 4K QN90C</b>	 <br />
          Valeur en CHF: CHF 2.099,00
        </p>

        <p className="content__text borderline">1x Lot 3
        </p>
        <p className="content__text borderin">
          <b>iPad Pro 12,9'', gris sidéral, 512 Go, Wi-Fi,
            sans gravure, sans pencil, sans clavier, sans Apple Care+</b>	 <br />
          Valeur en CHF: CHF 1.579,00<br />
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Ultra, sans Apple Care+</b>	 <br />
          Valeur en CHF: CHF 849,00
        </p>
        <p className="content__text borderin">
          <b>Appareil photo hybride Canon EOS R7 + objectif RF-S 18-150mm F3.5-6.3 IS STM</b>	 <br />
          Valeur en CHF: CHF 1.799,00
        </p>

        <p className="content__text borderline">2x Lot 4
        </p>
        <p className="content__text borderin">
          <b>Appareil photo hybride Canon EOS R7 + objectif RF-S 18-150mm F3.5-6.3 IS STM</b>	 <br />
          Valeur en CHF: CHF 1.799,00<br />
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Ultra, sans Apple Care+</b>	 <br />
          Valeur en CHF: CHF 849,00
        </p>

        <p className="content__text borderline">2x Lot 5
        </p>
        <p className="content__text borderin">
          <b>iPad Pro 12,9'', gris sidéral, 512 Go, Wi-Fi,
            sans gravure, sans pencil, sans clavier, sans Apple Care+</b>	 <br />
          Valeur en CHF: CHF 1.579,00<br />
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Ultra, sans Apple Care+</b>	 <br />
          Valeur en CHF: CHF 849,00
        </p>

        <p className="content__text borderline">2x Lot 6
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Ultra, sans Apple Care+</b>	 <br />
          Valeur en CHF: CHF 999,00
        </p>

        <p className="content__text borderline">1x Lot 7
        </p>
        <p className="content__text borderin">
          <b>Sonos ARC Soundbar, noir</b>	 <br />
          Valeur en CHF: CHF 849,00
        </p>

        <p className="content__text borderline">2x Lot 8
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Series 8, boîtier aluminium, minuit, 41mm, bracelet sport,
            connectivité GPS, sans Apple Care+</b>	 <br />
          Valeur en CHF: CHF 429,00
        </p>
        <p className="content__text borderin">
          <b>Air Pods Pro (2. Generation)</b>	 <br />
          Valeur en CHF: CHF 259,00
        </p>

        <p className="content__text borderline">3x Lot 9
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Series 8, boîtier aluminium, minuit, 41mm, bracelet sport,
            connectivité GPS, sans Apple Care+</b>	 <br />
          Valeur en CHF: CHF 429,00
        </p>

        <p className="content__text borderline">3x Lot 10
        </p>
        <p className="content__text borderin">
          <b>Air Pods Pro (2e génération)	</b>	 <br />
          Valeur en CHF: CHF 259,00
        </p>
        <p className="content__text borderin">
          <b>Home Pod mini	</b>	 <br />
          Valeur en CHF: CHF 99,00
        </p>
      </>
    )

  }
  render() {
    return (
      <div className="main__content">
        <p className="text__heading">CONDITIONS DE PARTICIPATION</p>
        <div className="content">
          <p className="content__text">
            Annonceur et organisateur : Baker & Baker Germany GmbH est le seul
            sponsor des prix au sens de l'article 657 du Code civil Allemand («
            organisateur »). D&K Brand Activation (Drewes & Keretic GmbH) agit
            en tant que prestataire de services mandaté par l'organisateur,
            responsable de la gestion globale du jeu concours, de la fourniture
            et de la distribution des prix. Consultix GmbH est une agence qui
            gère et conserve les données des participants. Proximity (RAPP
            Worldwide, Inc.) and AUW (artundweise GmbH) ont été engagés en tant
            que partenaire technique, agence de création et de conception
            numérique. Aucun de ces tiers n'est obligé de fournir ses propres
            services à la suite du jeu concours. Les points de vente et les
            plateformes de réseaux sociaux ne sont pas les organisateurs du jeu
            concours et n’agissent pas au nom de l'organisateur.
          </p>
          <p className="content__text">
            Conditions de participation : Toute personne physique âgée de plus
            de 18 ans et résidant en Allemagne, en Autriche, en Suisse, au
            Royaume-Uni, en France, en Belgique, aux Pays-Bas ou au Luxembourg
            peut participer. La condition préalable à la participation est que
            l'enregistrement en bonne et due forme ait eu lieu. A cet effet, le
            formulaire en ligne sur le site www.donut-muffin.oreo.eu doit être
            rempli et soumis par le participant et les présentes conditions de
            participation doivent être acceptées en cochant la case prévue à cet
            effet. La participation est sans obligation d’achat. Pour les
            participants résidants en Belgique, en raison des exigences
            réglementaires, une question doit être répondue correctement avant
            d'être éligible à participer.
          </p>
          <p className="content__text">
            Sont exclus de la participation, les employés et les proches des
            employés de Baker&Baker ainsi que les employés et les proches des
            employés de D&K Brand Activiation, Consultix GmbH, Proximity et AUW.
            Les participations effectuées par des prestataires de services
            mandatés, tels que les services de concours pour leurs
            clients/membres, sont expressément exclues. Les participations
            multiples ne sont pas autorisées. L'organisateur se réserve le droit
            d'exclure du concours les participants qui enfreignent les présentes
            conditions de participation, fournissent des informations erronées
            ou tentent d'influencer le déroulement du jeu concours de manière
            illicite. Dans ce cas, les participants exclus n'ont aucun droit au
            paiement du prix ou à toute autre compensation.
          </p>
          <p className="content__text">
            Annulation du concours : L'organisateur se réserve le droit de
            mettre fin prématurément au jeu concours pour un motif valable si,
            pour des raisons techniques (par exemple, manipulation de la page du
            jeu concours basée sur le site Web par des tiers ou infection du
            site Web par des virus), pour des raisons juridiques ou pour
            d'autres raisons autres que sous le contrôle de l'organisateur, le
            bon déroulement du jeu concours ne peut plus être garanti.
            L'organisateur ne peut en être tenu responsable, sauf en cas de
            faute intentionnelle ou de faute lourde de la part de
            l'organisateur. En cas d'annulation, les participants ne pourront
            prétendre à aucune indemnité ni contrepartie dans ces cas présents.
          </p>
          <p className="content__text">
            Période / délai de participation au jeu : Ce jeu sans obligation
            d’achat est ouvert du 1er septembre 2023 au 31 mars 2024.
          </p>
          <p className="content__text">
            Lot : Un total de 18 lots seront mis en jeu avec des prix allant
            d'environ : £ 300 / € 400 / CHF 350 à £ 4.200 / € 4.800 / CHF 5.000
            . Les gagnants se verront automatiquement attribuer un lot. La
            valeur totale de tous les prix ne dépassera pas 29.000 £ / 35.000 €
            / CHF 31.200 CHF. Voir la liste des lots ci-dessous. Sous réserve de la
            disponibilité des articles. Si des articles ne sont pas disponibles
            à ce moment-là, ils seront remplacés par des produits similaires, de
            valeur équivalente.
          </p>
          <p className="content__text">
            Distribution des prix : Les lots seront envoyés par un mode de
            livraison approprié dans les 4 semaines suivant le tirage au sort.
          </p>
          <p className="content__text">
            Détermination des gagnants : Les 18 gagnants seront déterminés au
            hasard, via un tirage au sort parmi toutes les participations
            conformes et complètes le 1er avril 2024. Il n'y aura pas de gagnant
            garanti dans chaque pays où le jeu concours est organisé.
          </p>
          <p className="content__text">
            Notification du gain, délai de réponse, déchéance du gain : Les
            gagnants seront informés par D&K Brand Activation à l'adresse e-mail
            qu'ils auront saisie dans le formulaire d'inscription, puis il leur
            sera demandé de fournir une adresse postale valide dans le but
            d'envoyer le lot. En principe, un gain ne peut être transférée à une
            autre personne. Si un gagnant ne fournit pas les données demandées
            dans un délai de 14 jours après l'envoi de la notification de gain,
            ou si la distribution de gain n'est pas possible en raison de
            données incorrectes ou incomplètes, un autre gagnant sera tiré au
            sort et le gain du gagnant, préalablement déterminé, devient caduc à
            cet égard. D&K Brand Activation enverra les lots aux gagnants
            respectifs.
          </p>
          <p className="content__text">
            Responsabilité: La responsabilité de l'organisateur et des personnes
            engagées par lui est exclue. L'exclusion de responsabilité ne
            s'applique pas au comportement intentionnel ou à la négligence grave
            et à la mort ou aux blessures corporelles. En cas de simple
            négligence, l’étendu de la responsabilité est limité aux principales
            obligations contractuelles, à savoir la bonne détermination des
            gagnants et la remise des lots. L'organisateur décline toute
            responsabilité dans le cas où un lot n'est pas livré (en totalité ou
            en partie) ou est endommagé pendant le transport / la livraison.
          </p>
          <p className="content__text">
            Divers: Seul le droit de la République fédérale d'Allemagne est
            applicable. Cette disposition n'affecte pas le droit de l'article 6,
            paragraphe 2, du règlement Rome I, selon lequel, malgré le choix de
            la loi, s'appliquent les dispositions impératives qui auraient été
            applicables sans le choix exprès de la loi. Si l'une de ces
            dispositions était ou devenait invalide, la validité des autres
            conditions de participation n'en serait pas affectée.
          </p>
          <p className="content__text">
            Protection des données: Mondelēz International (en tant que
            propriétaire de la marque Oreo) est responsable du traitement des
            données personnelles fournies par les participants et Consultix GmbH
            traitera les données personnelles aux fins du concours.
          </p>
          <p className="content__text">
            Les informations obligatoires sur le traitement des données requises
            par le règlement général de l'UE sur la protection des données
            ("RGPD") sont résumées ici (l'avis de confidentialité de Mondelēz
            International est accessible via{" "}
            <a
              href="https://eu.mondelezinternational.com/privacy-notice?sc_lang=fr-FR&siteId=oflz5sfu8Z3hVysFlH1urLGGgCAc6b2Z "
              target="_blank"
              rel="noreferrer"
            >
              https://eu.mondelezinternational.com/privacy-notice?sc_lang=fr-FR&siteId=oflz5sfu8Z3hVysFlH1urLGGgCAc6b2Z{" "}
            </a>
          </p>
          <p className="content__text">
            Responsable : Mondelēz International, Lindbergh-Allee 1, 8152
            Glattpark, Suisse, en tant que responsable du traitement, est
            responsable de la protection des données
          </p>
          <p className="content__text">
            Centre de protection des données : Vous pouvez contactez le centre
            de protection des données de Mondelez International à l’adresse :{" "}
            <a href="mailto:MDLZDataProtectionOfficeMEU@mdlz.com">
              MDLZDataProtectionOfficeMEU@mdlz.com{" "}
            </a>
          </p>
          <p className="content__text">
            Traitement des données : Afin de participer au jeu-concours via le
            site www.donut-muffin.oreo.eu, les données suivantes devront être
            renseignées lors de l'inscription : Civilité, prénom, nom, adresse
            email et date de naissance. Ces données personnelles seront
            utilisées pour mener à bien le déroulement du jeu concours,
            c'est-à-dire pour déterminer les gagnants, les informer et pouvoir
            leur envoyer leur lot. Les gagnants seront également invités à
            fournir séparément par email les informations suivantes pour la
            distribution des lots : leur adresse postale. Dans le cadre du
            trafic de courrier électronique, des fichiers journaux sont stockés
            sur les propriétés du courrier électronique et l'heure d'arrivée,
            ainsi que toutes les données spécifiées dans le courrier
            électronique par l'expéditeur.
          </p>
          <p className="content__text">
            Base juridique : Le traitement des données personnelles dans le
            cadre de l’inscription et de la distribution des lots s’effectue
            légalement sur la base du RGPD. Consultix GmbH traite les données
            pour exécuter le contrat sous la forme de la réalisation du tirage
            au sort et de l’obligation légale associée, article 6, paragraphe 1,
            point b. du RGPD.
          </p>
          <p className="content__text">
            Transmission à des tiers : Mondelēz International ne transmet pas
            les données des participants à des tiers au sens de la RGPD.
          </p>
          <p className="content__text">
            Sous-traitants: Mondelēz International utilise uniquement des
            prestataires de services dignes de confiance qui traitent vos
            données personnelles. Ils sont mandatés par écrit conformément aux
            exigences strictes de la RGPD et, par exemple, disposent de mesures
            techniques et organisationnelles avec lesquelles les prestataires de
            services protègent les données qui leur sont confiées contre toute
            utilisation abusive. Plus précisément, D&K Brand Activation a été
            chargé de gérer le concours et Proximity a été chargé de fournir le
            site Web du concours. Le traitement des commandes ne comprend que
            les services directement liés à la fourniture du service principal.
            Cela n'inclut pas les services auxiliaires tels que les services de
            télécommunications.
          </p>
          <p className="content__text">
            Transfert vers des pays tiers: Toutes les données personnelles sont
            collectées, traitées et stockées au sein de l'UE/EEE ainsi que par
            nos prestataires.
          </p>
          <p className="content__text">
            Durée de conservation : Mondelēz International et Consultix GmbH
            supprimeront:
            <br />
            <ul>
              <li>
                • les données personnelles des gagnants dès qu'il n'existe plus
                d'obligation commerciale ou fiscale de conservation contraire
                (cette dernière pouvant aller jusqu'à 10 ans) ;
              </li>
              <li>
                • les données personnelles des autres participants dès que les
                gagnants du concours auront été définitivement déterminés, car
                des tirage au sort supplémentaires peuvent avoir eu lieu en
                raison d'informations incomplètes ou de l'absence de réponse
                d'un gagnant préalablement identifié ;
              </li>
              <li>
                • les e-mails reçus dans le cadre du jeu concours : six (6) mois
                après la désignation définitive des gagnants.
              </li>
            </ul>
          </p>
          <p className="content__text">
            Cookies: Voir{" "}
            <a
              href="https://www.mavieencouleurs.fr/vie-privee "
              target="_blank"
              rel="noreferrer"
            >
              https://www.mavieencouleurs.fr/vie-privee
            </a>
          </p>
          <p className="content__text">
            Vos droits : Si les conditions légales sont remplies, vous disposez
            des droits suivants, qui peuvent être exercés contre Mondelēz
            International:
          </p>
          <p className="content__text">
            a. Droit d’être informé : Vous avez le droit à tout moment dans le
            cadre de l'art. 15 RGPD de demander à Mondelēz International de
            confirmer si elle traite des données personnelles vous concernant ;
            si tel est le cas, vous avez également droit en vertu de l'art. 15
            RGPD d’obtenir des informations sur ces données personnelles et
            certaines autres informations (par exemple, les finalités du
            traitement, les catégories de données personnelles, les catégories
            de destinataires, la période de conservation prévue, l'origine des
            données, l'utilisation d'une prise de décision automatisée et, dans
            le cas des transferts vers des pays tiers, les garanties
            appropriées) et de recevoir une copie de vos données.
          </p>
          <p className="content__text">
            b. Droit de rectification : Conformément à l'art. 16 RGPD, vous avez
            le droit de demander à Mondelēz International de corriger les
            données personnelles stockées à votre sujet si elles sont inexactes
            ou incorrectes.
          </p>
          <p className="content__text">
            c. Droit de suppression : Vous avez le droit, dans les conditions de
            l'art. 17 RGPD, d'exiger que Mondelēz International supprime
            immédiatement les données personnelles vous concernant. Le droit à
            la suppression n'existe pas, entre autres, si le traitement des
            données personnelles est requis conformément à l'art. 17 (3) RGPD.
          </p>
          <p className="content__text">
            d. Droit à la limitation du traitement : Vous avez le droit, dans
            les conditions de l'art. 18 RGPD, pour demander à Mondelēz
            International de limiter le traitement de vos données personnelles.
          </p>
          <p className="content__text">
            e. Droit à la portabilité des données : Vous avez le droit, sous
            réserve des exigences de l'art. 20 RGPD, de demander à Mondelēz
            International de transmettre les données personnelles pertinentes
            que vous avez fournies dans un format structuré, commun et lisible.
          </p>
          <p className="content__text">
            f. Droit de rétractation : Vous avez le droit de révoquer à tout
            moment votre consentement au traitement des données personnelles
            avec effet immédiat.
          </p>
          <p className="content__text">
            g. Droit d'opposition : Vous avez le droit, dans les conditions de
            l'art. 21 RGPD, de vous opposer au traitement de vos données
            personnelles, notamment sur la base d'une mise en balance des
            intérêts (art. 6 al. 1 lit. f) RGPD).
          </p>
          <p className="content__text">
            Informations sur votre droit d'opposition conformément à l'art. 21
            RGPD Vous avez le droit de vous opposer à tout moment au traitement
            de vos données, sur la base de l'art. 6 Par. 1 f) RGPD (traitement
            des données sur la base d'un équilibre des intérêts), s'il existe
            des raisons à cela qui découlent de votre situation particulière. Si
            vous vous y opposez, Mondelēz International ne traitera plus vos
            données personnelles, sauf si elles peuvent démontrer des motifs
            légitimes et impérieux de traitement qui l'emportent sur vos
            intérêts, droits et libertés, ou si le traitement sert à faire
            valoir, exercer ou défendre des droits légaux. L'objection peut être
            faite sous n'importe quelle forme et doit être adressée à{" "}
            <a href="mailto:MDLZDataProtectionOfficeMEU@mdlz.com">
              MDLZDataProtectionOfficeMEU@mdlz.com
            </a>{" "}
            si possible.
          </p>
          <p className="content__text">
            h. Droit de recours : Vous avez également le droit de saisir une
            autorité de contrôle compétente en cas de réclamation concernant des
            questions de protection des données. L'autorité de contrôle
            compétente est : <br />
          </p>
          <p className="content__text">
            VERBRAUCHERSERVICE
            <br />
            MONDELEZ DEUTSCHLAND SERVICES GMBH & CO. KG VERBRAUCHERSERVICE
            <br />
            POSTFACH 10 78 40, 28078 BREMEN
            <br />
            HOTLINE DE/AT/CH: 00800 83 00 00 36 KOSTENFREI <br />
            Phone: HOTLINE DE/AT/CH: 00800 83 00 00 36 FREE OF CHARGE <br />
            Email:{" "}
            <a href="mailto:verbraucherservice@mdlz.com">
              VERBRAUCHERSERVICE@MDLZ.COM
            </a>
          </p>
          <p className="content__text">Annexe: Détails des lots à gagner</p>
          <div className="prize">
            <p id="prize" className="content__text">Le jeu concours débute le 1er septembre 2023 et le tirage au sort aura lieu que le 1er avril 2024, la liste ci-dessous est donc soumise à la disponibilité des articles au moment de l’expédition aux gagnants. Si des articles sont indisponibles à ce moment-là, ils seront remplacés par des produits similaires, de valeur équivalente.</p>
          </div>

          {/* <p className="content__text">
            1x Lot 1 <br />
            Valeur en CHF
            <br />
            Samsung TV 75" Neo QLED 8K QN800B <br />CHF 3.527,00 <br />
            Microsoft, XBox Series X sans jeux <br />CHF 499,00 <br />
            Manette sans fil Xbox Elite Series 2, 2 pièces <br />CHF 295,80 <br />
            Sonos ARC Soundbar, noir <br />CHF 999,00
          </p>
          <p className="content__text">
            1x Lot 2 <br />
            Valeur en CHF
            <br />
            Pack immersion totale 5.1 avec Sonos Arc <br />CHF 2.896,00
            <br />
            Samsung TV 50" Neo QLED 4K QN90C <br /> CHF 2.099,00
          </p>
          <p className="content__text">
            1x Lot 3 <br />
            Valeur en CHF
            <br />
            iPad Pro 12,9'', gris sidéral, 512 Go, Wi-Fi, sans gravure, sans
            pencil, sans clavier, sans Apple Care+ <br />CHF 1.579,00
            <br />
            Apple Watch Ultra, sans Apple Care+ <br />CHF 849,00<br />
            Appareil photo hybride Canon EOS R7 + objectif RF-S 18-150mm
            F3.5-6.3 IS STM <br /> CHF 1.799,00
          </p>
          <p className="content__text">
            2x Lot 4 <br />
            Valeur en CHF
            <br />
            Appareil photo hybride Canon EOS R7 + objectif RF-S 18-150mm
            F3.5-6.3 IS STM <br /> CHF 1.799,00 <br />
            Apple Watch Ultra, sans Apple Care+ <br />CHF 849,00
          </p>
          <p className="content__text">
            2x Lot 5 <br />
            Valeur en CHF
            <br />
            iPad Pro 12,9'', gris sidéral, 512 Go, Wi-Fi, sans gravure, sans
            pencil, sans clavier, sans Apple Care+ <br />CHF 1.579,00 <br />
            Apple Watch Ultra, sans Apple Care+ <br />CHF 849,00
          </p>
          <p className="content__text">
            2x Lot 6 <br />
            Valeur en CHF
            <br />
            Apple Watch Ultra, sans Apple Care+ <br />CHF 999,00
          </p>
          <p className="content__text">
            1x Lot 7 <br />
            Valeur en CHF
            <br />
            Sonos ARC Soundbar, noir <br />CHF 849,00
          </p>
          <p className="content__text">
            2x Lot 8 <br />
            Valeur en CHF
            <br />
            Apple Watch Series 8, boîtier aluminium, minuit, 41mm, bracelet
            sport, connectivité GPS, sans Apple Care+ <br />CHF 429,00 <br />
            Air Pods Pro (2. Generation) <br />CHF 259,00
          </p>
          <p className="content__text">
            3x Lot 9 <br />
            Valeur en CHF
            <br />
            Apple Watch Series 8, boîtier aluminium, minuit, 41mm, bracelet
            sport, connectivité GPS, sans Apple Care+ <br />CHF 429,00
          </p>
          <p className="content__text">
            3x Lot 10 <br />
            Valeur en CHF
            <br />
            Air Pods Pro (2e génération) <br />CHF 259,00 <br />
            Home Pod mini <br />CHF 99,00
          </p> */}
          {this.Table()}
        </div>
      </div>
    );
  }
}

export default FrCh;
