import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next'
import parse from 'html-react-parser'
import ValidateForm from '../../modules/Validate'
import { mainLoaderToggle } from '../../modules/Helpers'
import GLOBAL_CONFIG, { GetRoute } from '../../config/Config'
import { withRouter } from 'react-router-dom'
import ReCaptcha from 'react-google-invisible-recaptcha'
import flatpickr from 'flatpickr'
import { French } from 'flatpickr/dist/l10n/fr.js'
import { German } from 'flatpickr/dist/l10n/de.js'
import { Dutch } from 'flatpickr/dist/l10n/nl.js' 
import { english } from 'flatpickr/dist/l10n/default.js' 

import Banner from "../../components/Oreo/Banner";
import TermsPopUp from './TermsPopUp'

//css import for flatpickr
import 'flatpickr/dist/flatpickr.css'
import _LOCALE from '../../Models/Locale'
import ParticipationServices from '../../Services/Participation'
import ErrorMessages from '../../Models/ErrorMessages'
import appGoogleTracking from '../../modules/GoogleTracking'

class Main extends Component {
  constructor() {
    super()

    this.createText = this.createText.bind(this)
    this.state = {
      isOpen: false,
      loaderSubmit: false,
      ErrorMessage: '',
      newsletter: "0",
      terms: "0",
      modal: false,
      thirparty: "0",
      sweepstake: null,
      urlPrefix: "",
      selectedCountry: "UK",
      ErrorRadio: ""
    }

    this.triggerCaptcha = this.triggerCaptcha.bind(this);
    this.processFormData = this.processFormData.bind(this);
  }

  toggleModal() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  showPopUp = () => {
    if((window.URL_PREFIX === "lb-lu") || (window.URL_PREFIX === "fr-lu")) {
      this.setState((prevState) => ({
        modal: !prevState.modal,
      }));
    }
    this.setState({urlPrefix: window.URL_PREFIX});
  }

  componentDidMount() {
    ValidateForm.validateField('participation__form')
    mainLoaderToggle('hide')
    var limit = new Date(),
      language = null
    limit.setFullYear(limit.getFullYear() - 18)

    switch (window.URL_PREFIX) {
      case 'de-de':
      case "de-at":
      case "de-ch":
      case "lb-lu":
        language = German
        break
      case 'fr-fr':
      case "fr-ch":
      case "fr-be":
      case "fr-lu":
        language = French
        break
      case "nl-nl": 
      case "nl-be":
        language = Dutch
        break

      default:
        language = english
        break
    }

    flatpickr(this._DETAILS_dob, {
      /* dateFormat: 'd.m.Y', */
      dateFormat: 'Y.m.d',
      maxDate: limit,
      disableMobile: true,
      locale: language,
    })

    this.showPopUp();
    this.LinkTag();
    this.getCountry();
  }

  componentWillUnmount() {
    mainLoaderToggle('show')
  }

  handleOptionChange = (event) => {
    this.setState({
      sweepstake: event.target.value
    });
    this.setState({
      ErrorRadio: '',
    })
  }

  getCountry () {
    //Set country state based on url prefix
    switch (window.URL_PREFIX) {
      case 'de-de':
        this.setState({selectedCountry: "Germany"});
        break
      case "de-at":
        this.setState({selectedCountry: "Austria"});
        break
      case "de-ch":
        this.setState({selectedCountry: "Switzerland"});
        break
      case "lb-lu":
        this.setState({selectedCountry: "Luxemburg"});
        break
      case 'fr-fr':
        this.setState({selectedCountry: "France"});
        break
      case "fr-ch":
        this.setState({selectedCountry: "Switzerland"});
        break
      case "fr-be":
        this.setState({selectedCountry: "Belgium"});
        break
        
      case "fr-lu":
        this.setState({selectedCountry: "Luxemburg"});
        break
      case "nl-nl": 
        this.setState({selectedCountry: "Netherlands"});
        break
      case "nl-be":
        this.setState({selectedCountry: "Belgium"});
        break

      default:
        this.setState({selectedCountry: "UK"});
        break
    }
  }

  getCaptcha = () => {
      var hostName = window.location.hostname,
        isProd = hostName.toLowerCase().indexOf('donut-muffin.oreo.eu') > -1 ? true : false,
        isQA = hostName.toLowerCase().indexOf('donut-muffin-oreo-eu.mdlzapps.cloud') > -1 ? true : false,
        isLocal = hostName.toLowerCase().indexOf('localhost') > -1 ? true : false
    
      if (isProd) {
        return GLOBAL_CONFIG.Captcha.prod;
      }
      else if(isQA){
        return GLOBAL_CONFIG.Captcha.siteKey;
      } else if(isLocal){
        return GLOBAL_CONFIG.Captcha.dev;
      }
    
  }

  filterCountries = () => {
    const countryList = ["UK", "Germany", "Austria", "Switzerland", "France", "Belgium", "Netherlands", "Luxemburg"];
    //removed selected country in the list of countries for the dropdown in the form so that the dropdown does not contain duplicate values
    const filteredCountries = countryList.filter(country => country !== this.state.selectedCountry);
    filteredCountries.sort();
    return filteredCountries
  }
  
  getDesc = () => {
    if((window.URL_PREFIX === "fr-ch") || (window.URL_PREFIX === "de-ch")) {
      return true
    } else {
      return false
    }
    
  }
  luxembourdText = () => {
    if((window.URL_PREFIX === "lb-lu") || (window.URL_PREFIX === "fr-lu")) {
      return true
    } else {
      return false
    }
    
  }
  render() {
    const CaptchaKey= this.getCaptcha()
    let Routes = GetRoute(window.URL_PREFIX); 
    const { t } = this.props;
    let sweepstake = null;
    
    let newsLetter = (
      <div class="form-container" id="name-newsletter">
        <div class="input-container">
          <label class="custom-inputs" for="newsletter">
            <input
              type="checkbox"
              class="js-event-type-in js-event-ab"
              id="newsletter"
              name="name-newsletter"
              data-type="checkbox"
              data-event-fieldname="name-newsletter"
              data-error-target="#error-newsletter"
              data-event="form-click"
              data-category="Form Action"
              data-action="name-newsletter"
              data-label="Type In"
              //checked={this.state.check}
              ref = { check => this._DETAILS_newsletter = check}
              onChange={(event) => { 
                this.handleChangeCheckbox(event, this._DETAILS_newsletter)
              }}
            />
            <span class="text-content">
              {parse(t('page.main.form.oreo.newsletter'))}
            </span>
            <span class="checkmark"></span>
            <span
              id="error-newsletter"
              class="error-declaration form-input__error"
            ></span>
          </label>
        </div>
      </div>
    )

    let thirdPartyOptIn = (
      <div class="form-container">
        <div class="input-container">
          <label class="custom-inputs" for="third_party">
            <input
              type="checkbox"
              class="js-event-type-in js-event-ab"
              id="third_party"
              name="name-thirparty"
              data-type="checkbox"
              data-event-fieldname="name-thirparty"
              data-error-target="#error-thirparty"
              data-event="form-click"
              data-category="Form Action"
              data-action="name-thirparty"
              data-label="Type In"
              //checked={this.state.check}
              ref = { check => this._DETAILS_thirparty = check}
              onChange={(event) => { 
                this.handleChangeCheckbox(event, this._DETAILS_thirparty)
              }}
            />
            <span class="text-content">
              {parse(t('page.main.form.oreo.thirdparty'))}
            </span>
            <span class="checkmark"></span>
            <span
              id="error-thirparty"
              class="error-declaration form-input__error"
            ></span>
          </label>
        </div>
      </div>
    )

    if(window.URL_PREFIX === "lb-lu" || window.URL_PREFIX === "fr-lu") {
      newsLetter = null;
    }
    if(
      window.URL_PREFIX === "lb-lu" ||
      window.URL_PREFIX === "fr-lu" || 
      window.URL_PREFIX === "de-de" ||
      window.URL_PREFIX === "de-at" ||
      window.URL_PREFIX === "de-ch" ||
      window.URL_PREFIX === "fr-ch" ) {
      thirdPartyOptIn = null;
    }
    if(window.URL_PREFIX === "nl-be" || window.URL_PREFIX === "fr-be") {
      sweepstake = (
        <>
        <div class="form-container" id="name-sweepstake">
          <div className="wrapper__form-text">
            <p className="text__heading">{parse(t('page.main.form.oreo.sweepstake_title'))}</p>
            <p className="text__desc">{parse(t('page.main.form.oreo.sweepstake_sub_title'))}</p>
            <p className="error">{this.state.ErrorRadio}</p>
          </div>
          <div class="input-container">
            <div className="radio">
              <label>
                <input
                  type="radio"
                  value="1805"
                  checked={this.state.sweepstake === "1805"}
                  onChange={this.handleOptionChange}
                />
                1805
              </label>
            </div>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  value="1912"
                  checked={this.state.sweepstake=== "1912"}
                  onChange={this.handleOptionChange}
                />
                1912
              </label>
            </div>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  value="2019"
                  checked={this.state.sweepstake === "2019"}
                  onChange={this.handleOptionChange}
                />
                2019
              </label>
            </div>
          </div>
        </div>

        </>
        
      )
    }
    let other = null;//for german salution
    if(window.URL_PREFIX === "de-de" ||
       window.URL_PREFIX === "de-at" ||
       window.URL_PREFIX === "de-ch" ||
       window.URL_PREFIX === "lb-lu") {
        other = (
        <option value="10">
          {t('page.main.form.oreo.salutation.other')}
        </option>
      )
    } else {
      other= (
        <option value="3">
        {t('page.main.form.oreo.salutation.ms')}
        </option>
      )
    }

    //Countris list for dropdown
    const countries = this.filterCountries()
    const desc_ch = this.getDesc();
    const luxembourdText = this.luxembourdText()
    return (
      <>
        <Helmet>
          <title>{t('meta.title')}</title>
          <meta name="description" content={t('meta.description')} />
          <body className={window.URL_PREFIX + ' main'} />
        </Helmet>
        <div className="main__wrapper oreo-main">
          <Banner
          DesktopImage = {t("page.main.banner.desktop")}
          MobileImage = {t("page.main.banner.mobile")}
          AltText = {t("page.main.banner.alt")}
          />
          <div className="main__content">
            <p className="text__heading">{t('page.main.form.oreo.title')}</p>
            <p className="text__desc">{desc_ch ? t('page.main.form.oreo.desc_ch') : t('page.main.form.oreo.desc')}</p>

            <div className="product-wrapper">
              <div className="item">
                <picture>
                  <source srcSet={t("page.holding.oreo.product.1")} media="(min-width: 481px)" />
                  <source srcSet={t("page.holding.oreo.product.1")} media="(min-width: 200px)" />
                  <img
                    className="product__img"
                    src={t("page.holding.oreo.product.1")}
                    alt="product"
                  />
                </picture>
                <p><a href={`/${window.URL_PREFIX}${Routes.termsAndConditions}#prize`} target='_blank' rel="noreferrer">{t("page.holding.oreo.product.product-similar")}</a></p>
              </div>
              <div className="item">
                <picture>
                  <source srcSet={t("page.holding.oreo.product.2")} media="(min-width: 481px)" />
                  <source srcSet={t("page.holding.oreo.product.2")} media="(min-width: 200px)" />
                  <img
                    className="product__img"
                    src={t("page.holding.oreo.product.2")}
                    alt="product"
                  />
                </picture>
                <p><a href={`/${window.URL_PREFIX}${Routes.termsAndConditions}#prize`} target='_blank' rel="noreferrer">{t("page.holding.oreo.product.product-similar")}</a></p>
              </div>
              <div className="item">
              <picture>
                  <source srcSet={t("page.holding.oreo.product.3")} media="(min-width: 481px)" />
                  <source srcSet={t("page.holding.oreo.product.3")} media="(min-width: 200px)" />
                  <img
                    className="product__img"
                    src={t("page.holding.oreo.product.3")}
                    alt="product"
                  />
                </picture>
                <p><a href={`/${window.URL_PREFIX}${Routes.termsAndConditions}#prize`} target='_blank' rel="noreferrer">{t("page.holding.oreo.product.product-similar")}</a></p>
              </div>
            </div>
          </div>
          
          <div className="main__form">
            <div className="wrapper__form-text">
              <p className="text__heading">{t('page.main.form.oreo.formTitle')}</p>
              <p className="text__desc">{t('page.main.form.oreo.formDesc')}</p>
            </div>
            <form
              className="participation__form"
              id="participation__form"
              method="post"
              action="/"
              noValidate
              onSubmit={this.onSubmitHandler.bind(this)}
            >
              <p className="required__field">{t('page.main.form.oreo.fields')}</p>
              <div className="form-input__container form-input__dropdown">
                <select
                  className="form-input__input js-event-type-in js-event-ab"
                  type="text"
                  name="salutation"
                  id="salutation"
                  placeholder={t('page.main.form.oreo.salutation.salute')}
                  data-require="true"
                  data-type="ddl"
                  data-error-target="#error-salutation"
                  data-required-message="Salutation is required."
                  data-event-fieldname="Salutation"
                  data-event="form-click"
                  data-category="Form Action"
                  data-action="Salutation"
                  data-label="Type In"
                >
                  <option value="default">
                    {t('page.main.form.oreo.salutation.salute')}
                  </option>
                  <option value="1">
                    {t('page.main.form.oreo.salutation.mr')}
                  </option>
                  <option value="2">
                    {t('page.main.form.oreo.salutation.mrs')}
                  </option>
                  {other}
                </select>
              </div>
              <div className="name">
                <div className="firstName">
                  <input
                    className="form-input__input js-event-type-in js-event-ab"
                    type="text"
                    name="firstname"
                    id="firstname"
                    placeholder={t('page.main.form.oreo.fname')}
                    data-require="true"
                    data-type="regex"
                    data-error-target="#error-firstname"
                    data-regex-pattern="^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðß ,.'-]+$"
                    data-required-message="Firstname is required."
                    data-pattern-message="Firstname not valid."
                    data-event-fieldname="Firstname"
                    data-event="form-click"
                    data-category="Form Action"
                    data-action="Firstname"
                    data-label="Type In"
                  />
                </div>
                <div className="lastName">
                  <input
                    className="form-input__input js-event-type-in js-event-ab"
                    type="text"
                    name="lastname"
                    id="lastname"
                    placeholder={t('page.main.form.oreo.lname')}
                    data-require="true"
                    data-type="regex"
                    data-error-target="#error-lastname"
                    data-regex-pattern="^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðß ,.'-]+$"
                    data-required-message="Lastname is required."
                    data-pattern-message="Lastname not valid."
                    data-event-fieldname="Lastname"
                    data-event="form-click"
                    data-category="Form Action"
                    data-action="Lastname"
                    data-label="Type In"
                  />
                </div>
              </div>
              <div className="details">
                <div className="email">
                  <input
                    className="form-input__input js-event-type-in js-event-ab"
                    type="text"
                    name="email"
                    id="email"
                    placeholder={t('page.main.form.oreo.email')}
                    data-require="true"
                    data-type="regex"
                    data-error-target="#error-email"
                    data-regex-pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,17}$"
                    data-required-message=""
                    data-pattern-message={t('page.main.form.oreo.emailError')}
                    data-event-fieldname="Email"
                    data-event="form-click"
                    data-category="Form Action"
                    data-action="Email"
                    data-label="Type In"
                  />
                  <span id="error-email" className="form-input__error"></span>
                </div>
                <div className="dob">
                  <label class="form-input__label" for="dob">
                    {t('page.main.form.oreo.dob')}
                  </label>
                  <input
                    className="form-input__input js-event-type-in js-event-ab"
                    type="text"
                    name="dob"
                    id="dob"
                    placeholder={t('page.main.form.oreo.dobFormat')}
                    autoComplete="off"
                    readOnly
                    data-require="true"
                    data-type="regex"
                    data-error-target="#error-dob"
                    /* regex for format: JJ.MM.TTTT*/
                    /* data-regex-pattern="^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$" */
                    data-regex-pattern="^\s*((?:19|20)?\d{2})\.(1[012]|0?[1-9])\.(3[01]|[12][0-9]|0?[1-9])\s*$"///* regex for format: TT.MM.JJ*/
                    data-required-message="Birthday is required."
                    data-pattern-message="Birthday is not valid."
                    data-event-fieldname="Birthday"
                    data-event="form-click"
                    data-category="Form Action"
                    data-action="Birthday"
                    data-label="Type In"
                    ref={(input) => (this._DETAILS_dob = input)}
                  />
                </div>
              </div>

              <div className="address__wrapper">
                <div className="street">
                  <input className="form-input__input js-event-type-in js-event-ab"
                      type="text"
                      name="street_name"
                      id="street_name"
                      placeholder={t('page.main.form.oreo.street')}
                      data-require="true"
                      data-type="text"
                      data-error-target="#error-street-number"
                      data-required-message="Street number is required."
                      data-event-fieldname="Street number"
                      data-event="form-click"
                      data-category="Form Action"
                      data-action="Street number"
                      data-label="Type In"
                      ref={input => this._DETAILS_street_name = input}
                  />
                </div>
                <div className="street_number">
                    <input className="form-input__input js-event-type-in js-event-ab"
                        type="text"
                        name="street_number"
                        id="street_number"
                        placeholder={t('page.main.form.oreo.number')}
                        data-require="true"
                        data-type="regex"
                        /* data-regex-pattern="^\d{1,15}$" */
                        data-regex-pattern="^.{1,60}$"
                        data-pattern-message={t('page.main.form.oreo.notValidMessage')}
                        data-error-target="#error-house-number"
                        /* data-required-message={t('page.main.form.oreo.requireMessage')} */
                        data-event-fieldname="House number"
                        data-event="form-click"
                        data-category="Form Action"
                        data-action="House number"
                        data-label="Type In"
                        ref={input => this._DETAILS_street_number = input}
                    />
                    <span id="error-house-number" className="form-input__error"></span>
                </div>
              </div>

              <div className="additional_adress__wrapper">
                <div className="additional_adress">
                    <input className="form-input__input js-event-type-in js-event-ab"
                        type="text"
                        name="address"
                        id="address"
                        placeholder={t('page.main.form.oreo.add_adress')}
                        data-require="false"
                        data-type="text"
                        data-event-fieldname="Address"
                        data-error-target="#error-add-adress"
                        data-required-message="additional adress is required."
                        data-event="form-click"
                        data-category="Form Action"
                        data-action="Address"
                        data-label="Type In"
                        ref={input => this._DETAILS_address = input}
                    />
                </div>
                <div className="zip_code">
                    <input className="form-input__input js-event-type-in js-event-ab js-zip-code"
                        type="text"
                        name="zip_code"
                        id="zip_code"
                        placeholder={t('page.main.form.oreo.zip_code')}
                        maxLength="25"
                        data-require="true"
                        data-type="text"
                        data-error-target="#error-zip-code"
                        /* data-regex-pattern="^[0-9]{5}$" */
                        /* data-required-message="Zip code is required." */
                        /* data-pattern-message={t('page.main.form.oreo.notValidMessage')} */
                        data-event-fieldname="Zip code"
                        data-event="form-click"
                        data-category="Form Action"
                        data-action="Zip code"
                        data-label="Type In"
                        ref={input => this._DETAILS_zipCode = input}
                    />
                    <span id="error-zip-code" className="form-input__error"></span>
                </div>
              </div>

              <div className="city__wrapper">
                <div className="city">
                  <input className="form-input__input js-event-type-in js-event-ab"
                      type="text"
                      name="city"
                      id="city"
                      placeholder={t('page.main.form.oreo.city')}
                      data-require="true"
                      data-type="text"
                      data-error-target="#error-city"
                      data-required-message="City is required."
                      data-event-fieldname="City"
                      data-event="form-click"
                      data-category="Form Action"
                      data-action="City"
                      data-label="Type In"
                      ref={input => this._DETAILS_city = input}
                  />
                </div>
                <div className="form-input__container form-input__dropdown">
                  <select
                  className="form-input__input js-event-type-in js-event-ab"
                  type="text"
                  name="country"
                  id="country"
                  placeholder={t('page.main.form.oreo.country')}
                  data-require="true"
                  data-type="ddl"
                  data-error-target="#error-country"
                  data-required-message="Country is required."
                  data-event-fieldname="country"
                  data-event="form-click"
                  data-category="Form Action"
                  data-action="country"
                  data-label="Type In"
                  ref={input => this._DETAILS_country = input}
                  > 
                    <option value={this.state.selectedCountry}>
                      {this.state.selectedCountry}
                    </option>
                    {countries.map((country, index) => (
                      <option key={index} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                </div>
              </div>


              <div className="form-checkbox">
                <div class="form-container" id="name-terms">
                  <div class="input-container">
                    <label class="custom-inputs" for="terms">
                      <input
                        type="checkbox"
                        class="js-event-type-in js-event-ab"
                        id="terms"
                        name="name-terms"
                        data-type="checkbox"
                        data-require="true"
                        data-required-message={t('page.main.form.oreo.termsError')}
                        data-event-fieldname="name-terms"
                        data-error-target="#error-terms"
                        data-event="form-click"
                        data-category="Form Action"
                        data-action="name-terms"
                        data-label="Type In"
                        ref = { check => this._DETAILS_terms = check}
                        onChange={(event) => { 
                          this.handleChangeCheckbox(event, this._DETAILS_terms)
                        }}
                      />
                      <span class="text-content">
                        
                        <div className="show-more">
                          {parse(t('page.main.form.oreo.termsCond'))}
                          <a href={`/${window.URL_PREFIX}${Routes.termsAndConditions}`} target='_blank' rel="noreferrer">
                          {parse(t('page.main.form.oreo.termsText'))}
                          </a>
                          {parse(t('page.main.form.oreo.termsText2'))}
                          {luxembourdText ? parse(t('page.main.form.oreo.termsText3')) : null}
{/*                           <a
                            onClick={() => {
                              this.createText();
                              appGoogleTracking.dataLayerPush({
                                'dataEvent': 	'go_to_more_info',
                                'dataCategory': 'Take Part Page',
                                'dataAction': 	'Go to More Information',
                                'dataLabel': 	'ENG_TPP_FORM'
                            });
                            }}
                            href="javascript:void(0)"
                            style={{ cursor: 'pointer' }}
                          >
                            {parse(t('page.main.form.oreo.showMore'))}
                          </a> */}
                        </div>
                        <div className="read-more">
                          {parse(t('page.main.form.readMore'))}
                        </div>
                      </span>
                      <span class="checkmark"></span>
                    </label>
                    <span
                      id="error-terms"
                      class="error-declaration form-input__error"
                    ></span>
                  </div>
                </div>
                {newsLetter}
                {thirdPartyOptIn}
                {sweepstake}
              </div>

              <ReCaptcha
                ref={(ref) => (this.recaptcha = ref)}
                locale={_LOCALE[window.URL_PREFIX]?.captcha || 'de'}
                sitekey={CaptchaKey}
                onResolved={this.onResolved}
              />
              {this.state.ErrorMessage.length > 0 ? (
                <p className="error">{this.state.ErrorMessage}</p>
              ) : null}
              <button
                class="oreo-button-blue"
                type="submit"
                data-event="info-click"
                data-category="Form Action"
                data-action="Submit"
                data-label="Type In"
              >
                {t('page.main.form.oreo.send')}
              </button>
              <div className="bottom-text">
                <p class="text-content">{t('page.main.form.oreo.bottomText')}</p>
                <p class="text-content2">{t('page.main.form.oreo.bottomText2')}</p>
              </div>
            </form>
          </div>
          {this.state.loaderSubmit ? (
            <div className="simple-loader__container active">
              <div className="simple-loader__indicator"></div>
            </div>
          ) : null}
        </div>
            {this.state.modal && <TermsPopUp
            modal={this.state.modal}
            toggleModal={() => {
              this.toggleModal();
            }}
            urlPrefix={this.state.urlPrefix}
          />}
          
      </>
    )
  }

  handleChangeCheckbox(e, _ref) {   
    let refName = _ref.name;
    refName = refName.replace("name-", "");
    /* console.log("refName",refName) */

    if (_ref.checked) {
      this.setState({
        /* newsletter: "1", */
        [`${refName}`]: "1"
      })
      /* console.log("newsLetter check: ", _ref.checked)
      console.log("newsLetter state: ", this.state.newsletter) */
    } else {
      this.setState({
        [`${refName}`]: "0",
      })
    }
  }

  LinkTag() {
    let termsTags = document.querySelectorAll('.linkTag');
    
/*     console.log("CheckboxLinkTag", termsTag);
    termsTag?.addEventListener("click",
      function (event) {
        appGoogleTracking.processEventCTA(event);
      }
    ); */

    for (const tag of termsTags) {
      tag?.addEventListener("click",
          function (event) {
              appGoogleTracking.processEventCTA(event);
          }
      );
    }
    
  }

  googleTag = (category, action, label) => {
    console.log('GTM')
    window.dataLayer.push({
      event: 'custom_event_click',
      GAeventCategory: category,
      GAeventAction: action,
      GAeventLabel: label,
    })
  }

  createText() {
    var textblock = document.querySelector('.read-more')
    textblock.style.display = 'block'
    var textlink = document.querySelector('.show-more')
    textlink.style.display = 'block'
  }

  validateRadioButton = () => {
    const { t } = this.props;
    if((window.URL_PREFIX === "fr-be") || (window.URL_PREFIX === "nl-be")) {
      console.log("validateRadioButtonFire")
      if(this.state.sweepstake){
        return true
      }else {
        this.setState({
          ErrorRadio: t('page.main.form.oreo.radioError'),
        })
        return false
      }
    } else {
      return true
    }
  }

  onSubmitHandler(event) {
    event.preventDefault()
    //Validation of fields
    let validateForm = ValidateForm.validateForm('participation__form')

    this.setState({
      ErrorMessage: '',
    })

    const validateRadioButton = this.validateRadioButton()

    if (validateForm === true && validateRadioButton) {
      this.triggerCaptcha()
    } else {
      document.querySelector('.notvalid').focus()
    }
  }

  //Trigger the captcha
  triggerCaptcha() {
    //Process captcha - after which form ajax will be handle
    if (this.recaptcha.getResponse() !== '') {
      this.recaptcha.reset()
    } else {
      this.recaptcha.execute()
    }
  }

  //Google recaptcha success callback
  onResolved = (token) => {
    this.processFormData(token)
  }

  //process form when captcha is ok
  processFormData(token) {
    this.setState({
      loaderSubmit: true,
    })

    let language_code, country_code, country;

    //Language code and country code
    
    switch (window.URL_PREFIX) {
      case "de-de":
        language_code = "DE";
        country_code = "DE";
        break;
      case "de-at":
        language_code = "AT";
        country_code = "AT";
        break;
      case "fr-ch":
        language_code = "FR";
        country_code = "CH";
        break;
      case "de-ch":
        language_code = "DE";
        country_code = "CH";
        break;
      case "en-gb":
        language_code = "EN";
        country_code = "GB";
        break;
      case "fr-fr":
        language_code = "FR";
        country_code = "FR";
        break;
      case "fr-be":
        language_code = "FR";
        country_code = "BE";
        break;
      case "nl-be":
        language_code = "NL";
        country_code = "BE";
        break;
      case "nl-nl":
        language_code = "NL";
        country_code = "NL";
        break;
      case "lb-lu":
        language_code = "DE";
        country_code = "LU";
        break;
      case "fr-lu":
        language_code = "FR";
        country_code = "LU";
        break;

      default:
          break;
    }

    //country value
    switch (this._DETAILS_country.value) {
      case "UK":
        country = "UK";
        break;
      case "Germany":
        country = "DE";
        break;
      case "Austria":
        country = "AT";
        break;
      case "Switzerland":
        country = "CH";
        break;
      case "France":
        country = "FR";
        break;
      case "Belgium":
        country = "BE";
        break;
      case "Netherlands":
        country = "NL";
        break;
      case "Luxemburg":
        country = "LU";
        break;

      default:
          break;
    }

    let birthday = document.getElementById('dob').value;
    let formattedBirthday = birthday.replace(/\./g, "-");

    let requestData = {
      salutation: document.getElementById('salutation').value,//1 = Mr, 2 = Mrs, 10 = Mx
      firstname: document.getElementById('firstname').value,
      lastname: document.getElementById('lastname').value,
      email: document.getElementById('email').value,
      dob: formattedBirthday,
      street1: this._DETAILS_street_name.value,
      streetnumber: this._DETAILS_street_number.value,
      street2: this._DETAILS_address.value,
      zipcode: this._DETAILS_zipCode.value,
      city: this._DETAILS_city.value,
      country: country,
      locale: window.URL_PREFIX,
      language: language_code,
      countryCode: country_code,
      newsletter: this.state.newsletter,
      thirdPartyOptIn: this.state.thirparty,
      sweepstake: this.state.sweepstake//BE and NL only,
    }

    ParticipationServices.ParticipateOreo(requestData)
      .then((response) => {
        let { status } = response,
          { success, data } = response.data

        var Routes = GetRoute(window.URL_PREFIX)
        if (success) {

        //Google Tracking
        let formTags = [
          this.state.terms && {
              "dataEvent": "terms_checkbox",
              "dataCategory": "Take Part Page",
              "dataAction": "Check Terms Checkbox",
              "dataLabel": "OREO_TPP_FORM",
          },
          this.state.newsletter && {
              "dataEvent": "newsletter_checkbox",
              "dataCategory": "Take Part Page",
              "dataAction": "Check Newsletter Checkbox",
              "dataLabel": "ENG_TPP_FORM",
          },
          {'dataEvent': 'take_part_now_button', 'dataCategory':'Take Part Page', 'dataAction': 'Click on Take Part Now', 'dataLabel': 'OREO_TPP_FORM'}
        ]
        console.log("formTagValue:",formTags)
        const tagItems = () => {
            formTags.map(
                (formTag) => {
                    return appGoogleTracking.dataLayerPush(formTag);
                }
                           
            )
        }
        tagItems();
          /**
           * Redirect user to thank you page
           */
          this.props.history.push(`/${window.URL_PREFIX}${Routes.thankYou}`)
        } else {
          let { Error } = data

          if (!!Error && Error.length > 0) {
            this.setState({
              ErrorMessage: Error[0]
                /* ErrorMessages[error[0]][_LOCALE[window.URL_PREFIX]?.country], */
            })
          }

          this.setState({
            loaderSubmit: false,
          })
          this.recaptcha.reset()
        }
      })
      .catch(() => {
        this.setState({
          loaderSubmit: false,
        })
        this.recaptcha.reset()
      })
  }
}

export default withRouter(withTranslation()(Main))
