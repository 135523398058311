import React, { Component, createRef } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import parse from 'html-react-parser';                                              //Parse HTML data from string
import ReCaptcha from 'react-google-invisible-recaptcha';                           //Google Recaptcha
import flatpickr from "flatpickr";                                                  //Date Picker
import Aux from '../../hoc/Auxiliare';
import GLOBAL_CONFIG, { GetRoute } from '../../config/Config';
import ValidateForm from '../../modules/Validate';                                  //Form validation module
import appGoogleTracking from '../../modules/GoogleTracking';                       //Google tracking module
import { mainLoaderToggle, /*navParticipationMenu,*/ timeStamp, dateConfig } from '../../modules/Helpers';

import Salutations from '../../Models/Salutation';
//CSS import for Datepicker
import 'flatpickr/dist/flatpickr.css';
import CampaignServices from '../../Services/Campaign';
import _LOCAL_CAPTCHA from '../../Models/Captcha';
import _MATH from '../../modules/Math';
import TitleContent from '../../components/TitleContent/TitleContent';
import Banner from '../../components/Oreo/Banner';
import Product from '../../components/Product/Product';
import { Helmet } from 'react-helmet';
import Popup from '../../components/popup/popup';

class Participation extends Component {

    constructor(props) {
        super(props);

        this.scrollToRef = createRef();

        this.handleUnload = this.handleUnload.bind(this);       //Binding to check for Abondanment form for Google tracking

        var limit = new Date();
        limit.setFullYear(limit.getFullYear() - 18);
        this.maxDOB = limit;
        this.DateFormat = 'd.m.Y';
        this.errorMessage = "Ein Fehler ist aufgetreten. Bitte versuchen <br />Sie es später noch einmal.";
        this.DefaultSum = "xx";
        this.ReceiptSymbol = "€";

        this.state = {
            lu: false,
            minDate: dateConfig({
                promotion: window.PROMOTION_CAMPAIGN,       //Minimum Purchase date
                status: 'min',
                ...GLOBAL_CONFIG.Date
            }),
            maxDate: this.getMaxDate(),
            genericErrorMessage: "",

            MoreTerms: false,
            loaderSubmit: false,
            loaderError: false,
            loaderGeneralError: false,

            isMobile: false,

            //formParticipation
            salutation: null,
            firstName: null,
            lastname: null,
            email: null,
            dob: null,
            newsletter: null,
            privacyPolicy: null,
            token: null,
            showPopup: false,
            errorFile: false,
            sweepstake: "",
        };

        this.DisplayParticipationDetails = this.DisplayParticipationDetails.bind(this);
        this.ProcessError = this.ProcessError.bind(this);
        this.ProcessFormData = this.ProcessFormData.bind(this);
        this.TriggerCaptcha = this.TriggerCaptcha.bind(this);
        this.resize = this.resize.bind(this);
        this.checkProductAmount = this.checkProductAmount.bind(this);
        this.googleTag = this.googleTag.bind(this);
        this.handlesweepstake = this.handlesweepstake.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }

    closePopup = () => {
        this.setState(prevState => ({
            showPopup: !prevState.showPopup
        }));
    }

    handlesweepstake = (event) => {
        this.setState({ sweepstake: event.target.value });

        appGoogleTracking.dataLayerPush({
            'dataEvent': 'select_year',
            'dataCategory': 'Start Page',
            'dataAction': 'Click on Checkbox',
            'dataLabel': 'MILKA_SP_PART'
        });

        let radios = document.querySelectorAll('.radio');
        radios.forEach(radio => {
            radio.classList.remove('notvalid');
        });
    };
    resize() {
        // set isMobile to true if the device is a mobile and false otherwise 
        this.setState({ isMobile: window.innerWidth <= 768 });
    }

    getMaxDate() {
        var maxDate = dateConfig({
            promotion: window.PROMOTION_CAMPAIGN,       //Maximum Purchase date
            status: 'max',
            ...GLOBAL_CONFIG.Date
        });
        var splitDate = maxDate.split(".");
        const dt = new Date(splitDate[2], splitDate[1] - 1, splitDate[0]);
        const now = new Date();
        if (now < dt)
        {
            const day = now.getDate();
            const month = now.getMonth() + 1;
            const year = now.getFullYear();
            return `${day}.${month}.${year}`;
        }
        return maxDate;
    }

    componentDidMount() {
        const { t } = this.props;
        //Remove main loader
        mainLoaderToggle('hide');
        //Menu navigation active 
        // navParticipationMenu(true);

        //Date picker for purchase receipt date
        if (!!this._RECEIPT_ReceiptDate)
        {
            flatpickr(this._RECEIPT_ReceiptDate, {
                dateFormat: this.DateFormat,
                minDate: this.state.minDate,
                maxDate: this.state.maxDate,
                disableMobile: true
            });
        }

        if (this.props.urlPrefix === '/de-de' || this.props.urlPrefix === '/de-at')
        {
            this.setState({ lu: true })
        }

        //Assign Validation function
        ValidateForm.validateField('frm_participation');    //parameter : form id

        const link1 = document.getElementById('link_18');
        if (link1)
        {
            link1.addEventListener('click', function (event) {
                appGoogleTracking.dataLayerPush({
                    'dataEvent': 'data_protection',
                    'dataCategory': 'Take Part Page',
                    'dataAction': 'Click on Data Protection',
                    'dataLabel': 'ENG_TPP_PART'
                });
            });
        }

        const link4 = document.getElementById('luetaccept');
        if (link4)
        {
            link4.addEventListener('click', function (event) {
                appGoogleTracking.dataLayerPush({
                    'dataEvent': 'click_conditions_register_now',
                    'dataCategory': 'Start Page',
                    'dataAction': 'Click on Conditions',
                    'dataLabel': 'ENG_SP_PART'
                });
            });
        }
        const link2 = document.getElementById('link_newsletter');
        if (link2)
        {
            link2.addEventListener('click', function (event) {
                appGoogleTracking.dataLayerPush({
                    'dataEvent': 'data_protection',
                    'dataCategory': 'Start Page',
                    'dataAction': 'Click on Data Protection',
                    'dataLabel': 'ENG_SP_PART'
                });
            });
        }
        const link21 = document.getElementById('link_newsletter1');
        if (link21)
        {
            link21.addEventListener('click', function (event) {
                appGoogleTracking.dataLayerPush({
                    'dataEvent': 'data_protection',
                    'dataCategory': 'Start Page',
                    'dataAction': 'Click on Data Protection',
                    'dataLabel': 'ENG_SP_PART'
                });
            });
        }

        const link22 = document.getElementById('link_newsletter2');
        if (link22)
        {
            link22.addEventListener('click', function (event) {
                appGoogleTracking.dataLayerPush({
                    'dataEvent': 'data_protection',
                    'dataCategory': 'Start Page',
                    'dataAction': 'Click on Data Protection',
                    'dataLabel': 'ENG_SP_PART'
                });
            });
        }

        const link3 = document.getElementById('link_contact');
        if (link3)
        {
            link3.addEventListener('click', function (event) {
                appGoogleTracking.dataLayerPush({
                    'dataEvent': 'go_to_placeholder_link',
                    'dataCategory': 'Start Page',
                    'dataAction': 'Click on Link',
                    'dataLabel': 'ENG_SP_OTHER'
                });
            });
        }
        window.addEventListener('beforeunload', this.handleUnload);
        window.addEventListener("resize", this.resize);
        this.resize();
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleUnload);
        mainLoaderToggle('show');
    }

    scroll() {
        const section = document.querySelector('.form-content-participation');
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    //Render of the HTML
    render() {
        const { t } = this.props;
        return (
            <Aux>
                <Helmet>
                    <title>{t('meta.title')}</title>
                    <meta name="description" content={t('meta.description')} />
                    <body className={window.URL_PREFIX + ' main'} />
                </Helmet>
                <div>
                    {
                        this.state.loaderSubmit ?
                            <div className="simple-loader__container active">
                                <div className="simple-loader__indicator"></div>
                            </div>
                            : null
                    }
                    {
                        this.state.showPopup ?
                            <Popup closePopup={this.closePopup} errorText={this.state.errorMessage}></Popup>
                            : null
                    }
                    {
                        this.state.loaderError ?
                            <div className="js-overlay overlay overlay--black">
                                <div className="overlay__container overlay__content">
                                    <div className="overlay__close" onClick={this.onClosePopupHandler}></div>
                                    <p className="overlay__title">Du hast bereits 3x an der Aktion teilgenommen.</p>
                                    <p id="js-error-msg">Über den gesamten Gewinnspielzeitraum ist eine dreimalige Teilnahme mit jeweils neuem gültigem Kassenbon erlaubt. Eine weitere Teilnahme ist leider nicht möglich.</p>
                                    <div className="overlay__footer">
                                        <p>Du möchtest wissen, welche Neuigkeiten es bei Milka gibt?</p>
                                        <a href="https://www.milka.de/neues" target="_blank" rel="noopener noreferrer">
                                            <div class="btn__container btn--primary btn--hover">
                                                <span class="btn__text">NEUES VON MILKA</span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div> : null
                    }
                    {
                        this.state.loaderGeneralError ?
                            <div className="js-overlay overlay overlay--black">
                                <div className="overlay__container overlay__content">
                                    <div className="overlay__close" onClick={this.onClosePopupHandler}></div>
                                    <p id="js-error-msg">{parse(this.state.genericErrorMessage)}</p>
                                </div>
                            </div> : null
                    }
                </div>


                <Banner
                    DesktopImage={t("page.main.banner.desktop")}
                    MobileImage={t("page.main.banner.mobile")}
                    AltText={t("page.holding.oreo.banner.alt")}
                />
                <div className="wrapper oreo-holding">

                    <div className="participation__content">
                        <TitleContent
                            title={parse(t("page.main.title"))}
                            description={t("page.main.desc")}
                        />
                    </div>
                    <div>
                        <Product CampaignStatus="main" />
                    </div>

                    <div className="product-wrapper__second marge">
                        {this.DisplayParticipationDetails(t)}
                    </div>
                </div>
            </Aux>
        );
    }

    DisplayParticipationDetails(t) {
        //Get Google recaptcha
        //rectif lasa statique le edaka
        let _captcha = _LOCAL_CAPTCHA['muffin'];
        return (<>
            <form
                id="frm_participation"
                name="frm_participation"
                method="post"
                action="/"
                onSubmit={this.onSubmitHandler.bind(this)} noValidate>
                <div className={`content-prize content-prize__${window.PROMOTION_CAMPAIGN}`} style={{ marginTop: "50px" }}>

                    {/* {this.DisplayRecieptForm(t)} */}

                </div>
                <div className="s-content-partication">

                    <div className="form-container centered">
                        <ReCaptcha
                            style={{ zIndex: "1000" }}
                            ref={ref => this._CAPTCHA = ref}
                            locale={_captcha.lang}
                            sitekey={_captcha.key}
                            onResolved={this.onResolved}
                        />
                    </div>
                    {this.DisplayParticipantForm(t)}
                    <div class="content-product_second">
                        <div class="btn-contentInvert">

                            <div class="btn" onClick={this.onSubmitHandler.bind(this)} >
                                <p>
                                    {t('page.main.form.oreo.send')}
                                </p>
                                {/* <button class="btn" type="submit">jetzt mitmachen</button> */}
                            </div>
                        </div>
                    </div>
                    <div class="containerForm2">
                        <div className="input-containerterms">
                            <label className="custom-inputs bottom-text">
                                <span className="text-content link" style={{ marginRight: '10px' }}>
                                    {parse(t('page.main.form.oreo.bottomText'))}
                                    <br></br>
                                    {parse(t('page.main.form.oreo.bottomText2'))}
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            </form>
        </>
        );
    }
    //rectif
    capitalizeFirstLetter(string) {
        return string;
        return string.charAt(0).toUpperCase() + string.slice(1);
    }


    DisplayParticipantForm(t) {
        let Routes = GetRoute(this.props.urlPrefix.substring(1));
        const terms2 = t('page.Participation.Form.Sections.Details.Field.Terms2', { returnObjects: true });
        let terms2Html = null;
        if (terms2 !== null && terms2 !== undefined)
        {
            terms2Html = (
                <div className="input-container">
                    <label className="custom-inputs" htmlFor="terms2">
                        <span className="text-content" >
                            {parse(terms2.HTML)}
                        </span>
                        <input type="checkbox" id="terms2" name="terms2" className="js-event-type-in js-event-ab" data-require="false"
                            onClick={() => this.googleTag('checkbox_conditions', 'Start Page', 'Click on Checkbox', 'EASTER_SP_PART')}
                        />

                        <span className="checkmark"></span>
                    </label>
                </div>
            );
        }

        return (
            //huhu form
            <div className="containerForm2">
                <div class="back-top">
                    <p></p>
                </div>
                <h2 className="title-participation1">{t("page.Participation.Form.Sections.Details", { returnObjects: true }).Title}</h2>
                <h3 className="subtitle-participation1">{t("page.Participation.Form.Sections.Details", { returnObjects: true }).Subtitle}</h3>

                {
                    this.props.urlPrefix === "/fr-be" || this.props.urlPrefix === "/nl-be" ? (
                        <div className="formulaires">

                            {this.props.urlPrefix === "/fr-be"
                                ?
                                <h3 className='minititle'>Merci de répondre correctement à la question afin de valider votre participation !</h3>
                                :
                                <h3 className='minititle'>Beantwoord de volgende vraag correct om succesvol deel te nemen!</h3>
                            }


                            {this.props.urlPrefix === "/fr-be"
                                ?
                                <p>En quelle année la marque Milka a-t-elle été créée?</p>
                                // <h3 className='minititle'>Merci de répondre correctement à la question afin de valider votre participation !</h3>
                                :
                                <p>In welk jaar werd het merk Milka opgericht?</p>
                                // <h3 className='minititle'>Beantwoord de volgende vraag correct om succesvol deel te nemen!</h3>
                            }

                            <label className="custom-radio">
                                <input
                                    type="radio"
                                    name="bool"
                                    className="custom-radio_input"
                                    value="1820"
                                    checked={this.state.sweepstake === "1820"} // Vérifie si l'option est sélectionnée
                                    onChange={this.handlesweepstake} // Appelle la fonction pour mettre à jour l'état lorsqu'une option est sélectionnée
                                />
                                <span className="radio"></span>1820
                            </label>
                            <label className="custom-radio">
                                <input
                                    type="radio"
                                    name="bool"
                                    className="custom-radio_input"
                                    value="1901"
                                    checked={this.state.sweepstake === "1901"}
                                    onChange={this.handlesweepstake}
                                />
                                <span className="radio"></span>1901
                            </label>
                            <label className="custom-radio">
                                <input
                                    type="radio"
                                    name="bool"
                                    className="custom-radio_input"
                                    value="1979"
                                    checked={this.state.sweepstake === "1979"}
                                    onChange={this.handlesweepstake}
                                />
                                <span className="radio"></span>1979
                            </label>
                        </div>
                    ) : null
                }
                <div class="form-container2" id="UserDetails">
                    <p id="Pflichtfelder">{t("page.Participation.Form.Sections.Details.req")}</p>
                    <div id="salutation-div" className="input-container">
                        {window.URL_PREFIX === 'de-de' || window.URL_PREFIX === 'de-at' ?

                            <div className="form-input__container">
                                <select style={{ fontFamily: "Good Headline Pro" }} ref={select => this._DETAILS_salutation = select} className="form-input__input js-event-type-in js-event-ab"
                                    type={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Salutation.Type}
                                    name={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Salutation.Id}
                                    id="salutation"
                                    placeholder={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Salutation.Label}
                                    data-require={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Salutation.Required}
                                    data-type="text"
                                    data-error-target={`#error-${t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Salutation.Id}`}
                                    data-required-message=""
                                    data-event-fieldname="Salutation"
                                    data-event="form-click"
                                    data-category="Form Action"
                                    data-action="Salutation"
                                    data-label="Type In">
                                    {
                                        Object.keys(Salutations.dropdown).map(key => {
                                            var i = 0;
                                            return <option value={Salutations.value[key]} key={`${i++}-${key}`} style={{ color: 'black' }}>{Salutations.dropdown[key]}</option>
                                        })
                                    }
                                </select>

                            </div>
                            : null}
                    </div>
                    {this.state.isMobile ?
                        null : <div className="input-container"></div>
                    }
                    <div id="firstName" className="input-container">
                        <div className="form-input__container">
                            {/* <label className="form-input__label" htmlFor={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.FirstName.Id} >{t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.FirstName.Label}</label> */}
                            <input className="form-input__input js-event-type-in js-event-ab"
                                type={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.FirstName.Type}
                                name={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.FirstName.Id}
                                id={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.FirstName.Id}
                                placeholder={t("page.main.form.oreo.fname")}
                                data-require={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.FirstName.Required}
                                data-error-target={`#error-${t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.FirstName.Id}`}
                                data-regex-pattern="^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðß ,'-]+$"
                                data-type="regex"
                                data-required-message=""
                                data-pattern-message=""
                                data-event-fieldname="Firstname"
                                data-event="form-click"
                                data-category="Form Action"
                                data-action="Firstname"
                                data-label="Type In"
                                ref={input => this._DETAILS_firstName = input}
                            />
                        </div>
                    </div>
                    <div id="lastName" className="input-container">
                        <div className="form-input__container">
                            {/* <label className="form-input__label" htmlFor={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.LastName.Id} >{t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.LastName.Label}</label> */}
                            <input className="form-input__input js-event-type-in js-event-ab"
                                type={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.LastName.Type}
                                name={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.LastName.Id}
                                id={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.LastName.Id}
                                placeholder={t("page.main.form.oreo.lname")}
                                data-require={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.LastName.Required}
                                data-type="regex"
                                data-error-target={`#error-${t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.LastName.Id}`}
                                data-regex-pattern="^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðß ,.'-]+$"
                                data-required-message=""
                                data-pattern-message=""
                                data-event-fieldname="Lastname"
                                data-event="form-click"
                                data-category="Form Action"
                                data-action="Lastname"
                                data-label="Type In"
                                ref={input => this._DETAILS_lastName = input}
                            />
                        </div>
                    </div>

                    <div id="email" className="input-container">
                        <div className="form-input__container">
                            <input className="form-input__input js-event-type-in js-event-ab"
                                type={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Email.Type}
                                name={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Email.Id}
                                id={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Email.Id}
                                placeholder={t("page.main.form.oreo.email")}
                                data-require={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Email.Required}
                                data-type="regex"
                                data-error-target={`#error-${t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Email.Id}`}
                                data-regex-pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,17}$"
                                data-required-message=""
                                // data-pattern-message="Ups, da hat sich wohl ein Fehler in die E-Mail-Adresse eingeschlichen. Bitte überprüfe deine E-Mail."
                                data-pattern-message={t("page.main.form.emailError")}
                                data-event-fieldname="Email"
                                data-event="form-click"
                                data-category="Form Action"
                                data-action="Email"
                                data-label="Type In"
                                ref={input => this._DETAILS_email = input}
                            />

                            <span id={`error-${t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Email.Id}`} className="form-input__error active"></span>
                        </div>
                    </div>
                    <div id="strabe" className="input-container">
                        <div className="form-input__container">
                            {/* <label className="form-input__label" htmlFor={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Email.Id} >{t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Email.Label}</label> */}
                            <input
                                className="form-input__input js-event-type-in js-event-ab"
                                type={
                                    t("page.Participation.Form.Sections.Details", {
                                        returnObjects: true,
                                    }).Field.Street.Type
                                }
                                name={
                                    t("page.Participation.Form.Sections.Details", {
                                        returnObjects: true,
                                    }).Field.Street.Id
                                }
                                id={
                                    t("page.Participation.Form.Sections.Details", {
                                        returnObjects: true,
                                    }).Field.Street.Id
                                }
                                placeholder={t("page.main.form.oreo.street")}
                                data-require={
                                    t("page.Participation.Form.Sections.Details", {
                                        returnObjects: true,
                                    }).Field.Street.Required
                                }
                                data-type="text"
                                data-error-target={`#error-${t("page.Participation.Form.Sections.Details", {
                                    returnObjects: true,
                                }).Field.Street.Id
                                    }`}
                                data-required-message=""
                                data-event-fieldname="Street"
                                data-event="form-click"
                                data-category="Form Action"
                                data-action="Street"
                                data-label="Type In"
                                ref={(input) => (this._DETAILS_street = input)}
                            />
                            <span id={`error-${t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Email.Id}`} className="form-input__error active"></span>
                        </div>
                    </div>

                    <div id="houseNumber" className="input-container">
                        <div className="form-input__container">
                            {/* <label className="form-input__label" htmlFor={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.HouseNumber.Id} >{t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.HouseNumber.Label}</label> */}
                            <input
                                className="form-input__input js-event-type-in js-event-ab"
                                type={
                                    t("page.Participation.Form.Sections.Details", {
                                        returnObjects: true,
                                    }).Field.HouseNumber.Type
                                }
                                name={
                                    t("page.Participation.Form.Sections.Details", {
                                        returnObjects: true,
                                    }).Field.HouseNumber.Id
                                }
                                id={
                                    t("page.Participation.Form.Sections.Details", {
                                        returnObjects: true,
                                    }).Field.HouseNumber.Id
                                }
                                placeholder={t("page.main.form.oreo.number")}
                                data-require={
                                    t("page.Participation.Form.Sections.Details", {
                                        returnObjects: true,
                                    }).Field.HouseNumber.Required
                                }
                                data-type="text"
                                data-error-target={`#error-${t("page.Participation.Form.Sections.Details", {
                                    returnObjects: true,
                                }).Field.HouseNumber.Id
                                    }`}
                                data-required-message=""
                                data-event-fieldname="HouseNumber"
                                data-event="form-click"
                                data-category="Form Action"
                                data-action="HouseNumber"
                                data-label="Type In"
                                ref={(input) => (this._DETAILS_houseNumber = input)}
                            />
                        </div>
                    </div>
                    <div id="address" className="input-container">
                        <div className="form-input__container">
                            {/* <label className="form-input__label" htmlFor={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Address.Id} >{t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Address.Label}</label> */}
                            <input
                                className="form-input__input js-event-type-in js-event-ab"
                                type={
                                    t("page.Participation.Form.Sections.Details", {
                                        returnObjects: true,
                                    }).Field.Address.Type
                                }
                                name={
                                    t("page.Participation.Form.Sections.Details", {
                                        returnObjects: true,
                                    }).Field.Address.Id
                                }
                                id={
                                    t("page.Participation.Form.Sections.Details", {
                                        returnObjects: true,
                                    }).Field.Address.Id
                                }
                                placeholder={t("page.main.form.oreo.add_adress")}
                                data-require={
                                    t("page.Participation.Form.Sections.Details", {
                                        returnObjects: true,
                                    }).Field.Address.Required
                                }
                                data-type="text"
                                data-error-target={`#error-${t("page.Participation.Form.Sections.Details", {
                                    returnObjects: true,
                                }).Field.Address.Id
                                    }`}
                                data-required-message=""
                                data-event-fieldname="Address"
                                data-event="form-click"
                                data-category="Form Action"
                                data-action="Address"
                                data-label="Type In"
                                ref={(input) => (this._DETAILS_Address = input)}
                            />
                        </div>
                    </div>

                    <div id="zip-code" className="input-container">
                        <div className="form-input__container">
                            {/* <label className="form-input__label" htmlFor={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.ZipCode.Id} >{t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.ZipCode.Label}</label> */}
                            <input
                                maxLength={t("page.main.form.oreo.plzLength")}
                                className="form-input__input js-event-type-in js-event-ab"
                                type={
                                    t("page.Participation.Form.Sections.Details", {
                                        returnObjects: true,
                                    }).Field.ZipCode.Type
                                }
                                name={
                                    t("page.Participation.Form.Sections.Details", {
                                        returnObjects: true,
                                    }).Field.ZipCode.Id
                                }
                                id={
                                    t("page.Participation.Form.Sections.Details", {
                                        returnObjects: true,
                                    }).Field.ZipCode.Id
                                }
                                placeholder={t("page.main.form.oreo.zip_code")}
                                data-require={
                                    t("page.Participation.Form.Sections.Details", {
                                        returnObjects: true,
                                    }).Field.ZipCode.Required
                                }
                                data-type="text"
                                data-error-target={`#error-${t("page.Participation.Form.Sections.Details", {
                                    returnObjects: true,
                                }).Field.ZipCode.Id
                                    }`}
                                data-regex-pattern="^[0-9]{5}$"
                                data-required-message=""
                                data-event-fieldname="ZipCode"
                                data-event="form-click"
                                data-category="Form Action"
                                data-action="ZipCode"
                                data-label="Type In"
                                ref={(input) => (this._DETAILS_ZipCode = input)}
                            />
                        </div>
                    </div>
                    <div id="city" className="input-container">
                        <div className="form-input__container">
                            {/* <label className="form-input__label" htmlFor={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.City.Id} >{t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.City.Label}</label> */}
                            <input
                                className="form-input__input js-event-type-in js-event-ab"
                                type={
                                    t("page.Participation.Form.Sections.Details", {
                                        returnObjects: true,
                                    }).Field.City.Type
                                }
                                name={
                                    t("page.Participation.Form.Sections.Details", {
                                        returnObjects: true,
                                    }).Field.City.Id
                                }
                                id={
                                    t("page.Participation.Form.Sections.Details", {
                                        returnObjects: true,
                                    }).Field.City.Id
                                }
                                placeholder={t("page.main.form.oreo.city")}
                                data-require={
                                    t("page.Participation.Form.Sections.Details", {
                                        returnObjects: true,
                                    }).Field.City.Required
                                }
                                data-type="text"
                                data-error-target={`#error-${t("page.Participation.Form.Sections.Details", {
                                    returnObjects: true,
                                }).Field.City.Id
                                    }`}
                                data-required-message=""
                                data-event-fieldname="City"
                                data-event="form-click"
                                data-category="Form Action"
                                data-action="City"
                                data-label="Type In"
                                ref={(input) => (this._DETAILS_City = input)}
                            />
                        </div>
                    </div>

                    <div id="country" className="input-container">
                        <div className="form-input__container">
                            <label style={{ color: "white", marginTop: "10px" }} className="form-input__label on-top" htmlFor={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Country.Id}>{t("page.main.form.oreo.country")}</label>
                            <select style={{ fontFamily: "Good Headline Pro", marginTop: "12px" }} ref={select => this._DETAILS_Country = select} className="form-input__input js-event-type-in js-event-ab"
                                type={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Country.Type}
                                name={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Country.Id}
                                id={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Country.Id}
                                data-type="ddl"
                                data-error-target={`#error-${t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Country.Id}`}
                                data-required-message=""
                                data-event-fieldname="Country"
                                data-event="form-click"
                                data-category="Form Action"
                                data-action="Country"
                                data-label="Type In">
                                {
                                    (t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Country.Options).map((country, index) => {
                                        var { Display, Value } = country;
                                        return <option value={Value} key={`${Value}-${index}`} style={{ color: 'black' }}>{Display}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div id="dob" className="input-container">
                        <div className="form-input__container dob">
                            <label style={{ color: "white", marginTop: "10px", marginLeft: "-20px" }} className="form-input__label on-top" htmlFor={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Birthday.Id}>{t("page.main.form.oreo.dob")} </label>
                            <input className="form-input__input js-event-type-in js-event-ab"
                                style={{ marginTop: "12px" }}
                                id="day"
                                type={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Birthday.Day.Type}
                                placeholder={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Birthday.Day.Label}
                                data-require={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Birthday.Required}
                                data-error-target={`#error-${t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Birthday.Id}`}
                                data-required-message=""
                                data-pattern-message=""
                                data-event-fieldname="Lastname"
                                data-event="form-click"
                                data-category="Form Action"
                                data-action="Day"
                                data-label="Type In"
                                ref={input => this._DETAILS_Birthday_day = input}
                                onChange={(e) => { this.checkMinimumAge(e, "^\s*(3[01]|[12][0-9]|0?[1-9])\s*$") }}
                            />
                            <input className="form-input__input js-event-type-in js-event-ab"
                                style={{ marginTop: "12px" }}
                                id="month"
                                type={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Birthday.Month.Type}
                                placeholder={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Birthday.Month.Label}
                                data-require={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Birthday.Required}
                                data-error-target={`#error-${t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Birthday.Id}`}
                                data-required-message=""
                                data-pattern-message=""
                                data-event-fieldname="Lastname"
                                data-event="form-click"
                                data-category="Form Action"
                                data-action="Month"
                                data-label="Type In"
                                ref={input => this._DETAILS_Birthday_month = input}
                                onChange={(e) => { this.checkMinimumAge(e, "^\s*(1[012]|0?[1-9])\s*$") }}
                            />
                            <input className="form-input__input js-event-type-in js-event-ab"
                                style={{ marginTop: "12px" }}
                                id="year"
                                type={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Birthday.Year.Type}
                                placeholder={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Birthday.Year.Label}
                                data-require={t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Birthday.Required}
                                //data-type="regex" 
                                data-error-target={`#error-${t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Birthday.Id}`}
                                //data-regex-pattern="^\s*((?:19|20)\d{2})\s*$" 
                                data-required-message=""
                                data-pattern-message=""
                                data-event-fieldname="Lastname"
                                data-event="form-click"
                                data-category="Form Action"
                                data-action="Year"
                                data-label="Type In"
                                ref={input => this._DETAILS_Birthday_year = input}
                                onChange={(e) => { this.checkMinimumAge(e, "^\s*((?:19|20)\d{2})\s*$") }}
                            />
                        </div>
                        <span id={`error-${t("page.Participation.Form.Sections.Details", { returnObjects: true }).Field.Birthday.Id}`} className="form-input__error active"></span>
                    </div>
                </div>
                {this.props.urlPrefix === '/de-de' || this.props.urlPrefix === '/de-at' ?
                    <div className="input-containerterms">
                        <label className="custom-inputs notborder" htmlFor="terms">
                            <span className="text-content">
                                {t("page.main.form.oreo.termsCond")}<a rel="noreferrer" target="_blank" href={t("page.main.form.oreo.linkTermsText")}
                                    onClick={(e) => {
                                        appGoogleTracking.dataLayerPush({
                                            'dataEvent': 'click_conditions_register_now',
                                            'dataCategory': 'Start Page',
                                            'dataAction': 'Click on Conditions',
                                            'dataLabel': 'ENG_SP_PART'
                                        })
                                    }}>{t("page.main.form.oreo.termsText")}</a> {t("page.main.form.oreo.termsText2")}<br />
                                {
                                    !this.state.MoreTerms && t("page.main.form.oreo.showMore") !== "" ?
                                        <a rel="noreferrer" target="_blank" href="/teilnahmebedingungen"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({ MoreTerms: true })
                                                appGoogleTracking.dataLayerPush({
                                                    'dataEvent': 'more_information',
                                                    'dataCategory': 'Start Page',
                                                    'dataAction': 'Click on More Information',
                                                    'dataLabel': 'ENG_SP_PART'
                                                })
                                            }}><strong>„{t("page.main.form.oreo.showMore")} &gt;&gt;“</strong></a> : null
                                }
                                {
                                    this.state.MoreTerms ?
                                        parse(t('page.main.form.oreo.more'))
                                        : null
                                }
                                <span style={{ fontFamily: "Good Headline Pro" }} id="error-terms" className="form-input__error active"></span>
                            </span>
                            <input ref={check => this._DETAILS_Terms = check} type="checkbox" id="terms" name="terms" className="js-event-type-in js-event-ab" data-require="true" data-type="checkbox" data-error-target="#error-terms" data-required-message={t('page.main.form.termsError')} data-event-fieldname="Terms"
                                onClick={() => this.googleTag('click_conditions_join_now', 'Start Page', 'Click on Conditions', 'ENG_SP_PART')}
                            />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    :
                    <div className="input-containerterms">
                        <label className="custom-inputs notborder" htmlFor='lu'>
                            <span className="text-content">
                                {parse(t("page.main.form.oreo.termsAccept"))}
                                <br />
                                <span style={{ fontFamily: "Good Headline Pro" }} id="error-lu" className="form-input__error active"></span>
                                <br />
                                {parse(t("page.main.form.oreo.termsAccept2"))}
                            </span>
                            <input checked={this.state.lu}
                                onChange={event => {
                                    this.setState({ lu: event.target.checked })
                                    this.googleTag('click_conditions_join_now', 'Start Page', 'Click on Conditions', 'ENG_SP_PART')
                                }} type="checkbox" id='lu' className="js-event-type-in js-event-ab" data-require="true" data-type="checkbox" data-error-target="#error-lu" data-required-message={t('page.main.form.termsError')} data-event-fieldname="Terms" />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                }
                {this.props.urlPrefix === '/de-de' || this.props.urlPrefix === '/de-at' ? null :
                    <div className="input-containerterms">
                        <label className="custom-inputs notborder" htmlFor="terms">
                            <span className="text-content">
                                {t("page.main.form.oreo.termsCond")}<a target="_blank" rel="noopener noreferrer" href={t("page.main.form.oreo.linkTermsText")}
                                    onClick={(e) => {
                                        appGoogleTracking.dataLayerPush({
                                            'dataEvent': 'data_protection',
                                            'dataCategory': 'Start Page',
                                            'dataAction': 'Click on Data Protection',
                                            'dataLabel': 'ENG_SP_PART'
                                        })
                                    }}>{t("page.main.form.oreo.termsText")}</a> {t("page.main.form.oreo.termsText2")}<br />
                            </span>
                            <input ref={check => this._DETAILS_Terms = check} type="checkbox" id="terms" name="terms" className="js-event-type-in js-event-ab" data-require="false" data-type="checkbox" data-event-fieldname="Terms"
                                onClick={() => this.googleTag('click_conditions_join_now', 'Start Page', 'Click on Conditions', 'ENG_SP_PART')}
                            />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                }
                {terms2Html}
                <div className="input-containerterms">
                    <label className="custom-inputs" htmlFor="newsletter" >
                        <span className="text-content link" style={{ marginRight: '10px' }}>
                            {parse(t('page.main.form.oreo.newsletter'))}
                            <br />
                            <br />
                            {parse(t('page.main.form.oreo.termsContact'))}
                        </span>
                        <input ref={check => this._DETAILS_News = check} type="checkbox" id="newsletter" name="newsletter" className="js-event-type-in js-event-ab" data-require="false" data-event-fieldname="Newsletter"
                            onClick={() => this.googleTag('checkbox_conditions', 'Start Page', 'Click on Checkbox', 'MILKA_SP_PART')}
                        />
                        <span className="checkmark"></span>
                    </label>
                </div>
            </div>
        );
    }











    calculateAge(birthday) {
        const ageDifMs = Date.now() - birthday;
        const ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    async isNumber(...n) {
        for (let i = 0; i < n.length; i++)
        {
            if (Number(n[i]) != n[i])
            {
                return false;
            }
        }
        return true;
    }

    checkMinimumAge(e, regex) {
        if (!e.target.value.match(regex))
        {
            e.target.classList.remove("valid");
            e.target.classList.add("notvalid");
        } else
        {
            e.target.classList.remove("notvalid");
            e.target.classList.add("valid");
        }
        const day = document.querySelector("input#day"),
            month = document.querySelector("input#month"),
            year = document.querySelector("input#year");
        if (day && month && year)
        {
            const dayValue = day.value,
                monthValue = month.value,
                yearValue = year.value;



            if (dayValue && monthValue && yearValue)
            {
                const birthday = new Date(yearValue, monthValue - 1, dayValue);
                const age = this.calculateAge(birthday);
                this.isNumber(dayValue, monthValue, yearValue).then((val) => {
                    let isTTOk = true;
                    let isMMOk = true;
                    if (dayValue > 31)
                    {
                        isTTOk = false;
                    }
                    if (monthValue > 12)
                    {
                        isMMOk = false;
                    }
                    if (!val || age < 18 || age > 100 || isTTOk == false || isMMOk == false)
                    {
                        day.classList.remove("valid");
                        month.classList.remove("valid");
                        year.classList.remove("valid");

                        day.classList.add("notvalid");
                        month.classList.add("notvalid");
                        year.classList.add("notvalid");
                    } else
                    {
                        day.classList.remove("notvalid");
                        month.classList.remove("notvalid");
                        year.classList.remove("notvalid");

                        day.classList.add("valid");
                        month.classList.add("valid");
                        year.classList.add("valid");

                    }
                });
            }
        }
    }


    checkProductAmount(value, t) {
        let minValue = t("page.Participation.Form.Sections.Receipt.Field.ProductAmount.MinValue", { returnObjects: true });
        if (value === "yes")
        {
            if (window.PROMOTION_CAMPAIGN === "rewe")
            {
                minValue = t("page.Participation.Form.Sections.Receipt.Field.ProductAmount.MinValueAdventCalendar", { returnObjects: true });
            }
        }
        this.checkMinValueOfProductAmount(minValue, t);
    }

    //Form abondonment - Tracking for Tagging plan
    handleUnload(e) {

        return appGoogleTracking.dataLayerPush({
            'dataEvent': 'form-click',
            'dataCategory': 'Form Action',
            'dataAction': 'Form Abandonment',
            'dataLabel': appGoogleTracking.fieldConcat
        });
    }
    //Close error message popup
    onClosePopupHandler = () => {
        this.setState({
            loaderError: false,
            loaderGeneralError: false
        });
    }

    //Form submit event
    onSubmitHandler(event) {

        function scrollOnErrerField() {
            const errorField = document.querySelector('.notvalid');
            if (errorField)
            {
                errorField.focus();
            }
            const errorFile = document.querySelector(".form-input__error.js-file-error.active");
            if (errorFile)
            {
                errorFile.focus();
            }




            errorField.scrollIntoView({ behavior: 'smooth', block: 'center' });

        }

        event.preventDefault();
        //Validation of fields
        let validateForm = ValidateForm.validateForm('frm_participation');

        if (this.props.urlPrefix === "/de-de" || this.props.urlPrefix === "/de-at")
        {
            if (this._DETAILS_salutation.value === "0")
            {
                let sal = document.getElementById('salutation');
                sal.classList.add('notvalid');
                scrollOnErrerField()
                return;
            }
        }

        if (this.props.urlPrefix === "/fr-be" || this.props.urlPrefix === "/nl-be")
        {
            if (this.state.sweepstake === "")
            {
                let radios = document.querySelectorAll('.radio');
                radios.forEach(radio => {
                    radio.classList.add('notvalid');
                });
                scrollOnErrerField()
                return;
            }
        }

        if (validateForm === true)
        {
            //Trigger captcha
            this.TriggerCaptcha();
            // }
        } else
        {
            scrollOnErrerField()
        }
    }


    //Trigger the captcha
    TriggerCaptcha() {
        //Process captcha - after which form ajax will be handle
        if (this._CAPTCHA.getResponse() !== '')
        {
            this._CAPTCHA.reset();
        } else
        {
            this._CAPTCHA.execute();
        }
    }

    //When captcha is good -> Callback when Google capture is good
    onResolved = (token) => {
        //Process form
        this.ProcessFormData(token);
        // alert('resolve')
    }

    //Construct server date : YYY-MM-DD
    serverDate = (date) => {
        let setDate = date.split('.');
        return setDate[2] + '-' + setDate[1] + '-' + setDate[0];
    }

    serverDateDMY = (day, month, year) => {
        return year + '-' + month + '-' + day;
    }

    //Trigger datalayer push when form is successful
    triggerDatalayers = (param) => {
        if (param.status === 'success')
        {
            let retailerPlace = this._RECEIPT_ProductAmount.value,
                datePurchase = this._RECEIPT_ReceiptDate.value;
            //Product retailer place
            appGoogleTracking.dataLayerPush({
                'dataEvent': 'button-click',
                'dataCategory': 'Click Action',
                'dataAction': 'Form Submission',
                'dataLabel': param.participationID
            });

            //Valid participation
            appGoogleTracking.dataLayerPush({
                'dataEvent': 'form-click',
                'dataCategory': 'Form Action',
                'dataAction': 'Form Validated',
                'dataLabel': param.participationID
            });

            //Receipt upload
            appGoogleTracking.dataLayerPush({
                'dataEvent': 'button-click',
                'dataCategory': 'Click Action',
                'dataAction': 'Upload Receipt',
                'dataLabel': timeStamp()
            });

            //Date purchase
            appGoogleTracking.dataLayerPush({
                'dataEvent': 'button-click',
                'dataCategory': 'Click Action',
                'dataAction': 'Date of Purchase',
                'dataLabel': datePurchase
            });

            //Place of Purchasse
            appGoogleTracking.dataLayerPush({
                'dataEvent': 'button-click',
                'dataCategory': 'Click Action',
                'dataAction': 'Amount Purchased',
                'dataLabel': retailerPlace
            });
        } else if (param.status === 'error')
        {
            if (param.type === 'participate')
            {
                appGoogleTracking.dataLayerPush({
                    'dataEvent': 'form-click',
                    'dataCategory': 'Form Action',
                    'dataAction': 'Already Participated',
                    'dataLabel': timeStamp()
                });
            } else
            {
                appGoogleTracking.dataLayerPush({
                    'dataEvent': 'form-click',
                    'dataCategory': 'Form Action',
                    'dataAction': 'Form Not Valid',
                    'dataLabel': 'Error Type'
                });
            }
        }
    }


    //Process form when Captcha is OK
    ProcessFormData(token) {
        //Open loader
        this.setState({
            loaderSubmit: true,
        });
        let locale = this.props.urlPrefix.substring(1);
        let language = locale.split('-')[0].toUpperCase();
        if (this.props.urlPrefix === '/de-at')
        {
            language = 'AT'
        }

        ////gestion formulaire data

        let data = {
            firstname: this._DETAILS_firstName.value,
            lastname: this._DETAILS_lastName.value,
            email: this._DETAILS_email.value,
            dob: this.serverDateDMY(
                this._DETAILS_Birthday_day.value,
                this._DETAILS_Birthday_month.value,
                this._DETAILS_Birthday_year.value
            ),
            street1: this._DETAILS_street.value,
            streetnumber: this._DETAILS_houseNumber.value,
            street2: this._DETAILS_Address.value,
            zipcode: this._DETAILS_ZipCode.value,
            city: this._DETAILS_City.value,
            salutation: this._DETAILS_salutation ? this._DETAILS_salutation.value : "1",
            newsletter: this._DETAILS_News.checked ? "1" : "0",

            locale: locale,
            language: language,
            //countryCode: this._DETAILS_Country.value,
            countryCode: locale.split('-')[1].toUpperCase(),

            thirdPartyOptIn: this._DETAILS_News.checked ? "1" : "0",

            recaptcha: token
        };



        if (this.props.urlPrefix !== "/de-de" && this.props.urlPrefix !== "/de-at")
        {
            // let salutation = this._DETAILS_salutation.value;
            // switch (salutation) {
            //     case "Herr": salutation = "1"; break;
            //     case "Frau": salutation = "2"; break;
            //     case "Divers": salutation = "10"; break;
            //     default: break;
            // }
            data.newsletter = this._DETAILS_Terms.checked ? "1" : "0"
            data.salutation = "1"
        }

        if (this.props.urlPrefix === "/fr-be" || this.props.urlPrefix === "/nl-be")
        {
            data.sweepstake = this.state.sweepstake;
        }
        console.log('data=========>', data);
        // Form Data is used to passe the receipt as binary
        let _data = new FormData();

        for (let key in data)
        {
            _data.append(key, data[key]);
        }

        CampaignServices.participation(data)
            .then((response) => {
                let { success, data } = response.data;
                console.log('response.data', response.data);
                if (response.data.success)
                {
                    // sessionStorage.setItem(
                    // GLOBAL_CONFIG.Session.userinfo,
                    //     JSON.stringify(data));
                    let userInfo = {
                        email: this._DETAILS_email.value,
                        pid: data.ParticipationId,
                        token: token
                    }
                    sessionStorage.setItem('userinfo', JSON.stringify(userInfo));
                    // console.log(window.URL_PREFIX);
                    // console.log(this.props.urlPrefix.substring(1));
                    let Routes = GetRoute(this.props.urlPrefix.substring(1));
                    appGoogleTracking.dataLayerPush({
                        'dataEvent': 'join_now',
                        'dataCategory': 'Start Page',
                        'dataAction': 'Click on Join Now',
                        'dataLabel': 'MILKA_SP_PART'
                    })
                    this.props.history.push({ pathname: `${this.props.urlPrefix}${Routes.thankYou}` });
                } else
                {
                    let errorStatus = data.Error[0];
                    this.setState({
                        showPopup: true,
                        errorMessage: errorStatus,
                        loaderSubmit: false,
                    });

                    this._CAPTCHA.reset();
                }

            })
            .catch((error) => {
                /* let { Data } = error.response.data,
                    errorStatus = Data.Error[0] || ""; */
                this.setState({
                    loaderSubmit: false,
                });
                // this.ProcessError(errorStatus);

                this._CAPTCHA.reset();
            });


    }

    ProcessError(_ERR) {
        switch (_ERR.toUpperCase())
        {
            case "NUMBEROFPARTICIPATION_INVALID":
                this.setState({
                    loaderSubmit: false,
                    loaderError: true,
                    genericErrorMessage: this.errorMessage
                });

                this.triggerDatalayers({
                    status: 'error',
                    type: 'participate'
                });
                break;
            case "INVALID_RECAPTCHA_RESPONSE":
                this.setState({
                    loaderSubmit: false,
                    loaderGeneralError: true,
                    genericErrorMessage: this.errorMessage
                });

                this.triggerDatalayers({
                    status: 'error',
                    type: 'general'
                });
                break;

            case "INVALID_LOTCODE":
                this.setState({
                    loaderSubmit: false,
                    loaderGeneralError: true,
                    CodeIsValid: false,
                    genericErrorMessage: "INVALID_LOTCODE"
                });

                if (!!this._DETAILS_ProductCode)
                {
                    this._DETAILS_ProductCode.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
                this.errorMessage = "Du hast keinen gültigen Produktcode eingegeben. Bitte versuche es erneut oder lade deinen Kassenbon hoch.";
            default:
                this.setState({
                    loaderSubmit: false,
                    loaderGeneralError: true,
                    genericErrorMessage: this.errorMessage
                });
                break;
        }
    }

    googleTag = (event, category, action, label) => {
        appGoogleTracking.dataLayerPush({
            'dataEvent': event,
            'dataCategory': category,
            'dataAction': action,
            'dataLabel': label
        })
    }


}

export default withTranslation()(withRouter(Participation));