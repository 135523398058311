import { Component } from "react";
import { withTranslation } from "react-i18next";
import './Product.scss';

class Product extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isMobile: false,
            currentSlide: 0,
        };
        this.handleResize = this.handleResize.bind(this);
        const path = window.location.pathname.split('/');
        this.pathName = path[path.length - 1];
    }

    componentDidMount() {
        console.log(this.pathName);
        // Ajoute un écouteur d'événements pour redimensionner
        window.addEventListener("resize", this.handleResize);
        // Appelle handleResize une première fois pour définir l'état initial
        this.handleResize();
    }

    componentWillUnmount() {
        // Supprime l'écouteur d'événements lors du démontage du composant
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize() {
        // Met à jour isMobile en fonction de la largeur de la fenêtre
        this.setState({ isMobile: window.innerWidth <= 768 });
    }

    nextSlide = () => {
        this.setState((prevState) => ({ currentSlide: (prevState.currentSlide + 1) % 4 }));
    };

    prevSlide = () => {
        this.setState((prevState) => ({ currentSlide: (prevState.currentSlide - 1 + 4) % 4 }));
    };
    generateCarouselCard = (t, index, imageKey, textKey, descKey, selected) => {
        const translateY = selected ? '0' : '60px'; // Décaler de 50 pixels vers le bas lorsque non sélectionné
        const scale = selected ? 'scale(1)' : 'scale(1.1)';
        const opacity = selected ? 1 : 0.45; // Baissez l'opacité pour les éléments non sélectionnés
        const zIndex = selected ? 2 : 0;
        return (
            <div className={`swiper-slide`} key={index} style={{ zIndex: zIndex }}
            >
                <div className={`carousel-card ${selected ? 'selected' : ''}`} style={{
                    transform: `translateY(${translateY}) ${scale}`,
                    transition: 'transform 0.3s ease-in-out',
                    opacity: opacity,

                }}>
                    <div className='product'>
                        <div className="content-img">
                            <div className={this.props.CampaignStatus === 'main' ? `card addDescri` : `card`}>
                                <div className="image">
                                    <img src={t(imageKey)} alt="Product" />
                                    {index === 3 ? null :
                                        <div className="badge">
                                            <p>{t("page.holding.oreo.product-caption-top")}</p>
                                        </div>
                                    }
                                    <p className="lien">*{t("page.holding.oreo.product-caption-bottom")}</p>
                                </div>
                                <div className="descri">
                                    <p>{t(textKey)}</p>
                                </div>
                                {this.props.CampaignStatus === 'main' ?
                                    (<div class="para">
                                        <p>{t(descKey)}</p>
                                    </div>) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };




    render() {
        const { t } = this.props;
        const { currentSlide } = this.state;
        return (
            <>
                {
                    this.state.isMobile === false ? (
                        // web view
                        <div>
                            <div class="product">
                                <div class="content-img">
                                    <div class="card">
                                        <div class="image">
                                            <img src={t("page.holding.oreo.product.1")} alt="Product" />
                                            <div class="badge">
                                                <p>{t("page.holding.oreo.product-caption-top")}</p>
                                            </div>
                                            <p class="lien">*{t("page.holding.oreo.product-caption-bottom")}</p>
                                        </div>
                                        <div class="descri">
                                            <p>{t("page.holding.oreo.product.1-text")}</p>
                                        </div>
                                        {this.props.CampaignStatus === 'main' ?
                                            (<div class="para">
                                                <p>{t("page.holding.oreo.product.1-desc")}</p>
                                            </div>) : null
                                        }
                                    </div>
                                    <div class="card">
                                        <div class="image">
                                            <img src={t("page.holding.oreo.product.3")} alt="Product" />
                                            <div class="badge">
                                                <p>{t("page.holding.oreo.product-caption-top")}</p>
                                            </div>
                                            <p class="lien">*{t("page.holding.oreo.product-caption-bottom")}</p>
                                        </div>
                                        <div class="descri">
                                            <p>{t("page.holding.oreo.product.3-text")}</p>
                                        </div>
                                        {this.props.CampaignStatus === 'main' ?
                                            (<div class="para">
                                                <p>{t("page.holding.oreo.product.3-desc")}</p>
                                            </div>) : null
                                        }
                                    </div>
                                    <div class="card">
                                        <div class="image">
                                            <img src={t("page.holding.oreo.product.2")} alt="Product" />
                                            <div class="badge">
                                                <p>{t("page.holding.oreo.product-caption-top")}</p>
                                            </div>
                                            <p class="lien">*{t("page.holding.oreo.product-caption-bottom")}</p>
                                        </div>
                                        <div class="descri">
                                            <p>{t("page.holding.oreo.product.2-text")}</p>
                                        </div>
                                        {this.props.CampaignStatus === 'main' ?
                                            (<div class="para">
                                                <p>{t("page.holding.oreo.product.2-desc")}</p>
                                            </div>) : null
                                        }
                                    </div>
                                </div>
                                <div class="content">
                                    <div class="sleep-card">
                                        <div class="image">
                                            <img src={t("page.holding.oreo.product.4")} alt="Product" />
                                            <p class="lien">*{t("page.holding.oreo.product-caption-bottom")}</p>
                                        </div>
                                        <div class="info">
                                            <div class="descri">
                                                <p>{t("page.holding.oreo.product.4-text")}</p>
                                            </div>
                                            {this.props.CampaignStatus === 'main' ?
                                                (<div class="para">
                                                    <p>{t("page.holding.oreo.product.4-desc")}</p>
                                                </div>) : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        // mobile view
                        <div>
                            <div className="carousel">
                                <div className="swiper-wrapper" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
                                    {this.generateCarouselCard(t, 0, "page.holding.oreo.product.1", "page.holding.oreo.product.1-text", "page.holding.oreo.product.1-desc", currentSlide === 0)}
                                    {this.generateCarouselCard(t, 1, "page.holding.oreo.product.2", "page.holding.oreo.product.2-text", "page.holding.oreo.product.2-desc", currentSlide === 1)}
                                    {this.generateCarouselCard(t, 2, "page.holding.oreo.product.3", "page.holding.oreo.product.3-text", "page.holding.oreo.product.3-desc", currentSlide === 2)}
                                    {this.generateCarouselCard(t, 3, "page.holding.oreo.product.4", "page.holding.oreo.product.4-text", "page.holding.oreo.product.4-desc", currentSlide === 3)}
                                </div>
                            </div>
                            <div className={this.props.CampaignStatus === 'main' ? `button-container-descri` : `button-container`} style={{ display: 'flex', justifyContent: 'center' }}>
                                <button className="swiper-button-prev" onClick={this.prevSlide}></button>
                                <button className="swiper-button-next" onClick={this.nextSlide}></button>
                            </div>
                        </div>
                    )
                }
            </>
        );
    }
}

export default withTranslation()(Product);