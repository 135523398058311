const _LOCALE = {
    "de-de": {
        captcha: "de",
        country: "DE"
    },
    "de-at": {
        captcha: "de-AT",
        country: "AT"
    },
    "fr-fr": {
        captcha: "fr",
        country: "FR"
    }
}

export default _LOCALE;