import React, { Component } from 'react'
import { scrollToElementwithTimeout } from '../../../modules/Helpers';

export class FrBe extends Component {
  componentDidMount() {
    scrollToElementwithTimeout();
  }
  Table() {
    return (
      <>

        <p className="content__text borderline">1x Lot 1
        </p>
        <p className="content__text borderin">
          <b>Pack immersion totale 5.1 avec Sonos Arc</b>	 <br />
          Valeur en Euro: € 2.896,00 <br />
        </p>
        <p className="content__text borderin">
          <b>Samsung TV 50" Neo QLED 4K QN90C</b>	 <br />
          Valeur en Euro: € 1.999,00
        </p>

        <p className="content__text borderline">1x Lot 2
        </p>
        <p className="content__text borderin">
          <b>Samsung TV 75" Neo QLED 8K QN800B</b>	 <br />
          Valeur en Euro: € 3.089,00 <br />
        </p>
        <p className="content__text borderin">
          <b>Microsoft, XBox Series X sans jeux</b>	 <br />
          Valeur en Euro: € 499,90
        </p>
        <p className="content__text borderin">
          <b>Manette sans fil Xbox Elite Series 2, 2 pièces</b>	 <br />
          Valeur en Euro: € 290,00
        </p>
        <p className="content__text borderin">
          <b>Sonos ARC Soundbar, noir</b>	 <br />
          Valeur en Euro: € 999,00
        </p>


        <p className="content__text borderline">1x Lot 3
        </p>
        <p className="content__text borderin">
          <b>iPad Pro 12,9'', gris sidéral, 512 Go, Wi-Fi,
            sans gravure, sans pencil, sans clavier, sans Apple Care+</b>	 <br />
          Valeur en Euro: € 1.849,00<br />
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Ultra, sans Apple Care+</b>	 <br />
          Valeur en Euro: € 999,00
        </p>
        <p className="content__text borderin">
          <b>Appareil photo hybride Canon EOS R7 + objectif RF-S 18-150mm F3.5-6.3 IS STM</b>	 <br />
          Valeur en Euro: € 1.999,00
        </p>




        <p className="content__text borderline">2x Lot 4
        </p>
        <p className="content__text borderin">
          <b>Appareil photo hybride Canon EOS R7 + objectif RF-S 18-150mm F3.5-6.3 IS STM</b>	 <br />
          Valeur en Euro: € 1.999,00<br />
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Ultra, sans Apple Care+</b>	 <br />
          Valeur en Euro: € 999,00
        </p>

        <p className="content__text borderline">2x Lot 5
        </p>
        <p className="content__text borderin">
          <b>iPad Pro 12,9'', gris sidéral, 512 Go, Wi-Fi,
            sans gravure, sans pencil, sans clavier, sans Apple Care+</b>	 <br />
          Valeur en Euro: € 1.849,00<br />
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Ultra, sans Apple Care+</b>	 <br />
          Valeur en Euro: € 999,00
        </p>

        <p className="content__text borderline">1x Lot 6
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Ultra, sans Apple Care+</b>	 <br />
          Valeur en Euro: € 999,00
        </p>

        <p className="content__text borderline">2x Lot 7
        </p>
        <p className="content__text borderin">
          <b>Sonos ARC Soundbar, noir</b>	 <br />
          Valeur en Euro: € 999,00
        </p>

        <p className="content__text borderline">2x Lot 8
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Series 8, boîtier aluminium, minuit, 41mm, bracelet sport,
            connectivité GPS, sans Apple Care+</b>	 <br />
          Valeur en Euro: € 499,00
        </p>
        <p className="content__text borderin">
          <b>Air Pods Pro (2. Generation)</b>	 <br />
          Valeur en Euro: € 299,00
        </p>

        <p className="content__text borderline">3x Lot 9
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Series 8, boîtier aluminium, minuit, 41mm, bracelet sport,
            connectivité GPS, sans Apple Care+</b>	 <br />
          Valeur en Euro: € 499,00
        </p>

        <p className="content__text borderline">3x Lot 10
        </p>
        <p className="content__text borderin">
          <b>Air Pods Pro (2e génération)	</b>	 <br />
          Valeur en Euro: € 299,00
        </p>
        <p className="content__text borderin">
          <b>Home Pod mini	</b>	 <br />
          Valeur en Euro: € 109,00
        </p>
      </>
    )

  }
  render() {
    return (
      <div className="main__content">
        <p className="text__heading">CONDITIONS DE PARTICIPATION</p>
        <div className="content">
          <p className="content__text">
            <strong>Prix général et organisateur :</strong> Baker & Baker Germany GmbH est le seul sponsor des prix au sens de l'article 657 du code civil allemand ("organisateur"). D&K Brand Activation (Drewes & Keretic GmbH) agit en tant que prestataire de services mandaté par l'organisateur, responsable de la gestion globale de la promotion ainsi que de la fourniture et de la distribution des prix.  Consultix GmbH est une agence qui gérer et conserver les données des participants.  Proximity (RAPP Worldwide, Inc.) et AUW (artundweise GmbH) ont été engagés en tant que partenaire de production technique et agence de création et de conception numérique. Aucun de ces tiers n'est tenu de fournir ses propres services dans le cadre du concours. Les détaillants et les plateformes de médias sociaux ne sont pas des organisateurs du concours et n'agissent pas au nom de l'organisateur.
          </p>
          <p className="content__text"><strong>Conditions de participation :</strong> Toute personne âgée de plus de 18 ans et résidant de manière permanente en Allemagne, en Autriche, en France, en Belgique ou aux Pays-Bas peut participer. La condition préalable à la participation est que l'inscription ait été effectuée en bonne et due forme. À cette fin, le formulaire en ligne sur le site www.donut-muffin.milka.eu doit être rempli et soumis par le participant et les présentes conditions de participation doivent être acceptées en cochant la case prévue à cet effet.  Il n'est pas nécessaire d'effectuer un achat pour participer.  Pour les participants résidant en Belgique, en raison d'exigences réglementaires, il faut répondre correctement à une question pour pouvoir participer.
          </p>
          <p className="content__text">Sont exclus de la participation les employés et les proches des employés de l'organisateur, ainsi que les employés et les proches des employés de D&K Brand Activation, Consultix GmbH, Proximity et AUW. Les participations effectuées par des prestataires de services mandatés, tels que les services de concours pour leurs clients/membres, sont expressément exclues. Les participations multiples ne sont pas autorisées. L'organisateur se réserve le droit d'exclure du concours les participants qui enfreignent les présentes conditions de participation, fournissent des informations incorrectes ou tentent d'influencer le déroulement du concours de manière illicite. Dans ce cas, les participants exclus n'ont aucun droit au paiement du prix ou à toute autre compensation.
          </p>
          <p className="content__text"><strong>Annulation du concours :</strong> L'organisateur se réserve le droit de mettre fin prématurément au concours pour un motif valable si, pour des raisons techniques (par exemple, manipulation de la page du concours basée sur le site web par des tiers ou infection du site web par des virus), pour des raisons juridiques ou pour d'autres raisons indépendantes de la volonté de l'organisateur, le bon déroulement du concours ne peut plus être garanti.  L'organisateur ne peut en être tenu pour responsable, sauf en cas de faute intentionnelle ou de faute lourde de sa part. En cas d'annulation, les participants n'ont droit à aucun prix ni à aucune autre compensation.
          </p>
          <p className="content__text"><strong>Période / délai :</strong> La participation n'est possible que pendant la période allant du 1er mai 2024 à 0h00 au 30 novembre 2024 à 23h59.
          </p>
          <p className="content__text"><strong>Prix :</strong> Au total, 53 gagnants seront sélectionnés, 3 gagnants d'un gain pour organiser un évènement en famille (d'une valeur  unitaire d'environ 5 000 euros) et 50 gagnants d'un panier pique-nique (d'une valeur maximale de 100 euros chacun).  Les gagnants se verront automatiquement attribuer un prix.  La valeur totale de tous les prix ne dépassera pas 20 000 euros. Voir la liste des prix ci-dessous.
          </p>
          <p className="content__text">- 3 gains pour organiser un évènement en famille (d'une valeur unitaire approximative de 5 000 euros) - comprenant par exemple la restauration, la réservation d'un lieu/endroit si nécessaire, la location de matériel, la décoration et d'autres activités jusqu'à hauteur de la valeur maximale. Le choix final de l'exécution de la fête de famille est laissé à la discrétion de D&K en accord avec l'organisateur.  Si les demandes individuelles du gagnant dépassent la limite du prix, le gagnant devra payer le surplus directement au(x) fournisseur(s).  L'événement doit être réservé et réalisé au plus tard le 31 décembre 2025.  Si le coût de l'événement est inférieur à 5 000 euros, D&K Brand Activation fournira des chèques-cadeaux ou des articles de fête appropriés, à sa discrétion, jusqu'à hauteur de la valeur du manque à gagner
          </p>
          <p className="content__text">- 50 paniers pique-nique (d'une valeur unitaire de 100 €).
          </p>
          <p className="content__text">Tous les articles sont soumis à disponibilité. Si les articles ne sont pas disponibles à ce moment-là, ils seront remplacés par des produits similaires d'une valeur au moins égale.
          </p>
          <p className="content__text">Dans le cadre des gains pour organiser un évènement en famille, les représentants de D&K Brand Activation mettront jusqu'à 20 heures (du lundi au vendredi de 10h00 à 16h00) à la disposition de chaque gagnant pour l'aider à planifier l'événement, à sélectionner les lieux et les fournisseurs, à négocier et à finaliser les accords avec les fournisseurs (y compris le paiement des factures liées à l'événement jusqu'à hauteur du montant maximum indiqué).
          </p>
          <p className="content__text"><strong>Distribution des prix :</strong> Les 50 paniers de pique-nique seront envoyés par un moyen de livraison approprié dans les 4 semaines suivant le tirage au sort des prix.
          </p>
          <p className="content__text"><strong>Détermination des gagnants :</strong> Les gagnants seront désignés par tirage au sort parmi toutes les participations valides et correctes le 2 décembre 2024.  Il n'y aura pas de gagnant garanti dans chaque pays où le concours est organisé.
          </p>
          <p className="content__text"><strong>Notification du prix, période de confirmation, expiration du prix :</strong> Les gagnants seront informés par D&K Brand Activation à l'adresse électronique qu'ils auront indiquée dans le formulaire d'inscription et devront alors fournir un numéro de téléphone et une adresse postale à des fins de communication ou une adresse postale pour l'envoi du prix (si un prix plus petit a été gagné). En principe, le droit à un prix ne peut être transféré à une autre personne. Si un gagnant ne fournit pas les données demandées dans un délai de 14 jours (pour les paniers pique-nique) ou de 28 jours (pour les gains pour organiser un évènement en famille) après l'envoi de la notification du prix, ou si la distribution du prix n'est pas possible en raison de données incorrectes ou incomplètes, un autre gagnant sera sélectionné et le droit au prix du gagnant déterminé à l'origine sera annulé à cet égard.  D&K Brand Activation enverra les paniers pique-nique aux gagnants respectifs.
          </p>
          <p className="content__text"><strong>Responsabilité :</strong> La responsabilité de l'organisateur et des personnes engagées par lui est exclue.  L'exclusion de la responsabilité ne s'applique pas au comportement intentionnel ou à la négligence grave, ni au décès ou aux dommages corporels.  En cas de simple négligence, l'étendue de la responsabilité est limitée aux principales obligations contractuelles, à savoir la détermination correcte des gagnants et la remise des lots.  L'organisateur décline toute responsabilité à l'égard d'un gagnant si un lot n'est pas livré (en tout ou en partie) ou s'il est endommagé pendant le transport.
          </p>
          <p className="content__text"><strong>Divers :</strong> Le droit de la République fédérale d'Allemagne est exclusivement applicable. Cette disposition n'affecte pas le droit de l'article 6, paragraphe 2, du règlement Rome I, selon lequel, malgré le choix de la loi, s'appliquent les dispositions obligatoires qui auraient été applicables sans le choix exprès de la loi.  Si l'une de ces dispositions est ou devient invalide, la validité des autres conditions de participation n'en est pas affectée.
          </p>
          <p className="content__text"><strong>Protection des données :</strong> Mondelēz International (en tant que propriétaire de la marque Milka) est le responsable du traitement des données personnelles fournies par les participants et Consultix GmbH traitera les données personnelles aux fins du concours.
          </p>
          <p className="content__text">Les informations obligatoires sur le traitement des données requises par le règlement général sur la protection des données de l'UE ("GDPR") sont résumées ici (l'avis de confidentialité de Mondelēz International peut être consulté via : <a href="https://privacy.mondelezinternational.com/eu/fr-FR/privacy-notice/" target="_blank" rel="noreferrer">https://privacy.mondelezinternational.com/eu/fr-FR/privacy-notice/</a>
          </p>
          <p className="content__text">Responsable : Mondelēz International, Lindbergh-Allee 1, 8152 Glattpark, Suisse, en tant que responsable du traitement, est responsable de la protection des données.
          </p>
          <p className="content__text">Responsable de la protection des données : Vous pouvez joindre le délégué à la protection des données de Mondelēz International à l'adresse suivante : <a href="mailto:MDLZDataProtectionOfficeMEU@mdlz.com" target="_blank" rel="noreferrer">MDLZDataProtectionOfficeMEU@mdlz.com</a>
          </p>
          <p className="content__text">Traitement des données : Pour participer au concours via le site web <a href="https://www.donut-muffin.milka.eu" target="_blank" rel="noreferrer">www.donut-muffin.milka.eu</a>, les données suivantes doivent être fournies lors de l'inscription : Titre, prénom, nom, adresse électronique, adresse et date de naissance. Ces données personnelles sont traitées pour la réalisation du concours, c'est-à-dire pour déterminer les gagnants, les notifier, pouvoir communiquer avec eux et (le cas échéant) leur envoyer un prix. Les gagnants doivent également fournir les informations suivantes pour la distribution des prix : adresse postale. Si vous gagnez, vous serez invité à le faire séparément par courrier électronique. Dans le cadre du trafic de courriers électroniques, des fichiers journaux sont stockés sur les propriétés du courrier électronique et l'heure d'arrivée, ainsi que toutes les données spécifiées dans le courrier électronique par l'expéditeur.
          </p>
          <p className="content__text">Base juridique : Le traitement des données personnelles dans le cadre de l'inscription et de la distribution des prix a lieu légalement sur la base du GDPR. Consultix GmbH traite les données pour exécuter le contrat sous la forme de l'exécution du tirage au sort et de l'obligation légale associée, article 6, paragraphe 1, lit. b) du GDPR.
          </p>
          <p className="content__text">Transmission à des tiers : Mondelēz International ne transmet pas les données des participants à des tiers au sens du GDPR.
          </p>
          <p className="content__text">
            Sous-traitants : Mondelēz International ne fait appel qu'à des prestataires de services dignes de confiance pour le traitement de vos données à caractère personnel. Ils sont mandatés par écrit conformément aux exigences strictes du GDPR et disposent, par exemple, de mesures techniques et organisationnelles avec lesquelles les prestataires de services protègent les données qui leur sont confiées contre toute utilisation abusive. En particulier, D&K Brand Activation a été chargé de gérer le concours et Proximity a été chargé de fournir le site web du concours. Le traitement des commandes ne comprend que les services directement liés à la fourniture du service principal. Cela n'inclut pas les services auxiliaires tels que les services de télécommunications.
          </p>
          <p className="content__text">Transfert vers des pays tiers : Toutes les données à caractère personnel sont stockées au sein de l'UE/EEE, y compris par les sous-traitants.
          </p>
          <p className="content__text">Durée du stockage :  Mondelēz International et Consultix GmbH supprimeront :
          </p>
          <p className="content__text">- les données personnelles des gagnants dès qu'il n'y a plus d'exigences commerciales ou fiscales de conservation contraires (ces dernières peuvent aller jusqu'à 10 ans) ;
          </p>
          <p className="content__text">- les données personnelles des autres participants dès que les gagnants du concours ont été définitivement désignés, c'est-à-dire que des procédures de tirage au sort supplémentaires peuvent avoir eu lieu en raison d'informations incomplètes ou de l'absence de réponse d'un gagnant précédemment identifié ;
          </p>
          <p className="content__text">- les courriers électroniques reçus dans le cadre du concours : six (6) mois après la désignation définitive des gagnants.
          </p>
          <p className="content__text">Cookies : Voir <a href="https://milka.be/frbe/static/politique-relative-aux-cookies" target="_blank" rel="noreferrer">https://milka.be/frbe/static/politique-relative-aux-cookies</a>
          </p>
          <p className="content__text">Vos droits : Si les conditions légales sont remplies, vous disposez des droits suivants, que vous pouvez faire valoir auprès de Mondelēz International :
          </p>
          <p className="content__text">a. Droit d’être informé : Vous avez le droit, à tout moment, dans le cadre de l'art. 15 GDPR de demander à Mondelēz International de confirmer si elle traite des données à caractère personnel vous concernant ; si c'est le cas, vous avez également le droit, en vertu de l'art. 15 GDPR d'obtenir des informations sur ces données à caractère personnel et certaines autres informations (par exemple, les finalités du traitement, les catégories de données à caractère personnel, les catégories de destinataires, la période de stockage prévue, l'origine des données, l'utilisation d'une prise de décision automatisée et, dans le cas de transferts vers des pays tiers, les garanties appropriées) et de recevoir une copie de vos données.
          </p>
          <p className="content__text">b. Droit de rectification : Conformément à l'art. 16 GDPR, vous avez le droit de demander à Mondelēz International de corriger les données personnelles stockées à votre sujet si elles sont inexactes ou incorrectes.
          </p>
          <p className="content__text">c. Droit à l'effacement : vous avez le droit, dans les conditions de l'art. 17 GDPR, d'exiger que Mondelēz International supprime immédiatement les données à caractère personnel vous concernant. Le droit à l'effacement n'existe pas, entre autres, si le traitement des données à caractère personnel est requis conformément à l'Art. 17 (3) GDPR.
          </p>
          <p className="content__text">d. Droit à la limitation du traitement : Vous avez le droit, dans les conditions de l'art. 18 GDPR, de demander que Mondelēz International restreigne le traitement de vos données à caractère personnel.
          </p>
          <p className="content__text">e. Droit à la portabilité des données : Vous avez le droit, sous réserve des exigences de l'Art. 20 GDPR, de demander que Mondelēz International remette les données à caractère personnel pertinentes que vous avez fournies dans un format structuré, commun et lisible par machine.
          </p>
          <p className="content__text">f. Droit de rétractation : Vous avez le droit de révoquer votre consentement au traitement des données à caractère personnel à tout moment avec effet immédiat.
          </p>
          <p className="content__text">g. Droit d'opposition : Vous avez le droit, dans les conditions de l'art. 21 GDPR, de vous opposer au traitement de vos données à caractère personnel, en particulier sur la base d'une pesée des intérêts (Art. 6 Para. 1 lit. f) GDPR).
          </p>
          <p className="content__text">Informations sur votre droit d'opposition conformément à l'art. 21 GDPR Vous avez le droit de vous opposer à tout moment au traitement de vos données, qui est basé sur l'Art. 6 Para. 1 f) GDPR (traitement des données sur la base d'un équilibre des intérêts), s'il existe des raisons qui découlent de votre situation particulière. Si vous vous y opposez, Mondelēz International ne traitera plus vos données à caractère personnel, à moins qu'elle ne puisse démontrer des motifs légitimes impérieux pour le traitement qui l'emportent sur vos intérêts, droits et libertés, ou que le traitement serve à faire valoir, exercer ou défendre des droits légaux. L'objection peut être formulée sous n'importe quelle forme et doit être adressée à <a href="mailto:MDLZDataProtectionOfficeMEU@mdlz.com" target="_blank" rel="noreferrer">MDLZDataProtectionOfficeMEU@mdlz.com</a> si possible.
          </p>
          <p className="content__text">Droit de recours : Vous avez également le droit de contacter une autorité de contrôle compétente en cas de réclamation concernant des questions de protection des données. L'autorité de contrôle compétente est donc :
          </p>
          <p className="content__text">
            VERBRAUCHERSERVICE
            <br />
            MONDELEZ DEUTSCHLAND SERVICES GMBH & CO. KG VERBRAUCHERSERVICE
            <br />
            POSTFACH 10 78 40, 28078 BREMEN
            <br />
            HOTLINE DE/AT/CH: 00800 83 00 00 36 KOSTENFREI <br />
            Téléphone : HOTLINE DE/AT/CH: 00800 83 00 00 36 FREE OF CHARGE <br />
            Courriel :
            <a href="mailto:verbraucherservice@mdlz.com">
              VERBRAUCHERSERVICE@MDLZ.COM
            </a>
          </p>
        </div>
      </div>
    )
  }
}

export default FrBe