const Salutations = {
    _MALE: "Herr",
    _FEMALE: "Frau",
    _OTHERS: "Divers",
    dropdown: {
        "": "Anrede*",
        Herr: "Herr",
        Frau: "Frau",
        Divers: "Divers",
    },
    value:{
        "": "0",
        Herr: "1",
        Frau: "2",
        Divers: "10",
    }
}

export default Salutations;