import React from 'react';
import appGoogleTracking from '../../../modules/GoogleTracking';
import { GetRoute } from '../../../config/Config';

/*
    Google tagging plan added on links using : appGoogleTracking.processEventCTA
*/
const footerlinksV1 = (props) => (
    <div className="footer__legal_v1">
        <div className='group__legal__link'>
            {props.CampaignStatus === "end" || '/de-de/danke' === window.location.pathname || '/de-at/danke' === window.location.pathname || '/fr-fr/merci' === window.location.pathname ? null :
                <span className="legal__link">
                    <a className="js-event-cta"
                        href={props.t('footer.link.term')}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="BEDINGUNGEN ZUR NUTZUNG"
                        data-event="footer_contact_menu"
                        data-category="Footer"
                        data-action="Select Menu"
                        data-label={props.t('footer.oreo.termsOfUse')}
                        onClick={appGoogleTracking.processEventCTA}>{props.t('footer.oreo.termsOfUse')}</a>
                </span>
            }
            <span className="legal__link">
                <a className="js-event-cta"
                    href={props.t('footer.link.termsofuse')}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="BEDINGUNGEN ZUR NUTZUNG"
                    data-event="footer_contact_menu"
                    data-category="Footer"
                    data-action="Select Menu"
                    data-label={props.t('footer.oreo.terms')}
                    onClick={appGoogleTracking.processEventCTA}>{props.t('footer.oreo.terms')}</a>
            </span>
            <span className="legal__link">
                <a className="js-event-cta"
                    href={props.t('footer.link.dataprivacy')}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="DATENSCHUTZERKLÄRUNG"
                    data-event="footer_contact_menu"
                    data-category="Footer"
                    data-action="Select Menu"
                    data-label={props.t('footer.oreo.privacyNotice')}
                    onClick={appGoogleTracking.processEventCTA}>{props.t('footer.oreo.privacyNotice')}
                </a>
            </span>
            {props.urlPrefix === 'fr-be' ? null :
                (<span className="legal__link">
                    <a className="js-event-cta"
                        href={props.t('footer.link.imprint')}
                        title="IMPRESSUM"
                        target="_blank"
                        rel="noopener noreferrer"
                        data-event="footer_contact_menu"
                        data-category="Footer"
                        data-action="Select Menu"
                        data-label={props.t('footer.oreo.mention')}
                        //  data-label="NEUES" 
                        onClick={appGoogleTracking.processEventCTA}>{props.t('footer.oreo.mention')}</a>
                </span>)
            }
            {props.urlPrefix === 'fr-be' ? null :
                (<span className="legal__link">
                    <a className="js-event-cta"
                        href={props.t('footer.link.personalisedadvertising')}
                        target="_blank"
                        rel="noopener noreferrer"
                        title=" NUTZUNGSBASIERTE ONLINE-WERBUNG"
                        data-event="footer_contact_menu"
                        data-category="Footer"
                        data-action="Select Menu"
                        data-label={props.t('footer.oreo.onligne')}
                        onClick={appGoogleTracking.processEventCTA}>{props.t('footer.oreo.onligne')}</a>
                </span>)
            }
        </div>
        <div className='group__legal__link2'>
            {props.t('footer.oreo.vente') === "" ? null :
                <span className="legal__link">
                    <a className="js-event-cta"
                        href={props.t('footer.link.vente')}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Allgemeine Geschäftsbedingungen"
                        data-event="footer_contact_menu"
                        data-category="Footer"
                        data-action="Select Menu"
                        data-label={props.t('footer.oreo.vente')}
                        onClick={appGoogleTracking.processEventCTA}>{props.t('footer.oreo.vente')}</a>
                </span>
            }
            {props.t('footer.oreo.mondelez') === "" ? null :

                <span className="legal__link">
                    <a className="js-event-cta"
                        href={props.t('footer.link.mondelez')}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="MONDELEZ INTERNATIONAL"
                        data-event="footer_contact_menu"
                        data-category="Footer"
                        data-action="Select Menu"
                        data-label={props.t('footer.oreo.mondelez')}
                        onClick={appGoogleTracking.processEventCTA}>{props.t('footer.oreo.mondelez')}</a>
                </span>
            }
            <span className="legal__link">
                <a className="js-event-cta"
                    href={props.t('footer.link.cookies')}
                    title=" COOKIE RICHTLINIE"
                    target="_blank"
                    data-event="footer_contact_menu"
                    data-category="Footer"
                    data-action="Select Menu"
                    data-label={props.t('footer.oreo.cookie')}
                    onClick={appGoogleTracking.processEventCTA}>{props.t('footer.oreo.cookie')}</a>
            </span>
            <span className="legal__link">
                <a className="js-event-cta"
                    href={props.t('footer.link.contact')}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Kontakt"
                    data-event="footer_contact_menu"
                    data-category="Footer"
                    data-action="Select Menu"
                    data-label={props.t('footer.oreo.contactUs')}
                    onClick={appGoogleTracking.processEventCTA}>{props.t('footer.oreo.contactUs')}</a>
            </span>
            {/* <span className="legal__link">
                <a className="js-event-cta" 
                    href="https://www.milka.de/Static/faq"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Kontakt"
                    data-event="footer_contact_menu"
                    data-category="Footer"
                    data-action="Select Menu"
                    data-label="FAQ"
                    onClick={appGoogleTracking.processEventCTA}>FAQ</a>
            </span> */}
        </div>
    </div>
);

export default footerlinksV1;