import React, { useEffect, useRef, useState } from "react";
import { useHistory } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import parse from "html-react-parser";
import { mainLoaderToggle } from "../../modules/Helpers";
import appGoogleTracking from "../../modules/GoogleTracking";
import { GetRoute } from '../../config/Config'
import Banner from "../../components/Oreo/Banner";
import TitleContent from "../../components/TitleContent/TitleContent";
import ProductSecond from "../../components/ProductSecond/ProductSecond";

import ParticipationServices from "../../Services/Participation";
import ReCaptcha from 'react-google-invisible-recaptcha';                           //Google Recaptcha
import _LOCAL_CAPTCHA from "../../Models/Captcha";

const ThankYou = ({ urlPrefix, t, CampaignStatus }) => {

  const connais = useRef(0);
  const achat = useRef(0);
  const emballage = useRef(false);
  const media = useRef(false);
  const magasin = useRef(false);
  const letter = useRef(false);
  const autre = useRef(false);
  const [lu, setLu] = useState(false);
  const [loaderSubmit, setloaderSubmit] = useState(false);
  const [changeButton, setchangeButton] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!sessionStorage.getItem('userinfo'))
    {
      window.location.replace(getCurrentUrl());
    }

    if (sessionStorage.getItem('inscrit'))
    {
      setchangeButton(true);
    }
    mainLoaderToggle("hide");
    return () => {
      mainLoaderToggle("show");
    };
  }, []);

  function getCurrentUrl() {
    let currentUrl = window.location.href;
    currentUrl = currentUrl.replace(/\/[^/]*$/, ''); // Remplace la dernière partie de l'URL par une chaîne vide
    return currentUrl;
  }
  const captchaRef = useRef(null);

  const onResolved = (token) => {
    submitForm(token);
  }


  function controlForm() {
    if (lu)
    {
      TriggerCaptcha();
    }
    else
    {
      let errorLuElement = document.getElementById("errorLu");
      errorLuElement.style.display = "block";

    }
  }

  function TriggerCaptcha() {
    if (captchaRef.current.getResponse() !== '')
    {
      captchaRef.current.reset();
    } else
    {
      captchaRef.current.execute();
    }
  }
  const creePromo = async () => {
    let val = "";

    // Check if emballageRef is pointing to a DOM element and if it's checked
    if (emballage.current instanceof HTMLElement && emballage.current.checked)
    {
      val += "|1";
    }
    if (media.current instanceof HTMLElement && media.current.checked)
    {
      val += "|2";
    }
    if (magasin.current instanceof HTMLElement && magasin.current.checked)
    {
      val += "|4";
    }
    if (letter.current instanceof HTMLElement && letter.current.checked)
    {
      val += "|8";
    }
    if (autre.current instanceof HTMLElement && autre.current.checked)
    {
      val += "|32";
    }

    // If none of the checkboxes are checked, return "0"
    if (val === "")
    {
      return "0";
    }

    // Remove the leading '|' character if present
    return val.substring(1);
  };

  const submitForm = async (token) => {
    setloaderSubmit(true);
    let promoS = await creePromo();

    let userInfoString = sessionStorage.getItem('userinfo');
    let email, pid;

    let userInfo = JSON.parse(userInfoString);
    email = userInfo.email;
    pid = userInfo.pid;





    const data = {
      pid: pid,
      email: email,
      language: (window.URL_PREFIX.split('-')[0]).toUpperCase(),
      countryCode: (window.URL_PREFIX.split('-')[1]).toUpperCase(),
      productAwareness: connais.current.querySelector('input[type="radio"]:checked') ? connais.current.querySelector('input[type="radio"]:checked').value : "0",
      productBought: achat.current.querySelector('input[type="radio"]:checked') ? achat.current.querySelector('input[type="radio"]:checked').value : "0",

      promoAwareness: promoS,
      recaptcha: token,
    }
    console.log('data', data);

    ParticipationServices.Sondage(data)
      .then(rep => {
        console.log('success', rep);

        appGoogleTracking.dataLayerPush({
          'dataEvent': 'click_submit',
          'dataCategory': 'Thank You Page',
          'dataAction': 'Click on Submit',
          'dataLabel': 'ENG_TP_OTHER'
        })
        setloaderSubmit(false);
        if (rep.data.success)
        {
          let inscrit = true
          sessionStorage.setItem('inscrit', inscrit);
          setchangeButton(true);
          //          window.location.reload();
          setloaderSubmit(false);
        }
        else
        {
          alert('error')
          setloaderSubmit(false);
        }

      }).catch(err => {
        setloaderSubmit(false);
        console.log('error Survey', err);
      })
  };

  const handleLetterChange = (event) => {
    if (event.target.checked === true)
    {
      appGoogleTracking.dataLayerPush({
        'dataEvent': 'milka_competition_find_out',
        'dataCategory': 'Thank You Page',
        'dataAction': 'Select Checkbox',
        'dataLabel': 'Newsletter'
      })
    }
  };

  const handleLuChange = (event) => {
    if (event.target.checked === true)
    {
      appGoogleTracking.dataLayerPush({
        'dataEvent': 'click_conditions_submit',
        'dataCategory': 'Thank You Page',
        'dataAction': 'Click on Submit Conditions',
        'dataLabel': 'ENG_TP_OTHER'
      })
      let errorLuElement = document.getElementById("errorLu");
      errorLuElement.style.display = "none";
    } else
    {
      let errorLuElement = document.getElementById("errorLu");
      errorLuElement.style.display = "block";
    }
  };

  const handleAutreChange = (event) => {
    if (event.target.checked === true)
    {
      appGoogleTracking.dataLayerPush({
        'dataEvent': 'milka_competition_find_out',
        'dataCategory': 'Thank You Page',
        'dataAction': 'Select Checkbox',
        'dataLabel': 'Miscellaneos'
      })
    }
  };

  const handleMagasinChange = (event) => {
    if (event.target.checked === true)
    {
      appGoogleTracking.dataLayerPush({
        'dataEvent': 'milka_competition_find_out',
        'dataCategory': 'Thank You Page',
        'dataAction': 'Select Checkbox',
        'dataLabel': 'Supermarket Display'
      })
    }
  };

  const handleConnaisChange = (event) => {
    appGoogleTracking.dataLayerPush({
      'dataEvent': 'milka_product_know_about',
      'dataCategory': 'Thank You Page',
      'dataAction': 'Select Button',
      'dataLabel': event.target.value === '1' ? 'Yes' : 'No'
    })
  };

  const handleAchatChange = (event) => {
    appGoogleTracking.dataLayerPush({
      'dataEvent': 'milka_product_bought',
      'dataCategory': 'Thank You Page',
      'dataAction': 'Select Button',
      'dataLabel': event.target.value === '1' ? 'Yes' : 'No'
    })
  };

  const handleEmballageChange = (event) => {
    if (event.target.checked === true)
    {
      appGoogleTracking.dataLayerPush({
        'dataEvent': 'milka_competition_find_out',
        'dataCategory': 'Thank You Page',
        'dataAction': 'Select Checkbox',
        'dataLabel': 'product packaging'
      })
    }
  };

  const handleMediaChange = (event) => {
    if (event.target.checked === true)
    {
      appGoogleTracking.dataLayerPush({
        'dataEvent': 'milka_competition_find_out',
        'dataCategory': 'Thank You Page',
        'dataAction': 'Select Checkbox',
        'dataLabel': 'Social Media'
      })
    }
  };

  let _captcha = _LOCAL_CAPTCHA['muffin'];

  return (
    <>
      {loaderSubmit ? <div className="simple-loader__container active"> <div className="simple-loader__indicator"></div></div> : null}
      <Helmet>
        <title>{t("meta.title")}</title>
        <meta name="description" content={t("meta.description")} />
        <body className={window.URL_PREFIX + " thank-you"} />
      </Helmet>
      <Banner
        DesktopImage={t("page.thank-you.oreo.banner.desktop")}
        MobileImage={t("page.thank-you.oreo.banner.mobile")}
        AltText={t("page.holding.oreo.banner.alt")}
      />

      <div className="wrapper oreo-holding">
        <div className="thank-you__content">
          <TitleContent
            title={t("page.thank-you.oreo.title")}
            description={t("page.thank-you.oreo.desc")}
          />
        </div>
        <div className="product-wrapper__second">
          <div className="back-top">
            <p></p>
          </div>
          {window.URL_PREFIX === 'de-de' || window.URL_PREFIX === 'fr-fr' ?
            <div className="formulaire_content">
              <div className="form-container centered">
                <ReCaptcha
                  ref={captchaRef}
                  locale={_captcha.lang}
                  sitekey={_captcha.key}
                  onResolved={onResolved}
                />
              </div>
              <form
                id="frm_participation"
                name="frm_participation"
                method="post"
                action="/"
              /* onSubmit={onSubmitHandler(this)} */ noValidate>
                <h2>{t("page.survey.headline")}</h2>
                <p className="descri">{t("page.survey.subline")}</p>
                <div className="formulaire" >
                  <p>{t("page.survey.Antwort1")}</p>
                  <div ref={connais}>
                    <label className="custom-radio">
                      <input value="1"
                        // ref={connais}
                        onChange={handleConnaisChange} type="radio" name="bool" className="custom-radio_input" /><span className="radio"></span>{t("page.survey.RadioBtn.Y")}
                    </label>
                    <label className="custom-radio">
                      <input value="2"
                        onChange={handleConnaisChange} type="radio" name="bool" className="custom-radio_input" /><span className="radio"></span>{t("page.survey.RadioBtn.N")}
                    </label>
                  </div>
                  <hr className="underline" />
                  <p>{t("page.survey.Antwort2")}</p>
                  <div ref={achat}>
                    <label className="custom-radio">
                      <input value="1"
                        onChange={handleAchatChange} type="radio" name="boolean" className="custom-radio__input" /><span className="radio"></span>{t("page.survey.RadioBtn.Y")}
                    </label>
                    <label className="custom-radio">
                      <input value="2"
                        onChange={handleAchatChange} type="radio" name="boolean" className="custom-radio_input" /><span className="radio"></span>{t("page.survey.RadioBtn.N")}
                    </label>
                  </div>
                  <hr className="underline" />
                  <p>{t("page.survey.Antwort3")}</p>
                  <div className="checkbox-list">
                    <div className="input-containerterms">
                      <label className="custom-inputs notborder" htmlFor="Produktverpackung">
                        <span className="text-content " style={{ fontSize: "20px" }}>
                          {t("page.survey.CheckBoxen.1")}<br />
                          <span id="error-terms" className="form-input__error active"></span>
                        </span>
                        <input
                          ref={emballage}
                          onChange={event => {
                            handleEmballageChange(event);
                          }}
                          type="checkbox" id="Produktverpackung" name="Produktverpackung" className="js-event-type-in js-event-ab" data-require="true" data-type="checkbox" data-error-target="#error-terms" data-required-message="Bitte akzeptiere die Teilnahmebedingungen, um fortzufahren." data-event-fieldname="Terms"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="input-containerterms">
                      <label className="custom-inputs notborder" htmlFor="social">
                        <span className="text-content " style={{ fontSize: "20px" }}>
                          {t("page.survey.CheckBoxen.2")}<br />
                          <span id="error-terms" className="form-input__error active"></span>
                        </span>
                        <input

                          ref={media}
                          onChange={event => {
                            handleMediaChange(event)
                          }}
                          type="checkbox" id="social" name="social" className="js-event-type-in js-event-ab" data-require="true" data-type="checkbox" data-error-target="#error-terms" data-required-message="Bitte akzeptiere die Teilnahmebedingungen, um fortzufahren." data-event-fieldname="Terms"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="input-containerterms">
                      <label className="custom-inputs notborder" htmlFor="Aufsteller">
                        <span className="text-content " style={{ fontSize: "20px" }}>
                          {t("page.survey.CheckBoxen.3")}<br />
                          <span id="error-terms" className="form-input__error active"></span>
                        </span>
                        <input
                          ref={magasin}
                          onChange={event => {

                            handleMagasinChange(event)
                          }}
                          type="checkbox" id="Aufsteller" name="Aufsteller" className="js-event-type-in js-event-ab" data-require="true" data-type="checkbox" data-error-target="#error-terms" data-required-message="Bitte akzeptiere die Teilnahmebedingungen, um fortzufahren." data-event-fieldname="Terms"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="input-containerterms">
                      <label className="custom-inputs notborder" htmlFor="Newsletter">
                        <span className="text-content " style={{ fontSize: "20px" }}>
                          {t("page.survey.CheckBoxen.4")}<br />
                          <span id="error-terms" className="form-input__error active"></span>
                        </span>
                        <input
                          ref={letter}
                          onChange={event => {
                            handleLetterChange(event);
                          }}
                          type="checkbox" id="Newsletter" name="Newsletter" className="js-event-type-in js-event-ab" data-require="true" data-type="checkbox" data-error-target="#error-terms" data-required-message="Bitte akzeptiere die Teilnahmebedingungen, um fortzufahren." data-event-fieldname="Terms"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="input-containerterms">
                      <label className="custom-inputs notborder" htmlFor="Sonstiges">
                        <span className="text-content " style={{ fontSize: "20px" }}>
                          {t("page.survey.CheckBoxen.5")}<br />
                          <span id="error-terms" className="form-input__error active"></span>
                        </span>
                        <input
                          ref={autre}
                          onChange={event => {

                            handleAutreChange(event);
                          }}
                          type="checkbox" id="Sonstiges" name="Sonstiges" className="js-event-type-in js-event-ab" data-require="true" data-type="checkbox" data-error-target="#error-terms" data-required-message="Bitte akzeptiere die Teilnahmebedingungen, um fortzufahren." data-event-fieldname="Terms"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <hr className="underline backline" />

                  <div className="end input-containerterms">
                    <label className="custom-inputs notborder" htmlFor="terms">
                      <span className="text-content " style={{ fontSize: "20px" }}>
                        {t("page.survey.legalText")}<br />
                        <span id="error-terms" className="form-input__error active"></span>
                      </span>
                      <input
                        onChange={event => {
                          setLu(event.target.checked);
                          handleLuChange(event);
                        }}

                        type="checkbox" id="terms" name="terms" className="js-event-type-in js-event-ab" data-require="true" data-type="checkbox" data-error-target="#error-terms" data-required-message="Bitte akzeptiere die Teilnahmebedingungen, um fortzufahren." data-event-fieldname="Terms"
                      />
                      <span className="checkmark"></span>


                      <span id="errorLu" style={{ color: "#d93333", display: "none" }}>
                        {t("page.thank-you.sondageConditionTexte")}
                      </span>

                    </label>
                  </div>
                </div>


                <div className="btn-content">
                  {!changeButton ?
                    <div className="btn" onClick={() => controlForm()}>
                      <p>{t("page.survey.Button")}</p>
                    </div>
                    :
                    <div className="disabled">
                      <p>{t("page.survey.ButtonInscrit")}</p>
                    </div>
                  }
                </div>


              </form>
            </div>
            : <div className="formulaire_content"></div>}
          <ProductSecond CampaignStatus={CampaignStatus} urlPrefix={urlPrefix} />
        </div>
      </div>
    </>
  );
};

export default withTranslation()(ThankYou);
