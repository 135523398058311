import React, { Component } from 'react'
import { scrollToElementwithTimeout } from '../../../modules/Helpers';

export class EnGb extends Component {

  componentDidMount() {
    scrollToElementwithTimeout();
  }

  Table() {
    return (
      <>

        <p className="content__text borderline">1x Package 1
        </p>
        <p className="content__text borderin">
          <b>iPad Pro 12.9-inch Space Grey, 512 GB, WiFi
            without engraving, without pencil, without keyboard, without Apple Care+</b>	 <br />
          Value in GBP: £ 1,599.00 <br />
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Ultra, without Apple Care+</b>	 <br />
          Value in GBP: £ 849.00
        </p>
        <p className="content__text borderin">
          <b>Canon EOS R7 Mirrorless Camera + RF-S 18-150mm F3.5-6.3 IS STM Lens</b>	 <br />
          Value in GBP: £ 1,799.00
        </p>

        <p className="content__text borderline">1x Package 2
        </p>
        <p className="content__text borderin">
          <b>Samsung TV 75" Neo QLED 8K QN800B</b>	 <br />
          Value in GBP: £ 2,499.00 <br />
        </p>
        <p className="content__text borderin">
          <b>Microsoft, XBox Series X without games</b>	 <br />
          Value in GBP: £ 419.90
        </p>
        <p className="content__text borderin">
          <b>Xbox Elite Wireless Controller Series 2, 2 pieces</b>	 <br />
          Value in GBP: £ 299.98
        </p>
        <p className="content__text borderin">
          <b>Sonos ARC Soundbar, black</b>	 <br />
          Value in GBP: £ 899.00
        </p>

        <p className="content__text borderline">1x Package 3
        </p>
        <p className="content__text borderin">
          <b>Ultimate Immersive Set with Arc, Sub, 2 Era 300, black</b>	 <br />
          Value in GBP: £ 2,596.00<br />
        </p>
        <p className="content__text borderin">
          <b>Samsung TV 50" Neo QLED 4K QN90C</b>	 <br />
          Value in GBP: £ 1,499.00
        </p>

        <p className="content__text borderline">2x Package 4
        </p>
        <p className="content__text borderin">
          <b>Canon EOS R7 Mirrorless Camera + RF-S 18-150mm F3.5-6.3 IS STM Lens</b>	 <br />
          Value in GBP: £ 1,799.00<br />
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Ultra, without Apple Care+</b>	 <br />
          Value in GBP: £ 849.00
        </p>

        <p className="content__text borderline">2x Package 5
        </p>
        <p className="content__text borderin">
          <b>iPad Pro 12.9-inch Space Grey, 512 GB, WiFi
            without engraving, without pencil, without keyboard, without Apple Care+</b>	 <br />
          Value in GBP: £ 1,599.00<br />
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Ultra, without Apple Care+</b>	 <br />
          Value in GBP: £ 849.00
        </p>

        <p className="content__text borderline">2x Package 6
        </p>
        <p className="content__text borderin">
          <b>Sonos ARC Soundbar, black</b>	 <br />
          Value in GBP: £ 899.00
        </p>


        <p className="content__text borderline">1x Package 7
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Ultra, without Apple Care+</b>	 <br />
          Value in GBP: £ 849.00
        </p>

        <p className="content__text borderline">2x Package 8
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Series 8, alluminium case midnight finish, Sport Band , 41mm case size, GPS connectivity, without Apple Care+</b>	 <br />
          Value in GBP: £ 419.00
        </p>
        <p className="content__text borderin">
          <b>AirPods Pro (2nd generation)</b>	 <br />
          Value in GBP: £ 249.00
        </p>

        <p className="content__text borderline">3x Package 9
        </p>
        <p className="content__text borderin">
          <b>Apple Watch Series 8, alluminium case midnight finish, Sport Band , 41mm case size, GPS connectivity, without Apple Care+</b>	 <br />
          Value in GBP: £ 419.00
        </p>

        <p className="content__text borderline">3x Package 10
        </p>
        <p className="content__text borderin">
          <b>AirPods Pro (2nd generation)	</b>	 <br />
          Value in GBP: £ 249.00
        </p>
        <p className="content__text borderin">
          <b>Home Pod mini	</b>	 <br />
          Value in GBP: £ 99.00
        </p>
      </>
    )
  }
  render() {
    return (
      <div className="main__content">
        <p className="text__heading">CONDITIONS OF PARTICIPATION </p>
        <div className="content">
          <p className="content__text">
            General Prize and organizer: Baker & Baker Germany GmbH is the sole sponsor of the prizes within the meaning of Section 657 of the German Civil Code (“organizer”). D&K Brand Activation (Drewes & Keretic GmbH) acts as a commissioned service provider for the organizer, responsible for overall management of the promotion and supplying and distributing the prizes.  Consultix GmbH is an agency who will manage and hold participant data.  Proximity (RAPP Worldwide, Inc.) and AUW (artundweise GmbH) have been engaged, as technical production partner and digital creative and concept agency. None of these third parties are obligated to provide their own services as a result of the competition. Retailers and social media platforms are neither organizers of the competition nor do they act on behalf of the organizer.
          </p>
          <p className="content__text">Eligibility: Anyone over the age of 18 and with permanent residence in Germany, Austria, Switzerland, United Kingdom, France, Belgium, Netherlands or Luxembourg can take part. Prerequisite for participation is that proper registration has taken place. For this purpose, the online form on the website <a
            href="www.donut-muffin.oreo.eu"
            target="_blank"
            rel="noreferrer"
          >www.donut-muffin.oreo.eu
          </a> must be filled out and submitted by the participant and these conditions of participation must be accepted by clicking on the check box provided.  There is no requirement to make a purchase to participate.  For participants residing in Belgium, due to regulatory requirements, a question must be answered correctly before being eligible to participate.
          </p>
          <p className="content__text">Excluded from participation are employees and relatives of employees of the organizer and employees and relatives of employees of D&K Brand Activation, Consultix GmbH, Proximity and AUW. Participations that are carried out by commissioned service providers, such as competition services for their customers/members, are expressly excluded. Multiple participations are not permitted. The organizer reserves the right to exclude participants from the competition who violate these conditions of participation, provide incorrect information or try to influence the course of the competition in an unlawful manner. In this case, the excluded participants have no right to payment of the prize or any other compensation.
          </p>
          <p className="content__text">Cancellation of the competition: The organizer reserves the right to terminate the competition prematurely for good cause if, for technical reasons (for example, manipulation of the website based competition page by third parties or infection of the website with viruses), for legal reasons, or for other reasons beyond the control of the organizer, proper implementation of the competition can no longer be guaranteed.  The organizer cannot be held liable for this, except in the case of intent or gross negligence on the part of the organizer. In case of cancellation, the participants have no right to receive any prize or any other compensation.
          </p>
          <p className="content__text">Period / deadline: Participation is only possible in the period from 12:00 a.m. on September 1st, 2023, to 11:59p.m. on March 31st, 2024.
          </p>
          <p className="content__text">Prizes: A total of 18 winners will be selected, with prizes ranging in value from approximately: £300 / €400 / CHF350, to £4,200 / €4,800 / CHF 5,000. The winners will automatically be allocated a prize.  The total value of all prizes will not exceed £29,000 / €35,000 / CHF31,200. See prize list below. All items subject to availability. If items are unavailable at the time, they will be replaced by similar products of at least equal value.
          </p>
          <p className="content__text">Prize distribution: Prizes will be sent by a suitable delivery method within 4 weeks of the drawing of the prizes.
          </p>
          <p className="content__text">Winner determination: The winners will be determined at random from all valid and correct entries on April 1st 2024.  There will be no guaranteed winner in each country where the competition is being run.
          </p>
          <p className="content__text">Prize notification, confirmation period, expiry of the prize: The winners will be notified by D&K Brand Activation at the e-mail address they entered in the registration form and then be asked to provide a postal address for the purpose of sending out the prize. In principle, a claim to any prize cannot be transferred to another person. If a winner does not provide the requested data within a period of 14 days after sending the prize notification, or if a prize distribution is not possible due to incorrect or incomplete data, another winner will be selected and the claim to the prize of the originally determined winner lapses in this respect.  D&K Brand Activation will post the prizes to the respective winners.
          </p>
          <p className="content__text">Liability: The liability of the organizer and the persons engaged by it shall be excluded.  The exclusion of liability does not apply to intentional or grossly negligent behaviour and death or personal injury.  For ordinary negligence, the scope of liability is limited to the major contractual obligations – namely the proper determination of the prize winners and the delivery of the prizes.  The organizer will have no liability to any winner in the event any prize bundle is not delivered (either in full or in part), or is damaged in transit.
          </p>
          <p className="content__text">Miscellaneous: The law of the Federal Republic of Germany is exclusively applicable. This provision shall not affect the right of Art 6 (2) of the Rome I Regulation, according to which, despite the choice of law, those mandatory provisions apply which would have been applicable without the express choice of law.  Should any of these provisions be or become invalid, the validity of the remaining conditions of participation shall remain unaffected.
          </p>
          <p className="content__text">Data Protection: Mondelēz International (as the owner of the Oreo brand) is the data controller for personal data provided by participants and Consultix GmbH will process personal data for the purposes of the competition.
          </p>
          <p className="content__text">The mandatory information on data processing required by the EU General Data Protection Regulation (“GDPR”) is summarised here the privacy notice for Mondelēz International can be accessed via:  <a href="https://eu.mondelezinternational.com/privacy-notice?sc_lang=en-gb&siteId=fhMX2mASDxC1%2frQglzhz6H0z9QiUvDPH" target="_blank" rel="noreferrer">https://eu.mondelezinternational.com/privacy-notice?sc_lang=en-gb&siteId=fhMX2mASDxC1%2frQglzhz6H0z9QiUvDPH</a>
          </p>
          <p className="content__text">Responsible: Mondelēz International, Lindbergh-Allee 1, 8152 Glattpark, Switzerland, as the data controller, is responsible for data protection.
          </p>
          <p className="content__text">Data protection officer: You can reach the Mondelēz International data protection officer at:   <a href="mailro:MDLZDataProtectionOfficeMEU@mdlz.com">MDLZDataProtectionOfficeMEU@mdlz.com</a>
          </p>
          <p className="content__text">Data processing: In order to participate in the competition via the <a href="website www.donut-muffin.oreo.eu" target="_blank" rel="noreferrer">website www.donut-muffin.oreo.eu</a>, the following data must be provided when registering: Title, first name, surname, email address, address and date of birth. This personal data is processed to carry out the competition, i.e., to determine the winners, to notify them and to be able to send them the prize. The winners must also provide the following information for the distribution of prizes: postal address. If you win, you will be asked to do so separately by email. As part of the e-mail traffic, log files are stored about the properties of the e-mail and the time of arrival, as well as all data specified in the e-mail by the sender.
          </p>
          <p className="content__text">Legal basis: The processing of personal data in the context of registration and prize distribution takes place lawfully on the basis of the GDPR. Consultix GmbH processes the data to fulfill the contract in the form of carrying out the prize draw and the associated legal obligation, Article 6 Paragraph 1 lit. b) GDPR.
          </p>
          <p className="content__text"> Passing on to third parties: Mondelēz International does not pass on the participants' data to third parties within the meaning of the GDPR.
          </p>
          <p className="content__text">Processors: Mondelēz International only uses trustworthy service providers who process your personal data. They are commissioned in writing in accordance with the strict requirements of the GDPR and, for example, have technical and organizational measures with which the service providers protect the data entrusted to them from misuse. Specifically, D&K Brand Activation has been commissioned to manage the competition and Proximity has been commissioned to provide the website for the competition. Order processing only includes those services that relate directly to the provision of the main service. This does not include ancillary services such as Telecommunications Services.
          </p>
          <p className="content__text">Transfer to third countries: All personal data is stored within the EU/EEA, also by any processors.
          </p>
          <p className="content__text">
            Duration of storage:  Mondelēz International and Consultix GmbH will delete:<br></br>
            <ul>
              <li>
                •	the personal data of the winners as soon as there are no longer any commercial or tax retention requirements to the contrary (the latter can be up to 10 years);
              </li>
              <li>
                •	the personal data of the other participants as soon as the winners of the competition have finally been determined, i.e., additional draw procedures may have taken place due to incomplete information or no response from a previously identified winner;
              </li>
              <li>
                •	emails received in relation to the competition: six (6) months after the winners have been finally determined.
              </li>
            </ul>
          </p>
          <p className="content__text">
            Cookies: See <a href="https://www.oreo.co.uk/legal/cookies" target="_blank" rel="noreferrer">https://www.oreo.co.uk/legal/cookies</a>
          </p>
          <p className="content__text">Your rights: If the legal requirements are met, you have the following rights, which can be asserted against Mondelēz International:
          </p>
          <p className="content__text">a. Right of providing information: You are entitled at any time within the framework of Art. 15 GDPR to request confirmation from Mondelēz International as to whether they are processing personal data relating to you; if this is the case, you are also entitled under Art. 15 GDPR to obtain information about this personal data and certain other information (e.g. processing purposes, categories of personal data, categories of recipients, planned storage period, the origin of the data, the use of an automated decision-making and, in the case of third-country transfers, the appropriate safeguards) and to receive a copy of your data.
          </p>
          <p className="content__text">b. Right to rectification: According to Art. 16 GDPR, you are entitled to request that Mondelēz International corrects the personal data stored about you if it is inaccurate or incorrect.
          </p>
          <p className="content__text">c. Right to erasure: You are entitled, under the conditions of Art. 17 GDPR, to demand that Mondelēz International deletes personal data concerning you immediately. The right to erasure does not exist, among other things, if the processing of personal data is required in accordance with Art. 17 (3) GDPR.
          </p>
          <p className="content__text">d. Right to restriction of processing: You are entitled, under the conditions of Art. 18 GDPR, to request that Mondelēz International restricts the processing of your personal data.
          </p>
          <p className="content__text">e. Right to data portability: You are entitled, subject to the requirements of Art. 20 GDPR, to request that Mondelēz International hands over the relevant personal data that you have provided in a structured, common and machine-readable format.
          </p>
          <p className="content__text">f. Right of withdrawal: You have the right to revoke your consent to the processing of personal data at any time with effect for the future.
          </p>
          <p className="content__text">g. Right of objection: You have the right, under the conditions of Art. 21 GDPR, to object to the processing of your personal data, in particular on the basis of a weighing of interests (Art. 6 Para. 1 lit. f) GDPR).
          </p>
          <p className="content__text">Information about your right of objection according to Art. 21 GDPR You are entitled to object at any time to the processing of your data, which is based on Art. 6 Para. 1 f) GDPR (data processing on the basis of a balance of interests), if there are reasons for this that arise from your particular situation. If you object, Mondelēz International will no longer process your personal data unless they can demonstrate compelling legitimate grounds for processing that outweigh your interests, rights and freedoms, or the processing serves to assert, exercise or defend legal claims. The objection can be made in any form and should be addressed to <a href="mailro:MDLZDataProtectionOfficeMEU@mdlz.com">MDLZDataProtectionOfficeMEU@mdlz.com</a> if possible.
          </p>
          <p className="content__text">Right of appeal: You also have the right to contact a competent supervisory authority in the event of complaints regarding Data Protection issues. The supervisory authority responsible therefore is:
          </p>
          <p className="content__text">
            VERBRAUCHERSERVICE<br />
            MONDELEZ DEUTSCHLAND SERVICES GMBH & CO. KG VERBRAUCHERSERVICE<br />
            POSTFACH 10 78 40, 28078 BREMEN<br />
            HOTLINE DE/AT/CH: 00800 83 00 00 36 KOSTENFREI <br />
            Phone: HOTLINE DE/AT/CH: 00800 83 00 00 36 FREE OF CHARGE  <br />
            Email:   <a href="mailto:verbraucherservice@mdlz.com">VERBRAUCHERSERVICE@MDLZ.COM</a>

          </p>
          <p className="content__text">Annexe: Details on Prize Bundles
          </p>
          <div className="prize">
            <p id="prize" className="content__text">Promotion starts 01.09.2023. All items subject to availability in shipping period to winners. Items unavailable at the time will be replaced by similar products of at least equal value.
            </p>
          </div>
          {/* <p className="content__text">1x Package 1	<br/>Value in GBP<br/>
iPad Pro 12.9-inch Space Grey, 512 GB, WiFi
without engraving, without pencil, without keyboard, without Apple Care+	<br/>£ 1,599.00<br/>
Apple Watch Ultra, without Apple Care+	<br/>£ 849.00<br/>
Canon EOS R7 Mirrorless Camera + RF-S 18-150mm F3.5-6.3 IS STM Lens<br/>	£ 1,799.00

          </p>
          <p className="content__text">1x Package 2	<br/>Value in GBP
Samsung TV 75" Neo QLED 8K QN800B	<br/>£ 2,499.00<br/>
Microsoft, XBox Series X without games	<br/>£ 419.90<br/>
Xbox Elite Wireless Controller Series 2, 2 pieces	<br/>£ 299.98
Sonos ARC Soundbar, black	£ 899.00

          </p>
          <p className="content__text">1x Package 3	<br/>Value in GBP<br/>
Ultimate Immersive Set with Arc, Sub, 2 Era 300, black	<br/>£ 2,596.00<br/>
Samsung TV 50" Neo QLED 4K QN90C	<br/>£ 1,499.00

          </p>
          <p className="content__text">2x Package 4	<br/>Value in GBP<br/>
Canon EOS R7 Mirrorless Camera + RF-S 18-150mm F3.5-6.3 IS STM Lens	<br/>£ 1,799.00<br/>
Apple Watch Ultra, without Apple Care+	<br/>£ 849.00

          </p>
          <p className="content__text">2x Package 5	<br/>Value in GBP<br/>
iPad Pro 12.9-inch Space Grey, 512 GB, WiFi
without engraving, without pencil, without keyboard, without Apple Care+	<br/>£ 1,599.00<br/>
Apple Watch Ultra, without Apple Care+	<br/>£ 849.00<br/>

          </p>
          <p className="content__text">
2x Package 6	<br/>Value in GBP<br/>
Sonos ARC Soundbar, black	<br/>£ 899.00

          </p>
          <p className="content__text">1x Package 7	<br/>Value in GBP<br/>
Apple Watch Ultra, without Apple Care+	<br/>£ 849.00

          </p>
          <p className="content__text">2x Package 8	<br/>Value in GBP<br/>
Apple Watch Series 8, alluminium case midnight finish, Sport Band , 41mm case size, GPS connectivity, without Apple Care+	<br/>£ 419.00<br/>
AirPods Pro (2nd generation)	<br/>£ 249.00

          </p>
          <p className="content__text">3x Package 9	<br/>Value in GBP<br/>
Apple Watch Series 8, alluminium case midnight finish, Sport Band , 41mm case size, GPS connectivity, without Apple Care+	<br/>£ 419.00

          </p>
          <p className="content__text">3x Package 10	<br/>Value in GBP<br/>
AirPods Pro (2nd generation)	<br/>£ 249.00<br/>
Home Pod mini	<br/>£ 99.00

          </p> */}

          {this.Table()}
        </div>
      </div>
    )
  }
}

export default EnGb